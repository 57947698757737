import {IExecResult, IPage, IWithKey} from "./interface";
import {doPageQuery, ExecSql, Post} from "./r";

export interface IBuildingVO extends IWithKey {
    id: string
    name: string
    address: string // "大三子北"
    air_desc: string // "4点钟开始的"
    air_type: 1 | 2
    around: string // "人人乐超市"
    bus: string // "418"
    developer: string // "保利地产"
    floor_height: number // 3.5
    images: string // string // json 的数组
    latitude: string // "23.9876"
    longitude: string // "12.3234"
    park: string // "很多个车位哦"
    property_company: string // "保利物业"
    property_year: string // "20"
    subway: string // "21号线，16号线"
    total_area: string // "19000"
    city_id: number
    area_id: number
    district_id: number
    city_name: string
    area_name: string
    district_name: string
    district_area?: [number, number, number, string, string, string]
    latlng?: {lat: string | number, lng: string | number}

    create_by: number
    create_by_name: string
    latest_update_by: number
    latest_update_name: string
    owner: number
    owner_name: string
    latest_update_time: string
    create_time: string
}

export interface IBuildingParams extends IPage {
    name: string
}

export interface IBuildingSelectorParams extends IPage {
    name: string
}

export interface ICreateBuildingDto {

}

export interface IUpdateBuildingDto {

}

export const BuildingService = {
    list(params: Partial<IBuildingParams>) {
        return doPageQuery<IBuildingVO>("/building/list", params)
    },
    create(dto: ICreateBuildingDto) {
        return Post<IExecResult>("/building/create", dto)
    },
    update(dto: IUpdateBuildingDto) {
        return Post<IExecResult>("/building/update", dto)
    },
    delete(id: number | string) {
        return Post<IExecResult>("/building/delete", {id})
    },
    selector(query: Partial<IBuildingSelectorParams>) {
        return doPageQuery<IBuildingVO>("/building/selector", query)
    },
    // 更新归属
    assignOwner(buildingIds: string[], userid: number) {
        let idsList = buildingIds.map((item) => {
            return `UUID_TO_BIN('${item}')`
        })

        const sql = `update building set owner = ${userid} where id in (${idsList.join(',')})`

        // console.log(sql)

        return ExecSql(sql)
    }
}