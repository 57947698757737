import {IExecResult, IPage, IWithKey} from "./interface";
import {doPageQuery, Post} from "./r";
import SqlString  from "sqlstring"

export interface ICooperationVO extends IWithKey{
    id: number
    name: string
    business: string
    contacts: string
    phone: string
    status: number
    sort: number
    img: string
}

export interface ICooperationParams extends IPage {

}

export interface ICooperationTypeVO {
    id: number
    name: string
    desc: string
}

export const CooperationService = {
    list(params: Partial<ICooperationParams>) {
        return doPageQuery<ICooperationVO>("/cooperation/list", params)
    },
    enable(id: number) {
        return Post<IExecResult>("/cooperation/enable", {id})
    },
    disable(id: number) {
        return Post<IExecResult>("/cooperation/disable", {id})
    },
    delete(id: number) {
        return Post<IExecResult>("/cooperation/delete", {id})
    },

    createOrUpdate(params: ICooperationVO) {
        return Post<IExecResult>("/cooperation/create_or_update", params)
    },
    typeList() {
        const sql = `select * from cooperation_type;`
        return Post<ICooperationTypeVO[]>("/util/exec_query", {sql})
    },
    deleteType(vo: ICooperationTypeVO) {
        const sql = SqlString.format(`DELETE FROM cooperation_type where \`id\` = ?`, [vo.id])
        // console.log(sql)
        // return Promise.resolve(1)
        return Post<IExecResult>('/util/exec_sql', {sql})
        // const sql = `DELETE FROM cooperation_type where id = `
    },
    newType(name: string, desc: string) {
        const sql = SqlString.format("INSERT INTO cooperation_type SET ?", { name, desc })
        // console.log(sql)
        return Post<IExecResult>('/util/exec_sql', {sql})
        // return Promise.resolve(1)
    },
    updateType(vo: ICooperationTypeVO) {
        // console.log(vo);
        const sql = SqlString.format("UPDATE cooperation_type SET `name`= ?, `desc` = ? where `id` = ?", [vo.name, vo.desc, vo.id])
        // console.log(sql)
        // return Promise.resolve(1)
        return Post<IExecResult>('/util/exec_sql', {sql})
    }
}