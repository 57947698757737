import SqlString from "sqlstring";
import {AuthService} from "./AuthService";
import {CURRENT_TIMESTAMP} from "../utils/sql";
import {ExecSql} from "./r";
import {IPage, IWithKey} from "./interface";
import doSqlPageQuery from "./SqlHelper";

export enum EnumCustomerChangeType  {
    type = 1,
    level = 2,
}

export const EnumCustomerChangeTypeList = [
    [EnumCustomerChangeType.type, '客户类型'],
    [EnumCustomerChangeType.level, '客户等级']
]

export const EnumCustomerChangeTypeMap = new Map(EnumCustomerChangeTypeList as any)

// 审核状态
export enum EnumCustomerChangeAuditStatus {
    needAudit = 1,
    pass = 2,
    reject = 3
}

export const EnumCustomerChangeAuditStatusList = [
    [ EnumCustomerChangeAuditStatus.needAudit, '待审核' ],
    [ EnumCustomerChangeAuditStatus.pass, '审核通过' ],
    [ EnumCustomerChangeAuditStatus.reject, '拒绝' ],
]

export const EnumCustomerChangeAuditStatusMap = new Map(EnumCustomerChangeAuditStatusList as any)

export interface ICreateCustomerChangeAuditVO {
    customer_id: number
    type: EnumCustomerChangeType
    reason: string
    payload: string
}

export interface IAuditCustomerChangeVO {
    id: number
    audit_opinion: string
    status: EnumCustomerChangeAuditStatus.pass | EnumCustomerChangeAuditStatus.reject
}

export interface ICustomerChangeAuditQuery extends IPage {
    type: EnumCustomerChangeType
    status: EnumCustomerChangeAuditStatus
    applicant_name: string
}

export interface ICustomerChangeAuditVO extends IWithKey{
    id: number
    customer_id: number
    status: number
    applicant: number
    applicant_name: string

    reason: string
    audit_reason: string
    auditor: number
    auditor_name: string
    create_time: string
    audit_time: string
    type: EnumCustomerChangeType
    payload: string
    phone_list: string
}

const TABLE_NAME = 'customer_change_audit'

export const CustomerChangeAuditService = {
    // 返回列表
    list(query: Partial<ICustomerChangeAuditQuery>) {
        const where = [];
        let whereStr = ''
        //  select ca.*, c.phone_list from customer_change_audit as ca left join customer as c on c.id = ca.customer_id limit 0, 10
        let sql = `select ca.*, c.phone_list from ${TABLE_NAME} as ca left join customer as c on c.id = ca.customer_id `

        if (query.type) {
            where.push(`ca.type = ${query.type}`)
        }

        if (query.status) {
            where.push(`ca.status = ${query.status}`)
        }
        if (query.applicant_name) {
            where.push(`ca.applicant_name = ${SqlString.escape(query.applicant_name)}`)
        }

        if (where.length > 0) {
            whereStr += ' where ' + where.join(' and ') + ' '
        }

        sql += whereStr
        sql += " order by create_time desc "
        let countSql = `select count(1) as count from ${TABLE_NAME} as ca` + whereStr

        return doSqlPageQuery<ICustomerChangeAuditVO>(sql, countSql, query)
    },
    // 创建
    async create(params: ICreateCustomerChangeAuditVO) {
        const {customer_id, type, reason, payload} = params
        const {ID, Name} = await AuthService.info()
        /**
         CREATE TABLE `customer_change_audit` (
         `id` int NOT NULL AUTO_INCREMENT COMMENT 'id',
         `customer_id` int NOT NULL COMMENT '操作的客户ID',
         `status` int NOT NULL COMMENT '状态：1、待审核；2、审核通过；3、拒绝',
         `applicant` int DEFAULT NULL COMMENT '申请人',
         `applicant_name` varchar(50) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT '申请人中文名称',
         `reason` varchar(500) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT '变更理由',
         `audit_opinion` varchar(500) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT '审核意见',
         `auditor` int DEFAULT NULL COMMENT '审核人',
         `auditor_name` varchar(50) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT '审核人中文名称',
         `create_time` datetime DEFAULT NULL COMMENT '申请创建时间',
         `audit_time` datetime DEFAULT NULL COMMENT '审核时间',
         `type` int DEFAULT NULL COMMENT '变更类型 1、type 客别变更；2、level 客户等级变更',
         PRIMARY KEY (`id`)
         ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_unicode_ci COMMENT='客户信息变更审核表'
         */
        const sql = SqlString.format(`insert into ${TABLE_NAME}(
            customer_id, status, applicant, applicant_name,
            reason, create_time, type, payload
        ) values (
            ?, ?, ?, ?,
            ?, ?, ?, ?
        )`, [
            customer_id, EnumCustomerChangeAuditStatus.needAudit, ID, Name,
            reason, CURRENT_TIMESTAMP, type, payload
        ])

        return ExecSql(sql)
    },

    // 审核
    async audit(params: IAuditCustomerChangeVO) {
        const {audit_opinion, status, id} = params
        const {ID, Name} = await AuthService.info()
        const sql = SqlString.format(`update ${TABLE_NAME} 
            set status = ?, 
            audit_opinion = ?, 
            auditor = ?, 
            auditor_name = ?, audit_time = ? where id = ?
            `, [
                status, audit_opinion, ID, Name,
                CURRENT_TIMESTAMP,
                id
            ]);

        return ExecSql(sql);
    },

    async deleteByCustomerId(params: {customer_id: number}) {
        const {customer_id} = params
        const sql = `delete from ${TABLE_NAME} where customer_id = ${customer_id}`
        return ExecSql(sql);
    }
}