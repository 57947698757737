import { useCallback, useRef } from 'react';

/**
 * 返回一个不变的函数，保证源函数的变化不会影响到本函数的变更，适用于提供props function给子组件
 * @param callback 回调函数（不断变更）
 */
export const useFunction = (callback: any) => {
  const targetRef = useRef<any>();
  targetRef.current = callback;
  return useCallback((...arr) => {
    return targetRef.current.apply(null, arr);
  }, []);
};
