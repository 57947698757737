import {Button, Form, Input, message, Modal, Select, Space} from "antd";
import {IRoomVO, RoomPhoneTypeList, RoomService} from "../../service/RoomService";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import CityAreaDistrict from "../../components/CityAreaDistrict/CityAreaDistrict";
import BuildingSelector from "../../components/BuildingSelector/BuildingSelector";
import {getPayTypeList} from "../../service/ConfigService";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import useAccess from "../../components/hooks/useAccess";

interface IRoomEditModalProps {
    visible: boolean
    curr?: IRoomVO
    onClose: (fresh?: boolean) => void
}

function formatNum(numStr: string) {
    return parseInt((parseFloat(numStr) * 100).toFixed())
}

function RoomForm({visible, curr, onClose}: IRoomEditModalProps) {
    const instanceRef = useRef<{ isSubmitting: boolean }>({isSubmitting: false})
    const [form] = Form.useForm()
    const [divisible, setVisible] = useState<number>()
    const [payTypeList, setPayTypeList] = useState<{label: string, value: number}[]>([])
    const access = useAccess();

    const canEditPhone = access.room_edit_phone || (!access.room_edit_phone && curr === undefined)

    useEffect(() => {
        if (visible) {
            // console.log('===',curr)
            if (curr) {
                setVisible(curr.divisible)
            }
            form.resetFields()
            let list = []
            try {
                if (curr?.phone_list) {
                    list = JSON.parse(curr?.phone_list)
                }
            } catch (e) {
                console.error('解析JSON对象失败了')
            }

            form.setFieldsValue({
                phone_list_obj: list
            })
        }
    }, [visible, form, curr])

    useEffect(() => {
        getPayTypeList().then((data) => {
            setPayTypeList(data || [])
        })
    }, [])

    function onFormFinished(values: any) {
        if (instanceRef.current.isSubmitting) {
            return
        }

        instanceRef.current.isSubmitting = true

        // console.log(values)

        const params = {...values}
        params.day_rent = formatNum(params.day_rent)
        params.month_rent = formatNum(params.month_rent)
        params.area = formatNum(params.area)
        params.images = JSON.stringify(params.images)
        params.cover_image = params.cover_image[0]

        if (!params.district_area || params.district_area.length !== 6) {
            message.error('请选择完整的区域信息')
            instanceRef.current.isSubmitting = false
            return;
        }

        params.city_id = params.district_area[0]
        params.area_id = params.district_area[1]
        params.district_id = params.district_area[2]
        params.city_name = params.district_area[3]
        params.area_name = params.district_area[4]
        params.district_name = params.district_area[5]

        params.phone_list = params.phone_list_obj
        delete params.phone_list_obj

        // console.log(params)

        // return;

        // instanceRef.current.isSubmitting = false
        // return;
        let api = RoomService.create
        if (curr) {
            params.id = curr.id
            api = RoomService.update
        }

        // instanceRef.current.isSubmitting = false
        // return;

        api(params).then((data) => {
            if (data.Error === null) {
                message.success('操作成功')
                onClose(true)
                return
            }
            if (data.RowsAffected > 0) {
                message.success('操作成功')
                onClose(true)
            } else {
                message.error(data.Error)
            }
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }

    /*
    Title       string `json:"title"`        //  标题
		RoomNo      string `json:"room_no"`      // 房间号
		DayRent     int    `json:"day_rent"`     // 日租金, 用int表示的，需要除100
		MonthRent   int    `json:"month_rent"`   // 月租金, 用int表示的，需要除100
		RentContain string `json:"rent_contain"` // 租金包含
		Area        int    `json:"area"`         // 面积，用int表示的，需要除100
		PayType     int    `json:"pay_type"`     // 付款方式，枚举值
		Divisible   int    `json:"divisible"`    // 1 可以分割，2 不可以分割
		DivideDesc  string `json:"divide_desc"`  // 分割描述信息
		CanRegister int    `json:"can_register"` // 1、可注册 2、不可以注册
		RoomStatus  string `json:"room_status"`  // 房间状态，业务员自己手动填写的
		Detail      string `json:"detail"`       // 详细信息，业务员自己手动填写的
		Phone       string `json:"phone"`        // 业务员电话号码
		Images      string `json:"images"`       // 图片
		CoverImage  string `json:"cover_image"`  // 封面图
		BuildingId  string `json:"building_id"`  // 所属大厦
     */
    return <Form wrapperCol={wrapperCol} labelCol={labelCol} initialValues={curr} form={form} onFinish={onFormFinished}>
        <Form.Item label="标题" name="title" required>
            <Input />
        </Form.Item>
        <Form.Item label="房间号" name="room_no" required>
            <Input />
        </Form.Item>

        <Form.Item label="面积" name="area" required>
            <Input suffix={'平方米'} />
        </Form.Item>

        <Form.Item label="日租金" name="day_rent" required>
            <Input prefix={'¥'} suffix={'元/平方米/天'} />
        </Form.Item>

        <Form.Item label="月租金" name="month_rent" required>
            <Input prefix={'¥'} suffix={'元/月'} />
        </Form.Item>

        <Form.Item label="租金包含" name="rent_contain">
            <Input />
        </Form.Item>

        <Form.Item label="付款方式" name="pay_type" required>
            <Select>
                {
                    payTypeList.map((data) => {
                        return <Select.Option value={data.value} key={data.value}>{data.label}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>

        <Form.Item label="是否可分割" name="divisible" required>
            <Select<number> onChange={(v) => setVisible(v)}>
                <Select.Option value={1}>可以分割</Select.Option>
                <Select.Option value={2}>不可以分割</Select.Option>
            </Select>
        </Form.Item>

        {
            divisible === 1 ? <Form.Item label="分割描述信息" name="divide_desc" required>
                <Input />
            </Form.Item> : null
        }

        <Form.Item label="是否可注册" name="can_register" required>
            <Select>
                <Select.Option value={1}>可注册</Select.Option>
                <Select.Option value={2}>不可以注册</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item label="房间状态" name="room_status" required>
            <Input />
        </Form.Item>

        <Form.Item label="产权方" name="property_party" required>
            <Input />
        </Form.Item>

        <Form.Item label="详细信息" name="detail" required>
            <Input.TextArea  />
        </Form.Item>

        {/*
        <Form.Item label="联系人姓名" name="phone_name" rules={[{max: 20, message: '长度不超过20个字'}]}>
            <Input />
        </Form.Item>

        <Form.Item label="联系人类型" name="phone_type">
            <Select>
                <Select.Option value={0}>未设置</Select.Option>
                <Select.Option value={1}>本人</Select.Option>
                <Select.Option value={2}>代理人</Select.Option>
                <Select.Option value={3}>开发商</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item label="联系电话" name="phone" extra={'非必填，目前在前端页面上展示了固定的电话'}>
            <Input />
        </Form.Item>
        */}

        <Form.Item label={'联系人'} style={{ marginBottom: 0 }}>
            <Form.List name={'phone_list_obj'}>
                {
                    (fields, {add, remove}) => {
                        return <>
                            {
                                fields.map(({key, name}) => {
                                    return <Space key={key} style={{ display: 'flex' }} align="baseline">
                                        <Form.Item name={[name, 'phone_name']}>
                                            <Input placeholder={'联系人姓名'}/>
                                        </Form.Item>
                                        <Form.Item name={[name, "phone_type"]}>
                                            <Select placeholder={'联系人类型'} style={{width: 140}}>
                                                {
                                                    RoomPhoneTypeList.map((item) => {
                                                        return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={[name, "phone"]}>
                                            <Input placeholder={'联系电话'} disabled={!canEditPhone} />
                                        </Form.Item>
                                        {
                                            canEditPhone ? <MinusCircleOutlined onClick={() => remove(name)} /> : null
                                        }
                                    </Space>
                                })
                            }
                            <Form.Item extra={'最多添加3个联系人'}>
                                <Button type="dashed" disabled={(fields.length < 3 &&!canEditPhone) || fields.length >= 3} onClick={() => add()} icon={<PlusOutlined />}>
                                    添加联系人
                                </Button>
                            </Form.Item>
                        </>
                    }
                }
            </Form.List>
        </Form.Item>

        <Form.Item label="图片" name="images" extra={"可以上传多张图片, 每张图片的大小不超过4M"}>
            <ImageUploader multiple limitSize={1024 * 1024 * 4} />
        </Form.Item>

        <Form.Item label="封面图" name="cover_image" extra={"上传一张图片, 图片的大小不超过4M"}>
            <ImageUploader />
        </Form.Item>

        <Form.Item label="所在区域商圈" required name="district_area">
            <CityAreaDistrict />
        </Form.Item>

        {/*<Form.Item label="大厦" name="building_id">*/}
        {/*    <Input />*/}
        {/*</Form.Item>*/}

        <BuildingSelector label={"大厦"} name="building_id" />

        <Form.Item wrapperCol={noLabelWrapperCol}>
            <Button htmlType={'submit'} type={'primary'}>保存</Button>
        </Form.Item>
    </Form>
}



export default function RoomEditModal({ visible, curr, onClose }: IRoomEditModalProps) {
    const doCancel = useCallback(() => {
        onClose()
    },[onClose])

    return <Modal keyboard={false} maskClosable={false} visible={visible} width={800} title={curr ? '编辑': '新增'}  footer={false} onCancel={doCancel}>
        <RoomForm visible={visible} curr={curr} onClose={onClose} />
    </Modal>
}