import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {Button, Form, Input} from "antd";
import {Table} from "antd/es";
import {useEffect, useMemo, useState} from "react";
import {Helper} from "../../utils/Helper";
import {IUserLikeRoomQuery, IUserLikeRoomVO, UserLikeRoomService} from "../../service/UserLikeRoomService";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";

export default function UserLikeRoom() {
    const [queryForm] = Form.useForm()
    const [query, setQuery] = useState<Partial<IUserLikeRoomQuery>>({page_no: 1, page_size: 10})
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [loading, setLoading] = useState<boolean>(false)
    const [list, setList] = useState<IUserLikeRoomVO[]>([])

    const columns = useMemo<ColumnsType<IUserLikeRoomVO>>(() => {
        return [
            // {dataIndex: 'user_id_str', title: '用户ID'},
            // {dataIndex: 'room_id_str', title: '房间ID'},
            {dataIndex: 'user_name', title: '用户'},
            {dataIndex: 'user_phone', title: '用户电话'},
            {dataIndex: 'room_title', title: '房源标题'},
            {dataIndex: 'room_no', title: '房号'},
            // {
            //     title: '操作', render() {
            //         return <>
            //             <Button type={'link'} size={'small'}>详情</Button>
            //         </>
            //     }
            // },
        ]
    }, [])

    useEffect(() => {
        setLoading(true)
        UserLikeRoomService.list(query).then((data) => {
            setList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    function doQueryFormFinished(values: any) {
        setQuery(q => ({...q, ...values, page_no: 1}))
    }

    function doTableChange(pagination: TablePaginationConfig) {
        setQuery((q) => ({
            ...q,
            page_no: pagination.current,
            page_size: pagination.pageSize,
        }))
    }

    return <PageContainer title={'用户收藏记录'}>
        <PageCard>
            <Form form={queryForm} layout={'inline'} onFinish={doQueryFormFinished}>
                <Form.Item name={'user_name'} label={"用户"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'phone'} label={"手机号"}>
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button type={'primary'} htmlType={'submit'}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'reset'}>重置</Button>
                </Form.Item>
            </Form>
        </PageCard>
        <PageCard>
            <Table
                loading={loading}
                dataSource={list}
                columns={columns}
                pagination={page}
                bordered
                size={'middle'}
                rowKey={'id'}
                onChange={doTableChange}
            />
        </PageCard>
    </PageContainer>
}