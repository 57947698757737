import {IPage, IWithKey} from "./interface";
import doSqlPageQuery from "./SqlHelper";
import SqlString from "sqlstring";
import {AuthService} from "./AuthService";
import {ExecSql} from "./r";

export interface ICustomerChangeLogQuery extends IPage {
    customer_id: string
    opt_type: ICustomerChangeOptEnum
}

export enum ICustomerChangeOptEnum {
    // offline = 1, // 下架
    follow = 2, // 跟进信息
    changeLevel = 3,
    changeType = 4,
}

export const ICustomerChangeOptEnumList = [
    // [IRoomChangeOptEnum.offline, '下架房源'],
    [ICustomerChangeOptEnum.follow, '跟进信息'],
    [ICustomerChangeOptEnum.changeLevel, '客户等级'],
    [ICustomerChangeOptEnum.changeType, '客别']
]

export const ICustomerChangeOptEnumMap = new Map(ICustomerChangeOptEnumList as any)

export interface ICustomerChangeLog extends IWithKey{
    id: number
    customer_id: string
    content: string
    create_time: string
    create_time_str: string
    create_by: number
    create_by_name: string
    opt_type: ICustomerChangeOptEnum
}

export const CustomerChangeService = {
    list(query: Partial<ICustomerChangeLogQuery>) {
        const where = []
        let whereStr = ''
        let sql = `select id, customer_id, opt_type, content, create_by, create_by_name, DATE_FORMAT(create_time, '%Y-%m-%d %H:%i:%S') as create_time_str from customer_change_log`
        if (query.customer_id) {
            where.push(`customer_id = ${query.customer_id}`)
        }
        if (query.opt_type) {
            where.push(`opt_type = ${query.opt_type}`)
        }

        if (where.length > 0) {
            whereStr += ' where ' + where.join(' and ') + ' '
        }
        sql += whereStr
        sql += " order by create_time desc "

        let countSql = "select count(1) as count from customer_change_log" + whereStr
        return doSqlPageQuery<ICustomerChangeLog>(sql, countSql, query)
    },

    async create(params: { customer_id: number, content: string, opt_type: ICustomerChangeOptEnum }) {
        const { customer_id, content, opt_type } = params
        const { ID, Name } = await AuthService.info()

        const sql = SqlString.format("insert into customer_change_log(`customer_id`, `content`, `create_by`, `create_by_name`, `opt_type`) values(?, ?, ?, ?, ?)", [
            customer_id, content, ID, Name, opt_type
        ])

        return ExecSql(sql);
    },

    async delete(params: {customer_id: number}) {
        const {customer_id} = params

        const sql = `delete from customer_change_log where customer_id = ${SqlString.escape(customer_id)}`
        return ExecSql(sql);
    }

}

