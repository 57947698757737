import React, {useEffect, useState} from "react";
import {DistrictCreate, DistrictUpdate, IDistrictVO} from "../../service/DistrictService";
import {Button, Form, Input, InputNumber, message, Modal, Radio, Select} from "antd";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import {CityList, ICity} from "../../service/CityService";
import {AreaList, IArea} from "../../service/AreaService";

export interface IDistrictEditModalProps {
    visible: boolean
    curr?: IDistrictVO
    onSuccess?: () => void
    onCancel?: () => void
}

export const DistrictEditModal: React.FC<IDistrictEditModalProps> = ({visible, curr, onCancel, onSuccess}) => {
    const [editForm] = Form.useForm()
    const [cityList, setCityList] = useState<ICity[]>([])
    const [areaList, setAreaList] = useState<IArea[]>([])
    const [selectedCityId, setSelectedCityId] = useState<number>()

    useEffect(() => {
        if (visible) {
            if (curr) {
                if (areaList.length > 0 && areaList[0].CityId === curr.CityId) {
                    return
                } else {
                    AreaList(curr.CityId).then((data) => {
                        setAreaList(data)
                    })
                }
            }
        }
    }, [visible, curr, editForm, areaList])

    useEffect(() => {
        setTimeout(() => {
            editForm.resetFields()
        }, 100)
    }, [visible, editForm])

    useEffect(() => {
        CityList().then((data) => {
            setCityList(data)
        })
    }, [])

    useEffect(() => {
        if (selectedCityId) {
            AreaList(selectedCityId).then((data) => {
                setAreaList(data)
            })
        }
        editForm.setFieldsValue({

        })
    }, [selectedCityId, editForm])

    function onModalCancel() {
        if (onCancel) {
            onCancel()
        }
    }

    function doSuccess() {
        message.success('操作成功')
        if (onSuccess) {
            onSuccess();
        }
    }

    function onFinish(values: IDistrictVO) {
        console.log(curr, values)
        if (curr) {
            DistrictUpdate({
                name: values.Name,
                id: curr.Id,
                sort: values.Sort,
                dict_index: values.DictIndex,
                area_id: values.AreaId,
                enable: values.Enable,
            }).then(() => {
                doSuccess()
            })
        } else {
            DistrictCreate({
                name: values.Name,
                sort: values.Sort,
                dict_index: values.DictIndex,
                area_id: values.AreaId,
                enable: values.Enable,
            }).then(() => {
                doSuccess()
            })
        }
    }

    function doSelectedCityChange(num: number) {
        setSelectedCityId(num)
        editForm.setFieldsValue({
            CityId: num
        })
    }

    return (
        <Modal visible={visible} getContainer={false} destroyOnClose={false} forceRender title={curr ? '编辑' : '新增'} onCancel={onModalCancel} footer={false}>
            <Form form={editForm} wrapperCol={wrapperCol} labelCol={labelCol} initialValues={curr} onFinish={onFinish}>
                <Form.Item label={'城市'} name={'CityId'} rules={[{required: true, message: '请选择城市'}]}>
                    <Select<number> onChange={doSelectedCityChange}>
                        {
                            cityList.map((item) => {
                                return <Select.Option value={item.ID} key={item.ID}>{item.Name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'区域'} name={'AreaId'} rules={[{required: true, message: '请选择区域'}]}>
                    <Select>
                        {
                            areaList.map((item) => {
                                return <Select.Option value={item.ID} key={item.ID}>{item.Name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'名称'} name={'Name'} rules={[{ required: true, message: '请输入该项' }, { max: 100, message: '最大长度不超过100' }]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'名字首字母'} name={'DictIndex'} rules={[{ required: true, message: '请输入该项' }, { pattern: /^[a-z]$/, message: "输入拼音首字母"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'排序值'} name={'Sort'}>
                    <InputNumber />
                </Form.Item>
                <Form.Item label={'启用状态'} name={"Enable"}>
                    <Radio.Group>
                        <Radio value={1}>启用</Radio>
                        <Radio value={2}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item wrapperCol={noLabelWrapperCol}>
                    <Button htmlType={'submit'} type={'primary'}>保存</Button>
                    <Button htmlType={'reset'} style={formButtonStyle}>重置</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}