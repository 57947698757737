import {Helper} from "../../../utils/Helper";
import {Image, Space} from 'antd';

interface IProps {
    urls?: string[] | string
}
export default function Images(props: IProps) {
    const { urls } = props

    if (!urls) {
        return null
    }

    if (Helper.isArray(urls)) {
        return <Space size={15} wrap={true}>
            {
                (urls as string[]).map((item) => {
                    return <Image width={120} height={90} src={item} key={item}/>
                })
            }
        </Space>
    }

    return <Image width={120} height={90} src={urls as string}/>
}