import {Button, Col, Input, Modal, Row} from "antd";
import React, {useEffect, useRef, useState} from "react";

let baiduMapApiLoadingPromise: Promise<any>
function loadBaiduMapApiScript(): Promise<any> {
    if (baiduMapApiLoadingPromise) {
        return baiduMapApiLoadingPromise
    }
    return baiduMapApiLoadingPromise = new Promise<any>((resolve) => {
        (window as any).__baidu_map_initialize = function () {
            resolve(true)
        }
        const script = document.createElement("script");
        script.src = "https://api.map.baidu.com/api?v=1.0&type=webgl&ak=GwQ4RlZDQNdHY2asnsxGKuyRPy3mtGP9&callback=__baidu_map_initialize";
        document.body.appendChild(script);
    })
}

const mapContainerStyle: React.CSSProperties = {
    height: 400,
    width: '100%'
}

type BMapPoint = { lat: number | string, lng: number| string }

interface IProps {
    value?: BMapPoint
    onOk?: (val: BMapPoint) => void
    onCancel?: () => void
}
// 116.49244463949975 40.00641653443463
const BaiduMapContent: React.FC<IProps> = ({value, onOk, onCancel}: IProps) => {
    const mapContainerRef = useRef<HTMLInputElement>(null)
    const baiduMapInstance = useRef<any>(undefined)
    const baiduMapSearch = useRef<(address: string) => void>()
    const baiduMapSetLocation = useRef<(val: BMapPoint)=>void>()

    // 用户暂存回显的值
    const searchRegister = useRef<BMapPoint>()

    const [currValue, setValue] = useState<BMapPoint>();
    const onSearch = function(keyword: string) {
        baiduMapSearch.current?.(keyword)
    };

    useEffect(() => {
        if (value) {
            console.log('123123', value)
            if (baiduMapSetLocation.current) {
                setTimeout(() => {
                    baiduMapSetLocation.current?.(value)
                }, 500)
            } else{
                searchRegister.current = value
            }
        }
    }, [value])

    useEffect(() => {
        let isCancel = false;
        async function init() {
            await loadBaiduMapApiScript();
            if (isCancel) {
                return
            }

            if (mapContainerRef.current) {

            }

            const map = new window.BMapGL.Map(mapContainerRef.current);
            const point = new window.BMapGL.Point(116.404, 39.915);// 116.404, 39.915
            map.centerAndZoom(point, 14);
            map.enableScrollWheelZoom(true);

            var zoomCtrl = new window.BMapGL.ZoomControl()
            map.addControl(zoomCtrl);

            var scaleCtrl = new window.BMapGL.ScaleControl();  // 添加比例尺控件
            map.addControl(scaleCtrl);


            baiduMapInstance.current = map;
            var myGeo = new window.BMapGL.Geocoder();

            map.addEventListener("click", function (e: any) {
                console.log(e)
                // e.latlng.lng, e.latlng.lat
                map.clearOverlays();
                map.centerAndZoom(e.latlng, 16);
                map.addOverlay(new window.BMapGL.Marker(e.latlng))
                setValue(e.latlng)
            })

            function doSearch(address: string) {
                myGeo.getPoint(address, (point?: BMapPoint) => {
                    if (point) {
                        console.log('搜索到了',point)
                        map.clearOverlays();
                        map.centerAndZoom(point, 16);
                        map.addOverlay(new window.BMapGL.Marker(point))
                        setValue(point)
                    } else {
                        console.log('么有搜索到位置哦')
                        setValue(undefined)
                    }
                }, '北京市');
            }

            function baiduMapSetLocationFun(val: BMapPoint){
                // console.log("=========",val)
                map.clearOverlays();
                let isOK = !(Number.isNaN(Number(val.lng)) || Number.isNaN(Number(val.lat)))
                if (isOK) {
                    // console.log('this is okkk', val.lat, val.lng)
                    let p = new window.BMapGL.Point(val.lng, val.lat)
                    setValue(p)
                    map.centerAndZoom(p, 16);
                    map.addOverlay(new window.BMapGL.Marker(p))
                }
            }

            baiduMapSearch.current = doSearch
            baiduMapSetLocation.current = baiduMapSetLocationFun

            if (searchRegister.current) {
                baiduMapSetLocationFun(searchRegister.current)
            }
        }

        init().catch((err) => {
            console.error('初始化地图失败了')
        })

        return () => {
            isCancel = true
            baiduMapInstance.current?.destroy?.()
        }

    }, [])

    function doOk() {
        onOk?.(currValue!)
    }

    return (
        <div>
            <Row gutter={15} style={{marginBottom: 15}}>
                <Col flex={'auto'}>
                    <Input.Search placeholder={'输入地址搜索'} enterButton={'搜索'} onSearch={onSearch}/>
                </Col>
                <Col>
                    <Button style={{marginRight: 15}} disabled={!currValue} type={'primary'} ghost onClick={doOk}>确定</Button>
                    <Button onClick={() => onCancel?.()}>取消</Button>
                </Col>
            </Row>
            <div ref={mapContainerRef} style={mapContainerStyle}/>
        </div>
    )
}


interface IBaiduLocationSelectorProps {
    value?: BMapPoint
    onChange?:(val?:BMapPoint) => void
}
export default function BaiduLocationSelector({value, onChange}: IBaiduLocationSelectorProps) {
    const [visible, setVisible] = useState<boolean>(false)
    const [lat, setLat] = useState<number | string>('')
    const [lng, setLng] = useState<number | string>('')

    const instanceValueRef = useRef<BMapPoint>()
    const [tempVal, setTempVal] = useState<BMapPoint>();

    function triggerChange(value: BMapPoint) {
        instanceValueRef.current = value
        onChange?.(value)
    }

    function onOk(value: BMapPoint) {
        setLng(value.lng)
        setLat(value.lat)
        // setValue({lat: value.lat, lng: value.lng})
        triggerChange({lat: value.lat, lng: value.lng})
        setVisible(false)
    }

    useEffect(() => {
        if (value !== instanceValueRef.current) {
            instanceValueRef.current = value
            if (value) {
                setLat(value.lat)
                setLng(value.lng)
            }
        }
    }, [value])

    function onLngChange(e: React.ChangeEvent<HTMLInputElement>) {
        setLng(e.target.value)
        triggerChange({ lng: e.target.value, lat: lat })
    }

    function onLatChange(e: React.ChangeEvent<HTMLInputElement>) {
        setLat(e.target.value)
        triggerChange({ lng: lng, lat: e.target.value })
    }

    function doSelect() {
        setVisible(true)
        setTempVal(instanceValueRef.current)
    }

    return <>
        <Row gutter={15}>
            <Col>
                <Input placeholder={'输入纬度'} value={lng} onChange={onLngChange} />
            </Col>
            <Col>
                <Input placeholder={'输入经度'} value={lat} onChange={onLatChange} />
            </Col>
            <Col>
                <Button onClick={doSelect}>地图选择</Button>
            </Col>
        </Row>
        <Modal visible={visible} width={720} title={'经纬度选择器'} footer={false} onCancel={() => setVisible(false)}>
            <BaiduMapContent value={tempVal} onOk={onOk} onCancel={() => setVisible(false)}/>
        </Modal>
    </>
}