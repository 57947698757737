import {doPageQuery, Post} from "./r";
import {IPage, IWithKey} from "./interface";

export interface IDistrictVO extends IWithKey{
    AreaId: number
    AreaName: string
    CityId: number
    CityName: string
    DictIndex: string
    Id: number
    Name:string
    Sort: number
    Enable: number
}

export interface IDistrictListParams extends IPage {
    name: string
    city_id: number
    area_id: number
    enable?: number
}

export function DistrictList(query: Partial<IDistrictListParams>) {
    return doPageQuery<IDistrictVO>("/district/list", query)
}

export function DistrictDelete(id: number) {
    return Post("/district/delete", {id})
}

export function DistrictUpdate(params: any) {
    return Post("/district/update", params)
}

export function DistrictCreate(params: any) {
    return Post("/district/create", params)
}