import { PageContainer } from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select, Space,
  Table,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import {
  CooperationService,
  ICooperationParams,
  ICooperationTypeVO,
  ICooperationVO,
} from "../../service/CooperationService";
import { Helper } from "../../utils/Helper";
import { TablePaginationConfig } from "antd/lib/table/interface";
import CooperationTypeDraw from "./CooperationTypeDraw";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import QueryFormItem from "../../components/QueryFormItem/QueryFormItem";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";

export default function CooperationList() {
  const [queryForm] = Form.useForm();
  const [query, setQuery] = useState<Partial<ICooperationParams>>({
    page_no: 1,
    page_size: 10,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editForm] = Form.useForm();
  const instanceRef = useRef<{ isSubmitting: boolean }>({
    isSubmitting: false,
  });
  const [curr, setCurr] = useState<ICooperationVO>();
  const [page, setPage] = useState(Helper.getDefaultPage());
  const [list, setList] = useState<ICooperationVO[]>([]);
  const [showTypeEditModal, setShowTypeEditModal] = useState<boolean>(false);
  const [typeList, setTypeList] = useState<ICooperationTypeVO[]>([]);

  const columns = useMemo<ColumnsType<ICooperationVO>>(() => {
    function doEdit(item: ICooperationVO) {
      return () => {
        setShowEditModal(true);
        let ii = { ...item };
        try {
          (ii as any).serviceImage = JSON.parse(ii.img);
        } catch (e) {
          (ii as any).serviceImage = [];
        }

        setCurr(ii);
        setTimeout(() => {
          editForm.resetFields();
        }, 100);
      };
    }

    function doEnable(item: ICooperationVO) {
      return () => {
        Modal.confirm({
          title: "确认启用",
          content: "启用后在网站首页用户可以看见该服务信息",
          onOk() {
            CooperationService.enable(item.id).then((res) => {
              if (res.RowsAffected !== 0) {
                message.success("操作成功").then(() => {});
                setQuery((q) => ({ ...q }));
              }
            });
          },
          onCancel() {},
        });
      };
    }

    function doDisable(item: ICooperationVO) {
      return () => {
        Modal.confirm({
          title: "确认禁用",
          content: "启用后在网站首页用户将看不见该服务信息",
          onOk() {
            CooperationService.disable(item.id).then((res) => {
              if (res.RowsAffected !== 0) {
                message.success("操作成功").then(() => {});
                setQuery((q) => ({ ...q }));
              }
            });
          },
          onCancel() {},
        });
      };
    }

    function doDelete(item: ICooperationVO) {
      return () => {
        Modal.confirm({
          title: "确认删除",
          content: "该操作不可逆，请谨慎操作",
          onOk() {
            CooperationService.delete(item.id).then((res) => {
              if (res.RowsAffected !== 0) {
                message.success("操作成功").then(() => {});
                setQuery((q) => ({ ...q }));
              }
            });
          },
          onCancel() {},
        });
      };
    }

    return [
      {
        title: "ID",
        dataIndex: "id",
        width: 80,
        align: "center",
      },
      {
        title: "单位名称",
        dataIndex: "name",
      },
      {
        title: "服务业务",
        dataIndex: "business",
      },
      {
        title: "类型",
        dataIndex: "type_name",
      },
      {
        title: "联系人",
        dataIndex: "contacts",
        width: 150,
        align: "center",
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        width: 150,
        align: "center",
      },
      {
        title: "排序值",
        dataIndex: "sort",
        width: 100,
        align: "center",
      },
      {
        title: "状态",
        dataIndex: "status",
        width: 120,
        align: "center",
        render(t) {
          if (t === 1) {
            return <Tag>草稿</Tag>;
          }
          if (t === 2) {
            return <Tag color={"green"}>启用中</Tag>;
          }
          if (t === 3) {
            return <Tag color="red">禁用</Tag>;
          }
          return null;
        },
      },
      {
        title: "操作",
        width: 160,
        fixed: 'right',
        render(_, r) {
          return (
            <div>
              <Button type="link" size={"small"} onClick={doEdit(r)}>
                编辑
              </Button>
              {r.status === 1 || r.status === 3 ? (
                <Button type={"link"} size={"small"} onClick={doEnable(r)}>
                  启用
                </Button>
              ) : null}
              {r.status === 2 ? (
                <Button type={"link"} size={"small"} onClick={doDisable(r)}>
                  禁用
                </Button>
              ) : null}
              <HasPermission code={API_DeleteData}>
                <Button type={"link"} size={"small"} onClick={doDelete(r)} danger>
                  删除
                </Button>
              </HasPermission>
            </div>
          );
        },
      },
    ];
  }, [editForm]);

  function onQueryFormFinished(values: any) {
    console.log(values);
    setQuery((q) => ({ ...values, page_no: 1 }));
  }

  useEffect(() => {
    setLoading(true);
    CooperationService.list(query)
      .then((data) => {
        setList(data.list);
        setPage(data.page);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  const getTypeList = useCallback(() => {
    CooperationService.typeList().then((data) => {
      setTypeList(data);
    });
  }, []);

  useEffect(() => {
    getTypeList();
  }, [getTypeList]);

  function onModalCancel() {
    setShowEditModal(false);
    setCurr(undefined);
  }

  function doNew() {
    setShowEditModal(true);
    setCurr(undefined);
    setTimeout(() => {
      editForm.resetFields();
    }, 100);
  }
  function onTableChange(pagination: TablePaginationConfig) {
    setQuery((q) => ({
      ...q,
      page_no: pagination.current,
      page_size: pagination.pageSize,
    }));
  }

  function onEditFormFinished(values: any) {
    // console.log(values)
    if (instanceRef.current.isSubmitting) {
      return;
    }

    instanceRef.current.isSubmitting = true;

    let params = { ...values };

    if (curr) {
      params.id = curr.id;
    }

    params.img = JSON.stringify(params.serviceImage);
    delete params.serviceImage;
    console.log(params);

    CooperationService.createOrUpdate(params)
      .then(() => {
        message.success("操作成功");
        setQuery(() => ({ ...query }));
        setShowEditModal(false);
      })
      .finally(() => {
        setTimeout(() => {
          instanceRef.current.isSubmitting = false;
        }, 100);
      });
  }

  function doManageCooperationType() {
    setShowTypeEditModal(true);
  }

  return (
    <PageContainer title={"合作服务"}>
      <PageCard>
        <Space size={15}>
          <Button type={"primary"} ghost htmlType={"button"} onClick={doNew}>
            新增
          </Button>
          <Button
              type="primary"
              htmlType="button"
              onClick={doManageCooperationType}
          >
            分类列表
          </Button>
        </Space>
      </PageCard>
      <PageCard isQueryForm>
        <Form form={queryForm} layout="inline" onFinish={onQueryFormFinished} labelCol={{ flex: '80px' }}>
          <QueryFormItem>
            <Form.Item label={"类型"} name={"type"}>
              <Select placeholder="请选择" allowClear>
                {typeList.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </QueryFormItem>

          <QueryFormItem>
          <Form.Item label={"单位名称"} name={"name"}>
            <Input />
          </Form.Item>
          </QueryFormItem>

          <QueryFormItem>
          <Form.Item label={"服务业务"} name={"business"}>
            <Input />
          </Form.Item>
          </QueryFormItem>

          <QueryFormItem>
          <Form.Item label={"联系人"} name={"contacts"}>
            <Input />
          </Form.Item>
          </QueryFormItem>

          <QueryFormItem>
          <Form.Item label={"电话号码"} name={"phone"}>
            <Input />
          </Form.Item>
          </QueryFormItem>

          <QueryFormItem>
          <Form.Item label={"状态"} name={"status"}>
            <Select allowClear>
              <Select.Option value={1}>草稿</Select.Option>
              <Select.Option value={2}>启用</Select.Option>
              <Select.Option value={3}>禁用</Select.Option>
            </Select>
          </Form.Item>
          </QueryFormItem>

          <QueryFormItem width={'auto'} fixedRight={true}>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                查询
              </Button>
              <Button htmlType={"reset"} style={{marginLeft: 15}}>重置</Button>
            </Form.Item>
          </QueryFormItem>
        </Form>
      </PageCard>
      <PageCard>
        <Table
          tableLayout={"fixed"}
          columns={columns}
          dataSource={list}
          size={"middle"}
          rowKey={"key"}
          pagination={page}
          loading={loading}
          onChange={onTableChange}
          bordered
          scroll={{x: 1400}}
        />
      </PageCard>

      <Modal
        width={500}
        visible={showEditModal}
        title={curr ? "编辑" : "新增"}
        onCancel={onModalCancel}
        footer={false}
      >
        <Form
          form={editForm}
          initialValues={curr}
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          onFinish={onEditFormFinished}
        >
          <Form.Item
            label={"单位名称"}
            name={"name"}
            rules={[
              { required: true, message: "该项必填" },
              { min: 2, message: "最少2个字" },
              { max: 100, message: "最长100个字" },
            ]}
          >
            <Input placeholder={"请输入服务单位名称"} />
          </Form.Item>

          <Form.Item
            label={"服务业务"}
            name={"business"}
            rules={[
              { required: true, message: "该项必须填写" },
              { min: 2, message: "最少2个字" },
              // { max: 20, message: "最长20个字" },
            ]}
            extra={<div>这里输入服务业务内容，比如：绿植租赁，保洁服务。</div>}
          >
            <Input.TextArea
              placeholder={"请输入服务业务内容"}
              autoSize={{ minRows: 3, maxRows: 10 }}
            />
          </Form.Item>

          <Form.Item
            label={"图片"}
            name={"serviceImage"}
            rules={[{ required: true, message: "图片必填" }]}
          >
            <ImageUploader />
          </Form.Item>

          <Form.Item
            label={"服务类型"}
            name={"type"}
            rules={[{ required: true, message: "服务类型必填" }]}
          >
            <Select>
              {typeList.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={"联系人"}
            name={"contacts"}
            rules={[{ required: true, message: "联系人必填" }]}
          >
            <Input placeholder={"输入联系人姓名"} />
          </Form.Item>

          <Form.Item
            label={"联系电话"}
            name={"phone"}
            rules={[{ required: true, message: "联系电话必填" }]}
          >
            <Input placeholder={"输入联系电话"} />
          </Form.Item>

          <Form.Item
            label={"排序"}
            name={"sort"}
            rules={[{ required: true, message: "请输入排序值" }]}
          >
            <InputNumber min={0} max={1000} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Button htmlType={"submit"} type={"primary"}>
              保存
            </Button>
            <Button htmlType={"reset"} style={{ marginLeft: 25 }}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <CooperationTypeDraw
        visible={showTypeEditModal}
        onClose={() => {
          getTypeList();
          setShowTypeEditModal(false);
        }}
      />
    </PageContainer>
  );
}
