import {Modal, Checkbox, Button, message, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {ICurrSessionInfo} from "../../../service/AuthService";
import {IEmployeeVO} from "../../../service/EmployeeService";
import {IRoleVO, IUserRoleUpdateVO, RoleService} from "../../../service/RoleService";
import {CheckboxValueType} from "antd/es/checkbox/Group";

interface IProps {
    visible: boolean
    onSuccess?: () => void
    onCancel?: () => void
    curr: IEmployeeVO,
    userInfo: ICurrSessionInfo
}

export const UserRoleSetting: React.FC<IProps> = ({visible, curr, onCancel, userInfo,onSuccess}: IProps) => {
    const [roleList, setRoleList] = useState<IRoleVO[]>([])
    const [checkedKeys, setCheckedKeys] = useState<CheckboxValueType[]>()
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        setLoading(true)
        RoleService.list({page_no: 1, page_size: 200}).then((data) => {
            setRoleList(data.list)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (visible && curr) {
            setLoading(true)
            RoleService.userRoleList(curr.id).then((data) => {
                // console.log('data')
                if (data) {
                    setCheckedKeys(data.map(v=> v.role_id))
                } else {
                    setCheckedKeys(undefined)
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [visible, curr])

    function doCheckboxGroupChange(val: CheckboxValueType[] | undefined) {
        // console.log(val);
        setCheckedKeys(val)
    }

    function doSave() {
        console.log(checkedKeys)
        let res: IUserRoleUpdateVO[] = []
        if (checkedKeys && checkedKeys?.length > 0) {
            res = checkedKeys.map((id) => {
                return {
                    user_id: curr.id,
                    role_id: id,
                    create_by: userInfo.ID,
                    create_by_name: userInfo.Name
                } as IUserRoleUpdateVO
            })
        }

        setLoading(true)
        RoleService.updateUserRole(res, curr.id).then((resVal: boolean | string) => {
            if (resVal === true) {
                message.success("设置成功").then(() => void 0)
                onSuccess?.()
                setCheckedKeys([])
            } else {
                message.error(resVal).then(() => void 0)
            }
        }).finally(() => {
            setLoading(false)
        })

    }

    return (
        <Modal visible={visible} forceRender title={`编辑角色 - ${curr?.name}`} footer={false} onCancel={onCancel}>
            <Spin spinning={loading}>
            <div>
                <div style={{marginBottom: 20}}>选择对应的角色</div>
                <Checkbox.Group onChange={doCheckboxGroupChange} value={checkedKeys}>
                    {
                        roleList.map((item) => {
                            return <Checkbox key={item.id} value={item.id}>{item.role_name}</Checkbox>
                        })
                    }
                </Checkbox.Group>
            </div>

            <div style={{marginTop: 20}}>
                <Button onClick={doSave}>保存</Button>
            </div>
            </Spin>

        </Modal>
    )
}