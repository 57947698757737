import {IRoomVO} from "../../../service/RoomService";
import React, {useReducer, useState} from "react";
import {Button, Drawer, message} from "antd";
import RoomChangeLog from "./RoomChangeLog";
import {IRoomChangeOptEnum} from "../../../service/RoomChangeLogService";
import ChangeLogModal from "./ChangeLogModal";

interface IProps {
    visible: boolean
    room?: IRoomVO
    onClose: () => void
}
export default function RoomChangeLogInfo(props: IProps) {
    const { visible, room, onClose } = props
    const [showEditChangeLog, setShowEditChangeLog] = useState(false)

    function doWriteFollowMsg() {
        setShowEditChangeLog(true)
    }

    const [freshFlag, forceUpdate] = useReducer(x => x + 1, 0)

    return <>
        <Drawer
            visible={visible}
            width={'900px'}
            placement={'right'}
            title={'房源跟进信息'}
            onClose={onClose}
            extra={
                <div>
                    <Button type={'primary'} onClick={doWriteFollowMsg}>填写跟进信息</Button>
                </div>
            }
        >
            <RoomChangeLog roomId={room?.id} freshFlag={freshFlag} />
        </Drawer>

        <ChangeLogModal
            optType={IRoomChangeOptEnum.follow}
            visible={showEditChangeLog}
            room={room}
            onCancel={() => {
                setShowEditChangeLog(false)
            }}
            onSuccess={() => {
                setShowEditChangeLog(false)
                message.success('跟进信息填写成功').then(() => ({}))
                forceUpdate()
            }}
        />
    </>
}