import SqlString from 'sqlstring'
import {ExecQuery, ExecSql} from "./r";
export function getConfig(key: string) {
    const sql = SqlString.format("select `value` from sys_config where `key` = ?", [key])
    return ExecQuery<{value: string}[]>(sql)
}

export function saveConfig(key: string, value: string) {
    const sql = SqlString.format("update sys_config set `value` = ? where `key` = ?", [value, key])
    return ExecSql(sql)
}

const BusinessConfigKey = "BusinessConfig"

export function getBusinessConfig() {
    return getConfig(BusinessConfigKey);
}

export function saveBusinessConfig(value: string) {
    return saveConfig(BusinessConfigKey, value)
}

export async function getBusinessConfigForKey(key: string) {
    const data = await getBusinessConfig()
    try {
        if(data.length > 0) {
            const configData = JSON.parse(data[0].value)
            return configData[key]
        }
    } catch (e) {
        console.error('解析参数错误')
    }
    return null
}

export function getPayTypeList() {
    return getBusinessConfigForKey("付款方式") as Promise<{label: string, value: number}[]>
}