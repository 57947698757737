import PageCard from "../../layout/PageCard";
import {Button, Form, Input, InputNumber, message, Modal, Select, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {BannerSettingsService, IBannerVO} from "../../service/BannerSettingsService";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import ImageViewer from "../../components/ImageView/ImageViewer";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";

enum BannerStatus {
    NotEffect = 1, // 待生效
    Normal = 2,
}

const BannerStatusList = [
    { name: '待生效', type: BannerStatus.NotEffect },
    { name: '已生效', type: BannerStatus.Normal },
]

const BannerStatusListMap = (() => {
    let map = new Map<BannerStatus, string>()
    BannerStatusList.forEach((item) => {
        map.set(item.type, item.name)
    })
    return map
})()

interface IProps {
    visible: boolean
    onSuccess?: () => void
    onCancel?: () => void
    curr?: IBannerVO
}

const BannerEditForm: React.FC<IProps> = ({ visible, onSuccess, onCancel, curr }) => {
    const [form] = Form.useForm()
    useEffect(() => {
        if (visible) {
            form.resetFields()
        }
    }, [curr, form, visible])
    function doFinished(values: IBannerVO) {
        if (curr) {
            BannerSettingsService.updateItem({...values, id: curr.id}).then((data) => {
                if (data.Error === null) {
                    onSuccess?.()
                }
            })
        } else {
            BannerSettingsService.insertItem({...values}).then((data) => {
                if (data.Error === null) {
                    onSuccess?.()
                }
            })
        }
    }
    return <Form form={form} initialValues={curr} labelCol={labelCol} wrapperCol={wrapperCol} onFinish={doFinished}>
        <Form.Item name={'img_url'} label={'图片'} rules={[{required: true, message: '请选择图片上传'}]}>
            <ImageUploader />
        </Form.Item>
        <Form.Item name={'desc'} label={'描述信息'} rules={[{required: true, message: '该信息必填'}]}>
            <Input />
        </Form.Item>
        <Form.Item name={'status'} label={'状态'} rules={[{ required: true, message: '请选择状态' }]}>
            <Select placeholder={'请选择'}>
                {
                    BannerStatusList.map((item) => {
                        return <Select.Option value={item.type} key={item.type}>{item.name}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
        <Form.Item name={'sort'} label={'排序值'} extra={'值越小排在越靠前的位置'} rules={[{ required: true, message: '请填写排序值' }]}>
            <InputNumber min={0} max={1000} />
        </Form.Item>

        <Form.Item wrapperCol={noLabelWrapperCol}>
            <Button type={'primary'} htmlType={'submit'}>保存</Button>
            <Button style={formButtonStyle} htmlType={'reset'}>重置</Button>
        </Form.Item>
    </Form>
}

const BannerEditModal: React.FC<IProps> = ({curr, visible, onCancel, onSuccess}) => {
    return <Modal visible={visible} forceRender title={curr ? '编辑' : '新增'} footer={false} onCancel={onCancel}>
        <BannerEditForm visible={visible} onSuccess={onSuccess} onCancel={onCancel} curr={curr}/>
    </Modal>
}

export default function BannerSettings() {
    const [list, setList] = useState<IBannerVO[]>([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [curr, setCurr] = useState<IBannerVO>()
    const [loading, setLoading] = useState(false)

    const loadList = useCallback(() => {
        setLoading(true)
        BannerSettingsService.list().then((data) => {
            setList(data)
        }).finally(()=> {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        loadList();
    }, [loadList])

    function doClickEdit(item: IBannerVO) {
        return () => {
            if (typeof item.img_url === 'string') {
                setCurr({...item, img_url: [item.img_url]})
            } else {
                setCurr(item)
            }

            setShowEditModal(true)
        }
    }

    function doClickDelete(item: IBannerVO) {
        return () => {
            Modal.confirm({
                title: '确定删除',
                onOk() {
                    BannerSettingsService.deleteItem(item.id).then((data) => {
                        if (data.Error === null) {
                            message.success('删除成功')
                            loadList();
                        }
                    })
                }
            })
        }
    }

    function doNew() {
        setCurr(undefined)
        setShowEditModal(true)
    }

    return <>
        <PageCard>
          <Button onClick={doNew}>新增</Button>
        </PageCard>
        <PageCard>
            <Table tableLayout={'fixed'} bordered  dataSource={list} loading={loading} size={'middle'} pagination={false} rowKey={'id'}>
                <Table.Column dataIndex={'img_url'} title={'图片'} render={(t) => {
                    return <ImageViewer src={t} />
                }}/>
                <Table.Column dataIndex={'desc'} title={'描述'}/>
                <Table.Column dataIndex={'status'} title={'状态'} render={(t) => {
                    return BannerStatusListMap.get(t)
                }}/>

                <Table.Column dataIndex={'sort'} title={'排序值'}/>
                <Table.Column<IBannerVO> title={'操作'} render={(t, r) => {
                    return <>
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doClickEdit(r)}>编辑</Button>
                        <HasPermission code={API_DeleteData} inApi>
                            <Button htmlType={'button'}
                                    type={'link'}
                                    size={'small'}
                                    onClick={doClickDelete(r)}
                                    danger>删除</Button>
                        </HasPermission>
                    </>
                }}/>
            </Table>
        </PageCard>
        <BannerEditModal
            visible={showEditModal}
            onCancel={() => {
                setShowEditModal(false)
            }}
            onSuccess={() => {
                setShowEditModal(false)
                loadList()
            }}
            curr={curr}
        />
    </>
}