import {Home} from "../pages/Home";
import React from "react";
import {City} from "../pages/City";
import {Area} from "../pages/Area";
import {District} from "../pages/District/District";
import {RouteComponentProps} from "react-router";
import {
    UserOutlined,
    UploadOutlined,
    NumberOutlined,
    EnvironmentOutlined,
    SettingOutlined,
    HomeOutlined, HeartOutlined, BankOutlined, FundViewOutlined, TeamOutlined
} from "@ant-design/icons";
// import SqlTest from "../pages/Test/SqlTest";
// import OSSTest from "../pages/Test/OSSTest";
import EmployeeList from "../pages/Employee/EmployeeList";
import UsersList from "../pages/Users/UsersList";
import NewsList from "../pages/News/NewsList";
import CooperationList from "../pages/Cooperation/CooperationList";
import BuildingList from "../pages/Building/BuildingList";
import {RoomList} from "../pages/Room/RoomList";
import EntrustList from "../pages/Entrust/EntrustList";
import Settings from "../pages/Settings/Settings";
import UserLikeRoom from "../pages/UserLikeRoom/UserLikeRoom";
import CustomerList from "../pages/Customer/index";

interface IRoutesItem {
    path: string,
    title: string,
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined,
    icon?: any,
    visible?: boolean
    selectPath?: string
    access?: string
    routes?: IRoutesItem[]
}

export const routes: IRoutesItem[] = [
    {
        path: '/',
        title: '首页',
        icon: HomeOutlined,
        Component: Home
    },
    {
        path: '/city',
        title: '城市',
        icon: NumberOutlined,
        access: "normalRouteFilter",
        Component: City
    },
    {
        path: '/area',
        title: '区域',
        visible: false,
        selectPath: "/city",
        icon: UploadOutlined,
        access: "normalRouteFilter",
        Component: Area
    },
    {
        path: "/district",
        title: '商圈',
        icon: EnvironmentOutlined,
        access: "normalRouteFilter",
        Component: District
    },
    {
        path: "/employee",
        title: '员工管理',
        icon: UserOutlined,
        access: "normalRouteFilter",
        Component: EmployeeList
    },
    {
        path: "/users",
        title: '用户管理',
        icon: UserOutlined,
        access: "normalRouteFilter",
        Component: UsersList
    },
    {
        path: "/news",
        title: '新闻',
        icon: NumberOutlined,
        access: "normalRouteFilter",
        Component: NewsList
    },
    {
        path: "/cooperation",
        title: '合作服务',
        icon: NumberOutlined,
        access: "normalRouteFilter",
        Component: CooperationList
    },
    {
        path: "/building",
        title: '大厦&项目',
        icon: BankOutlined,
        access: "normalRouteFilter",
        Component: BuildingList
    },
    {
        path: "/room",
        title: '房源',
        icon: BankOutlined,
        access: "normalRouteFilter",
        Component: RoomList
    },
    {
        path: "/entrust",
        title: '企业服务',
        icon: FundViewOutlined,
        access: "normalRouteFilter",
        Component: EntrustList
    },
    {
        path: "/user_like_room",
        title: '用户收藏',
        icon: HeartOutlined,
        access: "normalRouteFilter",
        Component: UserLikeRoom
    },
    {
        path: "/customer",
        title: '客源管理',
        icon: TeamOutlined,
        access: "normalRouteFilter",
        Component: CustomerList
    },
    {
        path: "/settings",
        title: '系统设置',
        icon: SettingOutlined,
        access: "normalRouteFilter",
        Component: Settings
    },
    /*{
        path: "/test/sql_test",
        title: 'SQL测试',
        visible: false,
        icon: NumberOutlined,
        Component: SqlTest
    },
    {
        path: "/test/oss_test",
        title: 'OSS测试',
        visible: false,
        icon: NumberOutlined,
        Component: OSSTest
    }*/
]