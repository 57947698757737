import {useHistory } from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {Post} from "../service/r";
import {Button, Form, Input, InputNumber, message, Modal, Radio, Table} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "./common";
import {PageContainer} from "../layout/PageContainer";
import {AreaList, IArea} from "../service/AreaService";
import PageCard from "../layout/PageCard";
import {useQuery} from "../components/hooks/useQuery";
import HasPermission from "../components/HasPermission/HasPermission";
import {API_DeleteData} from "../components/HasPermission/apiPermission";

export function Area() {
    const {id} = useQuery<"id">()
    const [list, setList] = useState<IArea[]>([])
    const [curr, setCurr] = useState<IArea>()
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [editForm] = Form.useForm()
    const history = useHistory()
    const [query, setQuery] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    const columns = useMemo<ColumnsType<IArea>>(() => {
        function doShowDistrictSetting(item: IArea) {
            return () => {
                history.push("/district")
            }
        }

        function doEdit(item: IArea) {
            return () => {
                setShowEditModal(true)
                setCurr(item)
                setTimeout(() => {
                    editForm.resetFields()
                }, 100)
            }
        }

        function doDelete(r: IArea) {
            return () => {
                Modal.confirm({
                    title: '确认删除',
                    content: '区域信息是基础数据，确认删除，删除后将影响对应的商圈数据。',
                    onOk() {
                        Post('/area/delete', { id: r.ID }).then(() => {
                            message.success('操作成功')
                            setQuery({})
                        })
                    }
                })
            }
        }
        return [
            {
                title: '#',
                dataIndex: 'ID',
                width: 120,
            },
            {
                title: '区域名称',
                dataIndex: 'Name',
            },
            {
                title: '排序值',
                dataIndex: 'Sort',
            },
            {
                dataIndex: 'Enable',
                title: '状态',
                render(t) {
                    if (t===1) {
                        return <span style={{color: "green"}}>启用</span>
                    } else if (t===2) {
                        return <span style={{color: "red"}}>禁用</span>
                    } else {
                        return t
                    }
                }
            },
            {
                title: '操作',
                render(t, r) {
                    return <div>
                        <Button size={'small'} type={'link'} onClick={doEdit(r)}>编辑</Button>
                        <Button size={'small'} type={'link'} onClick={doShowDistrictSetting(r)}>商圈设置</Button>
                        <HasPermission code={API_DeleteData} inApi>
                            <Button size={'small'} type={'link'} danger onClick={doDelete(r)}>删除</Button>
                        </HasPermission>
                    </div>
                }
            }
        ]
    }, [editForm, history])
    useEffect(() => {
        if (id) {
            let idNum = parseInt(id)
            if (idNum) {
                setLoading(true)
                AreaList(idNum).then((data) => {
                    // console.log(data.data)
                    setList(data)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }

    }, [id,query])

    function doNewArea() {
        setCurr(undefined)
        setShowEditModal(true)
        setTimeout(() => {
            editForm.resetFields()
        }, 100)
    }

    function doCancel(){
        setShowEditModal(false)
        setCurr(undefined)
    }

    function onEditFormFinished(values: IArea) {
        // console.log(values)
        if (curr?.ID) {
            Post("/area/update", {
                id: curr.ID,
                name: values.Name,
                city_id: curr?.CityId,
                sort: values.Sort,
                enable: values.Enable
            }).then(() => {
                message.success("操作成功")
                setQuery({})
                setShowEditModal(false)
            })
        } else {
            if (!id) {
                return
            }
            Post("/area/create", {
                name: values.Name,
                city_id: parseInt(id, 10),
                sort: values.Sort,
                enable: values.Enable
            }).then(() => {
                message.success("新增成功")
                setQuery({})
                setShowEditModal(false)
            })
        }
    }

    return (
        <PageContainer title={'区域'}>
            <PageCard>
                <Button onClick={doNewArea} type={'primary'}>新增</Button>
            </PageCard>

            <PageCard>
                <Table
                    tableLayout={'fixed'}
                    bordered
                    rowKey={r => r.ID}
                    columns={columns}
                    dataSource={list}
                    size={'middle'}
                    pagination={false}
                    loading={loading}
                />
            </PageCard>

            <Modal title={!curr ? '新增区域' : '编辑区域'} visible={showEditModal} footer={false} onCancel={doCancel}>
                <Form form={editForm} initialValues={curr} wrapperCol={wrapperCol} labelCol={labelCol} onFinish={onEditFormFinished}>
                    <Form.Item name={'Name'} label={'区域名称'} required rules={[{required: true, message: '该项必填'}, { max: 100, message: '最大长度不超过100个' }]}>
                        <Input placeholder={'输入区域名称，比如: 朝阳区'}/>
                    </Form.Item>
                    <Form.Item name={"Sort"} label={'排序值'} extra={'值越小在网站上展示的时候就越靠前，最大值不超过1000，最小值不能小于1'} required rules={[{required: true, message: '该项必填'}]}>
                        <InputNumber min={1} max={1000} placeholder={'输入数字'} />
                    </Form.Item>
                    <Form.Item label={'启用状态'} name={"Enable"}>
                        <Radio.Group>
                            <Radio value={1}>启用</Radio>
                            <Radio value={2}>禁用</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={noLabelWrapperCol}>
                        <Button htmlType={'submit'} type={'primary'}>保存</Button>
                        <Button htmlType={'reset'} style={formButtonStyle}>重置</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </PageContainer>
    )
}