import {Post} from "./r";

interface ILoginDto {
    login_name: string
    password: string
}

interface ILoginResponse {
    "ID":        number,
    "LoginName": string,
    "Name":      string,
    "Status":    number,
}

export interface ICurrSessionInfo {
    ID: number
    LoginName: string
    Name: string
    Email: string
    Phone: string
}

export interface IResetPasswordDto {
    old_password: string
    new_password: string
    re_new_password: string
}

export const AuthService = {
    login(data: ILoginDto) {
        return Post<ILoginResponse>("/auth/login", data)
    },
    logout() {
        return Post("/auth/logout")
    },
    info() {
        return Post<ICurrSessionInfo>("/auth/info")
    },
    resetPassword(p: IResetPasswordDto) {
        return Post("/auth/reset_password", p)
    }
}