import styles from './layout.module.css'
import React from "react";

interface IPageContainer {
    title?: string
}
export const PageContainer: React.FC<IPageContainer> = ({title, children}) => {
    return <div className={styles.PageContainer}>
        <div className={styles.PageTitle}>
            {title}
        </div>
        <div className={styles.PageContent}>
            {children}
        </div>
    </div>
}