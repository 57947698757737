import {IPage, IWithKey} from "./interface";
import SqlString  from "sqlstring"
import {ExecQuery, ExecSql} from "./r";
import {AuthService} from "./AuthService";
import doSqlPageQuery from "./SqlHelper";

export enum UserTypeEnum {
    Normal = 1,
    VIP1 = 2,
    VIP2 = 3,
}

export const UserTpeList = [
    { name: '普通用户', type: UserTypeEnum.Normal },
    { name: 'VIP', type: UserTypeEnum.VIP1 },
    { name: '超级VIP', type: UserTypeEnum.VIP2 },
]

export const UserTpeListMap = (() => {
    const map = new Map<number, string>()
    UserTpeList.forEach((item) => {
        map.set(item.type, item.name)
    })
    return map
})()

export enum UserStatusEnum {
    Normal = 1, // 正常状态
    Disabled = 2, // 禁用状态
}

export const UserStatusList = [
    { name: '正常', type: UserStatusEnum.Normal },
    { name: '禁用', type: UserStatusEnum.Disabled },
]

export const UserStatusListMap = (() => {
    const map = new Map<number, string>()
    UserStatusList.forEach((item) => {
        map.set(item.type, item.name)
    })
    return map
})()

export interface IUserVO extends IWithKey {
    uuid: string
    phone: string
    name: string
    email: string
    type: number
    wechat_openid: string
    status: number
}

export interface IUserListParams extends IPage {
    name: string
    phone: string
    type: number
}

export interface UpdateUserTypeDTO {
    uid: string
    type: number
    desc: string
}

export const UserService = {
    async list(query: Partial<IUserListParams>) {
        const whereValue = []
        let whereStr = ""
        if (query.name) {
            whereValue.push("`name` like " + SqlString.escape("%" + query.name +"%"))
        }
        if (query.type) {
            whereValue.push("`type` = " + SqlString.escape(query.type))
        }
        if (query.phone) {
            whereValue.push("`phone` = " + SqlString.escape(query.phone))
        }

        let sql = `
select 
    bin_to_uuid(id) as uuid, 
    name, 
    phone, 
    email, 
    type, 
    wechat_openid, 
    status, 
    DATE_FORMAT(create_time, '%Y-%m-%d %H:%i:%S') as create_time_str
from users`

        if (whereValue.length > 0) {
            whereStr = " where " + whereValue.join(' and ') + " "
        }
        sql += whereStr
        const countSql = SqlString.format(`select count(1) as count from users ` + whereStr)

        return doSqlPageQuery<IUserVO>(sql, countSql, query)
    },

    async setUserType(params: UpdateUserTypeDTO) {
        const CURRENT_TIMESTAMP = { toSqlString: function() { return 'CURRENT_TIMESTAMP()'; } };
        let sql1 = SqlString.format("update users set type = ? where id = uuid_to_bin(?)", [params.type, params.uid])
        let dd = await ExecSql(sql1)

        if (dd.Error !== null) {
            return dd
        }

        const { ID } = await AuthService.info()
        const p = [ params.uid, params.type, params.desc, ID, CURRENT_TIMESTAMP ]
        let sql = SqlString.format("insert into vip_history(`uid`, `type`, `desc`, `create_by`, `create_time`) values (uuid_to_bin(?), ?, ?, ?, ?)", p)
        return ExecSql(sql)
    },

    async vipHistoryList(uid: string) {
        const sql = SqlString.format("select " +
            "a.*, " +
            "DATE_FORMAT(a.create_time, '%Y-%m-%d %H:%i:%S') as create_time_str, " +
            "e.name as create_by_name " +
            "from vip_history as a " +
            "left join employee as e on e.id = a.create_by where uid = uuid_to_bin(?) order by create_time desc", [uid])
        return ExecQuery(sql)
    }
}