import {TablePaginationConfig} from "antd/lib/table/interface";


export const Helper = {
    genKey(): string {
        return Math.random().toString(16).substr(2) + Math.random().toString(16).substr(2)
    },

    getDefaultPage(): TablePaginationConfig {
        return {
            total: 0,
            current: 1,
            pageSize: 10,
            showQuickJumper: true
        }
    },

    isValidDate(date: any) {
        return date instanceof Date && !isNaN(date.getTime())
    },

    isArray(obj: any) {
        return Object.prototype.toString.call(obj) === '[object Array]'
    },

    formatDate(dateString: string | undefined) {
        if (!dateString) {
            return ''
        }
        let date = new Date(dateString)
        let yy = date.getFullYear()
        let mm = date.getMonth()
        let dd = date.getDate()
        let hh = date.getHours()
        let m = date.getMinutes()
        let s = date.getSeconds()
        if (!Helper.isValidDate(date)) {
            return ''
        }

        if (yy === 1) {
            return ''
        }

        function p2(num: number) {
            return ("0" + num).substr(-2);
        }

        return `${yy}-${p2(mm + 1)}-${p2(dd)} ${p2(hh)}:${p2(m)}:${p2(s)}`
    },
    formatToUrlStringArr(str: string) {
        try {
            if (str.startsWith("http")) {
                return [str]
            }
            let obj = JSON.parse(str)
            if (typeof obj === "string" && obj.startsWith("http")) {
                return [obj]
            }
            if (Helper.isArray(obj)) {
                return obj;
            }
        } catch (err) {
            console.log('解析结束', str)
            return []
        }
    },

    debounce<P extends any[], T>(func: (...args: P) => Promise<T>, delay: number) {
        let timer: any = 0;
        return (callBack: (args: T) => void, ...args: P) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args).then(callBack);
            }, delay);
        };
    }

}