import {IPage, IWithKey} from "./interface";
import SqlString from "sqlstring";
import doSqlPageQuery from "./SqlHelper";
import {AuthService} from "./AuthService";
import {ExecSql} from "./r";

export enum EntrustStatusEnum {
    NeedTodo = 1,
    DOING = 2,
    DONE = 3,
}

export const EntrustStatusList = [
    { name: "待处理", type: EntrustStatusEnum.NeedTodo },
    { name: "处理中", type: EntrustStatusEnum.DOING },
    { name: "处理完毕", type: EntrustStatusEnum.DONE },
]

export const EntrustStatusListMap = (() => {
    const map = new Map<number, string>()
    EntrustStatusList.forEach((item) => {
        map.set(item.type, item.name)
    })
    return map
})()

export enum EntrustTypeEnum {
    FindHouse = 1, // 找房
    RentHouse = 2, // 出租
    Other = 99, // 其他
}

export const EntrustTypeEnumList  = [
    { name: '我要找房', type: EntrustTypeEnum.FindHouse },
    { name: '有房出租', type: EntrustTypeEnum.RentHouse },
    { name: '商务合作', type: EntrustTypeEnum.Other },
]

export const EntrustTypeListMap = (() => {
    const map = new Map<number, string>()
    EntrustTypeEnumList.forEach((item) => {
        map.set(item.type, item.name)
    })
    return map
})()

export interface IEntrustVO extends IWithKey {
    id: number
    user_name: string
    phone: string
    type: number
    detail: string
    status: number
    create_time: string
    update_time: string
    last_update_by: string
    remarks: string
}

export interface IEntrustListQuery extends IPage {
    user_name: string
    phone: string
    status: number
}

export const EntrustService = {
    async list(query: Partial<IEntrustListQuery>) {
        const where = []
        let whereStr = "";
        let sql = "select " +
            "`a`.`id`, `a`.`user_name`, `a`.`phone`, `a`.`type`, `a`.`email`, `a`.`ip`," +
            "`a`.`detail`, `a`.`status`, `a`.`update_time`, " +
            "DATE_FORMAT(`a`.`create_time`, '%Y-%m-%d %H:%i:%S') as create_time_str, " +
            "DATE_FORMAT(`a`.`update_time`, '%Y-%m-%d %H:%i:%S') as update_time_str, " +
            "`a`.`last_update_by`, `e`.`name` as last_update_by_name, `a`.`remarks` from entrust as a left join employee as e on e.id = a.last_update_by "
        if (query.user_name) {
            where.push("`a`.`user_name` like " + SqlString.escape("%" + query.user_name +"%"))
        }

        if (query.phone) {
            where.push("`a`.`phone` = " + SqlString.escape(query.phone))
        }

        if (query.status) {
            where.push("`a`.`status` = " + SqlString.escape(query.status))
        }

        if (where.length > 0) {
            whereStr = " where " + where.join(' and ') + " "
        }

        sql += whereStr

        sql += " order by `a`.`create_time` desc "

        let countSql = "select count(1) as count from entrust as a " + whereStr
        return await doSqlPageQuery<IEntrustVO>(sql, countSql, query)
    },

    async handleEntrust(id: number, status: number, remarks: string) {
        const CURRENT_TIMESTAMP = { toSqlString: function() { return 'CURRENT_TIMESTAMP()'; } };
        const { ID } = await AuthService.info()
        const params = [CURRENT_TIMESTAMP, status, ID, remarks, id]
        const sql = SqlString.format("update entrust set update_time=?, status = ?, last_update_by = ?, remarks = ? where id = ?", params)
        return ExecSql(sql)
    }
}