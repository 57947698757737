import {Button, Form, Input, message, Modal, Radio, Space} from "antd";
import {CustomerLevelList, CustomerLevelMap, CustomerService, ICustomerVO} from "../../../service/CustomerService";
import {labelCol, noLabelWrapperCol, wrapperCol} from "../../common";
import React, {useEffect, useRef, useState} from "react";
import {CustomerChangeAuditService, EnumCustomerChangeType} from "../../../service/CustomerChangeAuditService";
import {CustomerChangeService, ICustomerChangeOptEnum} from "../../../service/CustomerChangeLogService";

interface IProps {
    curr?: ICustomerVO
    visible: boolean
    onSuccess: () => void;
    onClose:() => void;
}

export function SetCustomerLevelModal(props: IProps) {
    const { curr, visible, onClose, onSuccess } = props;
    const [form] = Form.useForm()
    const [isSubmitting, setSubmitting] = useState(false)
    const instanceRef = useRef({ isSubmitting: false })

    useEffect(() => {
        if (visible && curr) {
            form.setFieldsValue({
                level: curr.level,
                reason: ''
            })
        }
    }, [visible, curr, form])

    function onFormFinished(values: any) {
        console.log(values);

        console.log(curr?.level)

        let oldLevel: number = curr?.level!
        let newLevel: number = values.level
        let customer_id: number = curr?.id!
        const reason = values.reason


        if (instanceRef.current.isSubmitting) {
            return;
        }

        instanceRef.current.isSubmitting = true;
        setSubmitting(true);

        async function reqFun() {
            const payload = {
                old_level: oldLevel,
                new_level: newLevel
            }

            if (newLevel > oldLevel) {
                // 需要审核
                await CustomerChangeAuditService.create({
                    customer_id,
                    type: EnumCustomerChangeType.level,
                    reason,
                    payload: JSON.stringify(payload)
                })

                await CustomerService.setLevelIsChangeAudit({ customer_id })
                message.info('您的操作是降低客户等级，管理员审核通过后变更才会生效。')

                return
            }

            if (newLevel < oldLevel) {
                await CustomerChangeService.create({
                    customer_id,
                    opt_type: ICustomerChangeOptEnum.changeLevel,
                    content: `设置客户等级为 ${CustomerLevelMap.get(oldLevel)} -> ${CustomerLevelMap.get(newLevel)}， 变更原因：${reason}`
                })

                await CustomerService.setLevel({customer_id, level: newLevel})
            }

            // 等级没有变更不做任何的操作
            if (newLevel === oldLevel) {

            }
        }

        reqFun()
            .then(() => {
                onSuccess();
            })
            .catch(() => {
                message.error('保存失败了').then(() => {})
            })
            .finally(() => {
               setSubmitting(false)
               instanceRef.current.isSubmitting = false;
            });
    }

    function doRest() {
        form.resetFields()
        if (curr) {
            form.setFieldsValue(curr)
        } else {
            form.setFieldsValue(undefined)
        }
    }

    return (
        <Modal visible={visible} title={'设置客户等级'} footer={false} onCancel={onClose}>
            <Form form={form} wrapperCol={wrapperCol} labelCol={labelCol} onFinish={onFormFinished} onReset={doRest}>
                <Form.Item name={'level'} label={'等级'} rules={[{required: true, message: '请设置等级'}]} extra={
                    <div>
                        <div>客户等级分为A、B和C三个等级.</div>
                        <div>等级设定后，在下次编辑的用户等级的时候有如下规则：</div>
                        <div>1、A降级为B或者C需要管理员审核</div>
                        <div>2、B降级C需要管理员审核</div>
                        <div>3、B可以升级A</div>
                        <div>3、C可以升级B或者A</div>
                    </div>
                }>
                    <Radio.Group>
                        {
                            CustomerLevelList.map((item) => {
                                return <Radio value={item[0]} key={item[0]}>{item[1]}</Radio>
                            })
                        }
                    </Radio.Group>
                </Form.Item>

                <Form.Item name={'reason'} label={'变更原因'} rules={[{required: true, message: '请填写变更原因'}, {max: 500, message: '最大长度不超过500个字'}]}>
                    <Input.TextArea placeholder={'请输入变更原因'} />
                </Form.Item>

                <Form.Item wrapperCol={noLabelWrapperCol}>
                    <Space size={15}>
                        <Button htmlType={'submit'} loading={isSubmitting} type={'primary'}>保存</Button>
                        <Button htmlType={'reset'} loading={isSubmitting}>重置</Button>
                    </Space>
                </Form.Item>

            </Form>
        </Modal>
    )
}