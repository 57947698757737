import {IPage, IWithKey} from "./interface";
import doSqlPageQuery from "./SqlHelper";
import SqlString from "sqlstring";
import {ExecSql} from "./r";
import {AuthService} from "./AuthService";

export interface IRoomChangeLogQuery extends IPage {
    room_id: string
    opt_type: number
}

export enum IRoomChangeOptEnum {
    offline = 1, // 下架
    follow = 2, // 跟进信息
}

export const IRoomChangeOptEnumList = [
    [IRoomChangeOptEnum.offline, '下架房源'],
    [IRoomChangeOptEnum.follow, '跟进信息']
]

export const IRoomChangeOptEnumMap = new Map(IRoomChangeOptEnumList as any)

export interface IRoomChangeLog extends IWithKey{
    id: number
    room_id: string
    room_id_str: string
    content: string
    create_time: string
    create_time_str: string
    create_by: number
    create_by_name: string
    opt_type: IRoomChangeOptEnum
}

export const RoomChangeLogService = {
    /**
     * @param query
     */
    list(query: Partial<IRoomChangeLogQuery>) {
        const where = []
        let whereStr = ''
        let sql = `select id, opt_type, bin_to_uuid(room_id) as room_id_str, content, create_by, create_by_name, DATE_FORMAT(create_time, '%Y-%m-%d %H:%i:%S') as create_time_str from room_change_log `
        if (query.room_id) {
            where.push(`room_id = UUID_TO_BIN('${query.room_id}')`)
        }
        if (query.opt_type) {
            where.push(`opt_type = ${query.opt_type}`)
        }
        if (where.length > 0) {
            whereStr = ' where ' + where.join(' and ') + ' '
        }
        sql += whereStr
        sql += " order by create_time desc "
        let countSql = "select count(1) as count from room_change_log" + whereStr
        return doSqlPageQuery<IRoomChangeLog>(sql, countSql, query)
    },
    async create({room_id, content, opt_type}: {room_id: string, content: string, opt_type: IRoomChangeOptEnum }) {
        const { ID, Name } = await AuthService.info()
        const sql = SqlString.format("insert into room_change_log(`room_id`, `content`, `create_by`, `create_by_name`, `opt_type`) values(uuid_to_bin(?), ?, ?, ?, ?)", [
            room_id, content, ID, Name, opt_type
        ])
        return ExecSql(sql)
    },

    async offline({room_id, content}: {room_id: string, content: string}) {
        return this.create({room_id, content, opt_type: IRoomChangeOptEnum.offline})
    }
}