import {IRoomVO} from "../../../service/RoomService";
import {Button, Card, Descriptions, Drawer, message, Space} from "antd";
import {Helper} from "../../../utils/Helper";
import RoomChangeLog from "./RoomChangeLog";
import React, {useReducer, useState} from "react";
import {IRoomChangeOptEnum} from "../../../service/RoomChangeLogService";
import ChangeLogModal from "./ChangeLogModal";
import Images from "./Images";
import PhoneList from "./PhoneList";

interface IProps {
    visible: boolean
    room?: IRoomVO
    onClose: () => void
}

export default function RoomDetail(props: IProps) {
    const { visible, room, onClose } = props

    const [showEditChangeLog, setShowEditChangeLog] = useState(false)

    function doWriteFollowMsg() {
        setShowEditChangeLog(true)
    }

    const [freshFlag, forceUpdate] = useReducer(x => x + 1, 0)

    return (
        <>
            <Drawer
                visible={visible}
                width={'calc(100vw - 180px)'}
                placement={'right'}
                title={room?.title}
                onClose={onClose}
                bodyStyle={{paddingTop: 10}}
                extra={
                    <div>
                        <Button type={'primary'} onClick={doWriteFollowMsg}>填写跟进信息</Button>
                    </div>
                }
            >
                <Space direction={'vertical'} size={20}>
                    <Card title={'房源信息'}>
                        <Descriptions bordered layout={'horizontal'} labelStyle={{width: '120px'}} size={'small'} column={3}>
                            <Descriptions.Item label={'标题'} span={3}>{room?.title}</Descriptions.Item>
                            <Descriptions.Item label={'房间号'}>{room?.room_no}</Descriptions.Item>
                            <Descriptions.Item label={'面积'}>{room?.area} 平方米</Descriptions.Item>
                            <Descriptions.Item label={'日租金'}>{room?.day_rent} 元/平方/天</Descriptions.Item>
                            <Descriptions.Item label={'月租金'}>{room?.month_rent} 元/月</Descriptions.Item>
                            <Descriptions.Item label={'租金包含'}>{room?.rent_contain}</Descriptions.Item>
                            <Descriptions.Item label={'付款方式'}>{room?.pay_type}</Descriptions.Item>
                            <Descriptions.Item label={'是否可分割'}>
                                {room?.divisible === 1 ? room?.divide_desc : '不可分割'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'是否可注册'}>
                                {room?.can_register === 1 ? '可注册' : null}
                            </Descriptions.Item>
                            <Descriptions.Item label={'产权方'}>{room?.property_party}</Descriptions.Item>
                            <Descriptions.Item label={'联系人'}>
                                <PhoneList phoneList={room?.phone_list}/>
                            </Descriptions.Item>
                            <Descriptions.Item label={'创建人'}>{room?.create_by_name}</Descriptions.Item>
                            <Descriptions.Item label={'创建时间'}>
                                {Helper.formatDate(room?.create_time!)}
                            </Descriptions.Item>
                            <Descriptions.Item label={'最近更新人'}>{room?.latest_update_name}</Descriptions.Item>
                            <Descriptions.Item label={'最近更新时间'}>
                                {Helper.formatDate(room?.latest_update_time!)}
                            </Descriptions.Item>
                            <Descriptions.Item label={'所属人'}>{room?.owner_name}</Descriptions.Item>

                            <Descriptions.Item label={'房间状态'} span={3}>{room?.room_status}</Descriptions.Item>
                            <Descriptions.Item label={'详情'} span={3}>
                                <div>
                                    {room?.detail.split("\n").map((item) => {
                                        return <div>{item}</div>
                                    })}
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label={'封面图'} span={3}>
                                <Images urls={room?.cover_image}/>
                            </Descriptions.Item>
                            <Descriptions.Item label={'图片'} span={3}>
                                <Images urls={room?.images}/>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>

                    <Card title={'房源跟进信息'}>
                        <RoomChangeLog roomId={room?.id} freshFlag={freshFlag} />
                    </Card>
                </Space>
            </Drawer>

            <ChangeLogModal
                optType={IRoomChangeOptEnum.follow}
                visible={showEditChangeLog}
                room={room}
                onCancel={() => {
                    setShowEditChangeLog(false)
                }}
                onSuccess={() => {
                    setShowEditChangeLog(false)
                    message.success('跟进信息填写成功').then(() => ({}))
                    forceUpdate()
                }}
            />
        </>
    )
}