import style from './UserCenter.module.css'
import {Menu} from "antd";
import {HomeOutlined, KeyOutlined, UserOutlined} from "@ant-design/icons";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import UserInfo from "./pages/UserInfo";
import RestPassword from "./pages/RestPassword";
import {useEffect, useState} from "react";
export default function UserCenter() {
    const [activeKey, setActiveKey] = useState<string>()
    let { url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation()
    useEffect(() => {
        console.log(location)
        setActiveKey(location.pathname)
    }, [location])

    return <div className={style.UserCenterContainer}>
        <h1># 用户中心</h1>
        <div className={style.UserCenterContent}>
            <div className={style.UserCenterSideMenu}>
                <Menu style={{ width: 180 }} activeKey={activeKey}>
                    <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => history.push("/")}>
                        返回首页
                    </Menu.Item>
                    <Menu.Item key={`${url}/user_info`} icon={<UserOutlined />} onClick={() => history.push(`${url}/user_info`)}>
                        个人信息
                    </Menu.Item>
                    <Menu.Item key={`${url}/reset_password`} icon={<KeyOutlined />} onClick={() => history.push(`${url}/reset_password`)}>
                        修改密码
                    </Menu.Item>
                </Menu>
            </div>
            <div className={style.UserCenterMain}>
                <Switch>
                    <Route path={`${url}/`} exact render={() => {
                        return <Redirect to={{pathname: `${url}/user_info`}}/>
                    }} />
                    <Route path={`${url}/user_info`} component={UserInfo}/>
                    <Route path={`${url}/reset_password`} component={RestPassword}/>
                </Switch>
            </div>
        </div>
    </div>
}