import {useCallback, useMemo, useState} from "react";
import {
    EnumCustomerChangeAuditStatusList, EnumCustomerChangeTypeList,
    ICustomerChangeAuditQuery
} from "../../../service/CustomerChangeAuditService";
import {Button, Form, Input, Select, Space} from "antd";
import QueryFormItem from "../../../components/QueryFormItem/QueryFormItem";

export default function useChangeQuery() {
    const [query, setQuery] = useState<Partial<ICustomerChangeAuditQuery>>({})

    const reload = useCallback(() => {
        setQuery((q) => ({...q}))
    }, [])

    const queryForm = useMemo(() => {
        function queryFormFinished(values: any) {
            setQuery((q) => ({
                ...q,
                ...values,
                page_no: 1,
            }));
        }

        function onFormRest() {
            setQuery((q) => ({
                page_size: q.page_size,
                page_no: 1,
            }));
        }
        return <Form layout={'inline'} onFinish={queryFormFinished} onReset={onFormRest} labelCol={{flex: '80px'}}>
            <QueryFormItem>
                <Form.Item name={'applicant_name'} label={'申请人'}>
                    <Input placeholder={'请输入申请人名称'} allowClear />
                </Form.Item>
            </QueryFormItem>
            <QueryFormItem>
                <Form.Item name={'status'} label={'等级'}>
                    <Select placeholder={'请选择'} allowClear>
                        {
                            EnumCustomerChangeAuditStatusList.map((item) => {
                                return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </QueryFormItem>

            <QueryFormItem>
                <Form.Item name={'type'} label={'变更类型'}>
                    <Select placeholder={'请选择'} allowClear>
                        {
                            EnumCustomerChangeTypeList.map((item) => {
                                return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </QueryFormItem>
            <QueryFormItem fixedRight width={'auto'}>
                <Form.Item>
                    <Space size={15}>
                        <Button htmlType={'submit'} type={'primary'}>
                            查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                    </Space>
                </Form.Item>
            </QueryFormItem>
        </Form>
    }, [])
    return {
        query, setQuery, queryForm, reload
    }

}