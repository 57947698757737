import React, {useContext} from "react";
import {GlobalContext} from "../../global/GlobalContext";
import {IButtonAccess} from "../../layout/permission";

interface IHasPermissionProps {
    code: string | keyof IButtonAccess<boolean>;
    inApi?: boolean
    inBtn?: boolean
    fallback?: React.ReactNode;
}

const HasPermission: React.FC<IHasPermissionProps> = (props) => {
    const {
        code,
        inApi,
        inBtn,
        fallback,
        children
    } = props

    const {isLoadingPermission, apiPermission, btnPermission} = useContext(GlobalContext)

    // 如果权限数据正在加载中，直接返回 null
    if (isLoadingPermission) {
        return null
    }

    if (inApi) {
        if (apiPermission[code]) {
            return <>{children}</>
        } else {
            return <>{fallback}</>
        }
    }

    if (inBtn) {
        if (btnPermission[code as keyof IButtonAccess<boolean>]) {
            return <>{children}</>
        } else {
            return <>{fallback}</>
        }
    }

    if (apiPermission[code] || btnPermission[code as keyof IButtonAccess<boolean>]) {
        return <>{children}</>
    } else {
        return <>{fallback}</>
    }
}

export default HasPermission;

