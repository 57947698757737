import {Post} from "./r";

export interface IArea {
    CityId: number
    ID: number
    Name: string
    Sort: number
    Enable: number
}

export function AreaList(city_id: number) {
    return Post<IArea[]>("/area/list", {city_id})
}