import React from 'react';
import style from './QueryFormItem.module.css';

export interface IQueryFormProps {
  width?: string | number; // auto 也是可以的哦
  span?: 1 | 2;
  fixedRight?: boolean;
}

const QueryFormItem: React.FC<IQueryFormProps> = ({ children, fixedRight, span, width }) => {
  let itemStyle: React.CSSProperties | undefined;
  if (width) {
    itemStyle = {
      width,
    };
  }

  if (fixedRight) {
    if (!itemStyle) {
      itemStyle = {};
    }
    itemStyle.marginLeft = 'auto';
  }

  return (
    <div style={itemStyle} className={span === 2 ? style.QueryFormItem2 : style.QueryFormItem}>
      {children}
    </div>
  );
};

export default QueryFormItem;
