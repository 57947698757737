import {Button, Form, Input, message, Modal, Space} from "antd";
import {CustomerService, ICustomerVO} from "../../../service/CustomerService";
import {labelCol, noLabelWrapperCol, wrapperCol} from "../../common";
import React, {useEffect, useRef, useState} from "react";
import {CustomerChangeAuditService, EnumCustomerChangeType} from "../../../service/CustomerChangeAuditService";

interface IProps {
    curr?: ICustomerVO
    visible: boolean
    onSuccess: () => void;
    onClose:() => void;
}

export function SetCustomerTypeModal(props: IProps) {
    const { curr, visible, onClose, onSuccess } = props;
    const [form] = Form.useForm()
    const [isSubmitting, setSubmitting] = useState(false)
    const instanceRef = useRef({ isSubmitting: false })

    useEffect(() => {
        if (visible && curr) {
            form.setFieldsValue({
                level: curr.level,
                reason: ''
            })
        }
    }, [visible, curr, form])

    function onFormFinished(values: any) {
        // console.log(values);

        // console.log(curr?.level)

        // let oldLevel: number = curr?.level!
        // let newLevel: number = values.level
        let customer_id: number = curr?.id!
        const reason = values.reason


        if (instanceRef.current.isSubmitting) {
            return;
        }

        instanceRef.current.isSubmitting = true;
        setSubmitting(true);

        async function reqFun() {
            let res = await CustomerChangeAuditService.create({
                customer_id,
                type: EnumCustomerChangeType.type,
                reason,
                payload: "{}"
            })
            if (res.Error !== null) {
                throw new Error('创建申请单失败了，请联系管理员')
            }

            let res2 = await CustomerService.setTypeIsChangeAudit({ customer_id })
            if (res2.Error !== null ) {
                throw new Error('更改状态失败了，请联系管理员')
            }
            message.info('申请提交成功，管理员审核通过后再生效')
        }

        reqFun()
            .then(() => {
                onSuccess();
            })
            .catch(() => {
                message.error('保存失败了').then(() => {})
            })
            .finally(() => {
               setSubmitting(false)
               instanceRef.current.isSubmitting = false;
            });
    }

    function doRest() {
        form.resetFields()
        if (curr) {
            form.setFieldsValue(curr)
        } else {
            form.setFieldsValue(undefined)
        }
    }

    return (
        <Modal visible={visible} title={'设置为公客'} footer={false} onCancel={onClose}>
            <Form form={form} wrapperCol={wrapperCol} labelCol={labelCol} onFinish={onFormFinished} onReset={doRest}>
                <Form.Item name={'reason'} label={'变更原因'} rules={[{required: true, message: '请填写变更原因'}, {max: 500, message: '最大长度不超过500个字'}]}>
                    <Input.TextArea placeholder={'请输入变更原因'} />
                </Form.Item>

                <Form.Item wrapperCol={noLabelWrapperCol}>
                    <Space size={15}>
                        <Button htmlType={'submit'} loading={isSubmitting} type={'primary'}>保存</Button>
                        <Button htmlType={'reset'} loading={isSubmitting}>重置</Button>
                    </Space>
                </Form.Item>

            </Form>
        </Modal>
    )
}