import {PageContainer} from "../../layout/PageContainer";
import {Tabs} from "antd";
import BannerSettings from "./BannnerSettings";
import BusinessConfigSettings from "./BusinessConfigSettings";
import RoleSetting from "./RoleSetting";
import useAccess from "../../components/hooks/useAccess";
import NoPermission from "../../components/NoPermission/NoPermission";
import HasPermission from "../../components/HasPermission/HasPermission";

const { TabPane } = Tabs;
export default function Settings() {
    const btnPermission = useAccess()
    // console.log(btnPermission)
    return <PageContainer title={'系统配置'}>
        <Tabs defaultActiveKey="3" tabBarStyle={{backgroundColor: "#fff", paddingLeft: 10, paddingRight: 10}}>
            <TabPane tab="首页Banner配置" key="1" disabled={!btnPermission.setting_banner}>
                <HasPermission code={'setting_banner'} fallback={<NoPermission/>}>
                    <BannerSettings />
                </HasPermission>
            </TabPane>
            <TabPane tab="业务参数配置" key="2" disabled={!btnPermission.setting_config}>
                <HasPermission code={'setting_config'} fallback={<NoPermission/>}>
                    <BusinessConfigSettings />
                </HasPermission>
            </TabPane>
            <TabPane tab="角色权限" key="3" disabled={!btnPermission.setting_role}>
                <HasPermission code={'setting_role'} fallback={<NoPermission/>}>
                    <RoleSetting />
                </HasPermission>
            </TabPane>
        </Tabs>
    </PageContainer>
}