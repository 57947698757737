import {Modal, Form, Input, Select, Button, message, InputNumber} from "antd";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { BuildingService, IBuildingVO } from "../../service/BuildingService";
import { formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol } from "../common";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import CityAreaDistrict from "../../components/CityAreaDistrict/CityAreaDistrict";
import BaiduLocationSelector from "../../components/BaiduLocationSelector";

export interface IBuildingEditModalProps {
    onClose?: () => void
    onSuccess?: () => void
    visible?: boolean
    curr?: IBuildingVO
}

const BuildingEdit: React.FC<IBuildingEditModalProps> = ({visible, curr, onClose, onSuccess}) => {
    const instanceRef = useRef<{ isSubmitting: boolean }>({isSubmitting: false})
    const [form] = Form.useForm()

    useEffect(() => {
        if (visible) {
            form.resetFields()
        }
    }, [visible, form])

    function onFormFinished(valus: any) {
        if (instanceRef.current.isSubmitting) {
            return
        }

        // console.log(valus)
        let params = {...valus}
        params.images = JSON.stringify(params.images)

        if (!params.district_area || params.district_area.length !== 6) {
            message.error('请选择完整的区域信息')
            // instanceRef.current.isSubmitting = false
            return;
        }

        if (!params.latlng) {
            message.error('请在地图上选择经纬度')
            return;
        }

        if (params.latlng) {
            let isNotOk = (Number.isNaN(Number(params.latlng.lat)) || Number.isNaN(Number(params.latlng.lng)))
            if (isNotOk) {
                message.error('请输入正确格式的经纬度')
                return;
            }
        }

        instanceRef.current.isSubmitting = true

        params.city_id = params.district_area[0]
        params.area_id = params.district_area[1]
        params.district_id = params.district_area[2]
        params.city_name = params.district_area[3]
        params.area_name = params.district_area[4]
        params.district_name = params.district_area[5]
        params.latitude =  params.latlng.lat + ''
        params.longitude =  params.latlng.lng + ''

        let api = BuildingService.create
        if (curr) {
            params.id = curr.id
            api = BuildingService.update
        }

        api(params).then((res) => {
            if (res.Error === null) {
                if (onSuccess) {
                    message.success('操作成功')
                    onSuccess()
                }
            } else {
                message.error('操作失败')
            }
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }

    return (
        <Form wrapperCol={wrapperCol} labelCol={labelCol} initialValues={curr} form={form} onFinish={onFormFinished}>
            <Form.Item label="大厦&项目名称" name="name">
                <Input />
            </Form.Item>
            <Form.Item label="总面积" name="total_area">
                <Input />
            </Form.Item>
            <Form.Item label="产权年限" name="property_year">
                <Input />
            </Form.Item>
            <Form.Item label="物业" name="property_company">
                <Input />
            </Form.Item>
            <Form.Item label="开发商" name="developer">
                <Input />
            </Form.Item>
            <Form.Item label="所在区域商圈" name="district_area" required>
                <CityAreaDistrict />
            </Form.Item>
            <Form.Item label="地址" name="address">
                <Input />
            </Form.Item>

            <Form.Item label="经纬度" required name="latlng" rules={[{required: true, message: '请选择经纬度'}]}>
                <BaiduLocationSelector />
            </Form.Item>

            <Form.Item label="公交" name="bus">
                <Input />
            </Form.Item>
            <Form.Item label="地铁" name="subway">
                <Input />
            </Form.Item>
            <Form.Item label="周边配套" name="around">
                <Input />
            </Form.Item>
            <Form.Item label="停车位" name="park">
                <Input />
            </Form.Item>
            <Form.Item label="空调开放时间" name="air_type">
                <Select>
                    <Select.Option value={1}>24小时制</Select.Option>
                    <Select.Option value={2}>非24小时制</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="空调收费信息" name="air_desc">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="层高" name="floor_height">
                <InputNumber />
            </Form.Item>
            <Form.Item label="图片" name="images" extra={"可以上传多张图片, 每张图片的大小不超过4M"}>
                <ImageUploader multiple limitSize={1024 * 1024 * 4} />
            </Form.Item>
            <Form.Item wrapperCol={noLabelWrapperCol}>
                <Button htmlType='submit' type='primary'>保存</Button>
                <Button htmlType='reset' style={formButtonStyle}>重置</Button>
            </Form.Item>
        </Form>
    )
}

export default function BuildingEditModal({visible, curr, onClose, onSuccess}: IBuildingEditModalProps) {
    function doClose() {
        if (onClose) {
            onClose()
        }
    }

    function doSuccess() {
        if (onSuccess) {
            onSuccess()
        }
    }
    return (
    <Modal 
        visible={visible} 
        title={curr ? '编辑' : '新增'} 
        width={820} 
        keyboard={false} 
        maskClosable={false} 
        onCancel={doClose} 
        onOk={doSuccess} 
        footer={false}
        forceRender
        destroyOnClose={false}
    >
        <BuildingEdit visible={visible} curr={curr} onClose={onClose} onSuccess={onSuccess} />
    </Modal>
    )
}