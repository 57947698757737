import {IExecResult, IPage, IWithKey} from "./interface";
import {doPageQuery, Post} from "./r";

export interface INewsVO extends IWithKey {
    id: number
    title: string
    type: string
    content: string
    status: number
    publish_time: string
    read_counts: number
}

export interface INewsParams extends IPage {

}

export const NewsService = {
    list(params: Partial<INewsParams>) {
        return doPageQuery<INewsVO>("/news/list", params)
    },

    delete(id: number) {
        return Post<IExecResult>('/news/delete', {id})
    },

    create(vo: INewsVO) {
        return Post<IExecResult>('/news/create', vo)
    },

    update(vo: INewsVO) {
        return Post<IExecResult>("/news/update", vo)
    },

    offline(id: number) {
        return Post<IExecResult>("/news/offline", {id})
    },

    online(id: number) {
        return Post<IExecResult>("/news/offline", {id})
    },

    publish(id: number) {
        return Post<IExecResult>("/news/publish", {id})
    },

    getById(id: number) {
        return Post<INewsVO>("/news/get_by_id", {id})
    }
}