import {useEffect, useState} from "react";
import {RoomPhoneTypeMap} from "../../../service/RoomService";

interface IProps {
    phoneList?: string
}
export default function PhoneList({phoneList}: IProps) {
    const [list, setList] = useState<{phone: string, phone_type: number, phone_name: string}[]>()

    useEffect(() => {
        if (!phoneList) {
            setList([])
        } else {
            try {
                setList(JSON.parse(phoneList))
            } catch (e) {
                console.log('解析JSON对象失败了')
            }
        }
    }, [phoneList])

    if (!phoneList) {
        return null
    }

    return <div>
        {
            list?.map((item) => {
                return <div>{item.phone_name} {item.phone} ({RoomPhoneTypeMap.get(item.phone_type)})</div>
            })
        }
    </div>

}