import {ExecQuery, ExecSql} from "./r";
import SqlString  from "sqlstring"

export interface IBannerVO {
    id: number
    img_url: string | string[]
    desc: string
    status: number
    sort: number
}

export const BannerSettingsService = {
    list() {
        let sql = "select * from banner order by sort";
        return ExecQuery<IBannerVO[]>(sql)
    },

    deleteItem(id: number) {
        let sql = SqlString.format("delete from banner where id = ?", [id])
        return ExecSql(sql)
    },

    updateItem(item: IBannerVO) {
        console.log('upate sql', item)
        let sql = SqlString.format("update banner set `img_url` = ?, `desc` = ?, `status` = ?, `sort` = ? where `id` = ?", [item.img_url, item.desc, item.status, item.sort, item.id])
        return ExecSql(sql)
    },

    insertItem(item: IBannerVO) {
        console.log('insert', item)
        let sql = SqlString.format("insert into banner(`img_url`, `desc`, `status`, `sort`) values(?, ?, ?, ?)", [item.img_url, item.desc, item.status, item.sort])
        return ExecSql(sql)
    }
}