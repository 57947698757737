import {Button, Divider, Form, Input, message, Modal, Radio, Space} from "antd";
import {CustomerLevelList, CustomerService, CustomerTypeList, ICustomerVO} from "../../../service/CustomerService";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useRef, useState} from "react";
import {labelCol, noLabelWrapperCol, wrapperCol} from "../../common";
import CityAreaDistrict from "../../../components/CityAreaDistrict/CityAreaDistrict";
import InputBetween from "../../../components/InputBetween/InputBetween";
import useAccess from "../../../components/hooks/useAccess";

interface IProps {
    visible: boolean
    curr?: ICustomerVO
    onSuccess:() => void
    onClose:() => void
}

const emptyObj = {
    phone_list: [],
    level: undefined,
    type: undefined,
    source: undefined,
    remark: undefined,
    demands: {
        district_area: undefined,
        price: undefined,
        area: undefined
    }
}

export default function CustomerEditModal(props: IProps) {
    const { visible, curr, onSuccess, onClose } = props
    const [form] = Form.useForm()

    const [isSubmitting, setSubmitting] = useState(false)
    const instanceRef = useRef({ isSubmitting: false })

    const access = useAccess();

    function onFormFinished(values: any) {
        // console.log(values)
        if (instanceRef.current.isSubmitting) {
            return;
        }
        instanceRef.current.isSubmitting = true;
        setSubmitting(true)
        let api = CustomerService.add
        if (curr) {
            api = CustomerService.update
            values.id = curr.id
        }

        api(values).then((data) => {
            if (data.Error == null) {
                message.success('保存成功').then(() => {});
                onSuccess();
            } else {
                message.error('更新失败，请联系管理员处理.').then(() => {});
            }

        }).finally(() => {
            setSubmitting(false)
            instanceRef.current.isSubmitting = false
        }).catch(() => {
            message.error('保存失败了').then(() => {})
        })
    }

    useEffect(() => {
        if (visible) {
            form.resetFields()
            if (curr) {
                // form.setFieldsValue({
                //     phone_list: curr.phone_list,
                //     level: curr.level,
                //     type: curr.type,
                //     source: curr.source,
                //     remark: curr.remark,
                //     demands: curr.demands
                // })
                console.log(curr)
                form.setFieldsValue(curr)
            } else {
                form.setFieldsValue(emptyObj)
            }
        }
    }, [visible, curr, form])

    function doRest() {
        form.resetFields()
        if (curr) {
            form.setFieldsValue(curr)
        } else {
            form.setFieldsValue(emptyObj)
        }
    }

    // 是否可以编辑等级和类型信息
    const canEditLevelAndType = access.customer_edit_without_audit || (!access.customer_edit_without_audit && curr === undefined)

    // 是否可以编辑电话信息
    const canEditPhone = access.customer_edit_phone || (!access.customer_edit_phone && curr === undefined)

    return <Modal visible={visible} title={curr ? '编辑客源信息': '新增客源信息'} width={720} footer={false} onCancel={onClose}>
        <Form form={form} wrapperCol={wrapperCol} labelCol={labelCol} onFinish={onFormFinished} onReset={doRest}>
            <Divider orientation={'left'}>基本信息</Divider>
            <Form.Item label={'联系方式'} style={{ marginBottom: 0 }}>
                <Form.List name={'phone_list'}>
                    {
                        (fields, {add, remove}) => {
                            return <>
                                {
                                    fields.map(({key, name}) => {
                                        return <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item name={[name, 'name']}>
                                                <Input placeholder={'客户称呼'}/>
                                            </Form.Item>
                                            <Form.Item name={[name, "phone"]}>
                                                <Input placeholder={'手机号码'} disabled={!canEditPhone}/>
                                            </Form.Item>
                                            <Form.Item name={[name, "post"]}>
                                                <Input placeholder={'职位'} />
                                            </Form.Item>
                                            {
                                                canEditPhone ? <MinusCircleOutlined onClick={() => remove(name)} />: null
                                            }
                                        </Space>
                                    })
                                }
                                <Form.Item extra={'最多添加3个联系方式'}>
                                    <Button type="dashed" disabled={fields.length >= 3 || (fields.length < 3 && !canEditPhone)} onClick={() => add()} icon={<PlusOutlined />}>
                                        添加联系方式
                                    </Button>
                                </Form.Item>
                            </>
                        }
                    }
                </Form.List>
            </Form.Item>

            <Form.Item name={'level'} label={'等级'} rules={[{required: true, message: '请设置等级'}]} extra={
                <div>
                    <div>客户等级分为A、B和C三个等级.</div>
                    <div>等级设定后，在下次编辑的用户等级的时候有如下规则：</div>
                    <div>1、A降级为B或者C需要管理员审核</div>
                    <div>2、B降级C需要管理员审核</div>
                    <div>3、B可以升级A</div>
                    <div>3、C可以升级B或者A</div>
                </div>
            }>
                <Radio.Group disabled={!canEditLevelAndType}>
                    {
                        CustomerLevelList.map((item) => {
                            return <Radio value={item[0]} key={item[0]}>{item[1]}</Radio>
                        })
                    }
                </Radio.Group>
            </Form.Item>

            <Form.Item name={'type'} label={'客别'} rules={[{required: true, message: '请选择客别'}]}>
                <Radio.Group disabled={!canEditLevelAndType}>
                    {
                        CustomerTypeList.map((item) => {
                            return <Radio value={item[0]} key={item[0]}>{item[1]}</Radio>
                        })
                    }
                </Radio.Group>
            </Form.Item>

            <Form.Item name={'source'} label={'来源'} extra={'客户来源，长度不超过250个字'} rules={[{max: 250, message: '最大长度不超过250个字'}]}>
                <Input.TextArea placeholder={'请输入来源'} />
            </Form.Item>

            <Form.Item name={'remark'} label={'备注'} extra={'备注信息，长度不超过250个字'} rules={[{max: 250, message: '最大长度不超过250个字'}]}>
                <Input.TextArea placeholder={'请输入备注信息'} />
            </Form.Item>

            <Divider orientation={'left'}>客户需求</Divider>

            <Form.Item label='房源意向区域' name={['demands','district_area']}>
                <CityAreaDistrict flex={"1 0"}/>
            </Form.Item>

            <Form.Item label='价格' name={['demands','price']}>
                <InputBetween/>
            </Form.Item>

            <Form.Item label='面积' name={['demands','area']}>
                <InputBetween/>
            </Form.Item>

            <Form.Item wrapperCol={noLabelWrapperCol}>
                <Space size={15}>
                    <Button htmlType={'submit'} loading={isSubmitting} type={'primary'}>保存</Button>
                    <Button htmlType={'reset'} loading={isSubmitting}>重置</Button>
                </Space>
            </Form.Item>
        </Form>
    </Modal>
}