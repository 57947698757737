import {Button, Col, Form, Input, message, Modal, Row} from "antd";
import React, {useEffect, useRef} from "react";
import {INewsVO, NewsService} from "../../service/NewsService";
import Editor from "wangeditor";

interface INewsEditProps {
    visible: boolean
    curr?: INewsVO
    onSuccess?: () => void
    onCancel?: () => void
}

const gapStyle: React.CSSProperties = {
    marginBottom: 15
}

const NewsEdit: React.FC<INewsEditProps> = ({visible, curr, onCancel, onSuccess}) => {
    const instanceRef = useRef<{isSubmitting: boolean}>({isSubmitting: false})
    const [form] = Form.useForm()
    const ref = useRef<HTMLDivElement>(null)
    const editorRef = useRef<Editor>()

    useEffect(() => {
        if (visible) {
            form.resetFields()
            let content = ""
            if (curr) {
                content = curr.content
            }
            if (editorRef.current) {
                editorRef.current?.txt.html(content)
            }
        }
    }, [curr, visible, form])

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const editor = new Editor(ref.current)
        editorRef.current = editor
        // editor.config.onchange = (newHtml: string) => {
        //     setContent(newHtml)
        // }

        editor.config.uploadImgServer = "/api/uploader/editor_upload"
        editor.config.uploadFileName = "file"
        editor.config.excludeMenus= ['emoticon', 'code', 'splitLine', 'quote', 'todo']

        editor.create()
        return () => {
            editor.destroy()
        }
    }, [])

    function doFormFinished(values: any) {
        // console.log(values)
        if (instanceRef.current.isSubmitting) {
            return
        }
        instanceRef.current.isSubmitting = true

        let params: any = {}
        params.title = values.title
        let htmlStr = editorRef.current?.txt.html()
        params.content = htmlStr || ''
        params.status = values.status
        let api = NewsService.create
        if (curr) {
            params.id = curr.id
            api = NewsService.update
        }

        api(params).then((res) => {
            if (res.RowsAffected > 0) {
                message.success('操作成功')
                if (onSuccess) {
                    onSuccess()
                }
            } else {
                message.error('操作失败')
            }

        }).finally(() => {
            setTimeout(() => {
                instanceRef.current.isSubmitting = false
            }, 100)
        })
    }

    function doClickSaveDraft(e: React.MouseEvent) {
        e.preventDefault()
        form.validateFields().then((values) => {
            console.log(values)
            values.status = 1
            doFormFinished(values)
        }).catch(() => {

        })
    }

    function doClickSavePublish(e: React.MouseEvent) {
        e.preventDefault()
        form.validateFields().then((values) => {
            console.log(values)
            values.status = 2
            doFormFinished(values)
        }).catch(() => {

        })
    }



    return <div>
        <Form form={form} name={"new_form_edit"} initialValues={curr} onFinish={doFormFinished}>
            <Form.Item name={"title"} rules={[{required: true, message: '请输入新闻标题'}, { max: 255, message: '最长不超过255个字' }]}>
                <Input placeholder={'新闻标题最长不超过255个字'}/>
            </Form.Item>

            {/*<Form.Item name={'content'} rules={[{required: true, message: '请输入新闻内容'}, { max: 60000, message: '最长不超过60000个字' }]} >
                <Input.TextArea placeholder={'在这输入新闻内容'} autoSize={{minRows: 4, maxRows: 15}} />
            </Form.Item>*/}

            <div style={gapStyle}>
                <div ref={ref}/>
            </div>

            <Row gutter={30}>
               <Col span={8}>
                   <Button block htmlType={'submit'} name={"save_type"} value={"123"} type={'primary'} onClick={doClickSaveDraft}>保存草稿</Button>
               </Col>

                <Col span={8}>
                    <Button block htmlType={'submit'} type={'primary'} name={"save_type"} value={"345"} ghost onClick={doClickSavePublish}>保存并发布</Button>
                </Col>

                <Col span={8}>
                    <Button block htmlType={'reset'}>重置</Button>
                </Col>
            </Row>
        </Form>
    </div>
}

interface INewsEditModalProps extends INewsEditProps{

}

const NewsEditModal: React.FC<INewsEditModalProps> = ({curr, visible, onSuccess, onCancel}) => {

    return <Modal title={curr ? "编辑": "新增"} forceRender width={900} footer={false} visible={visible} onCancel={onCancel} onOk={onSuccess} keyboard={false} maskClosable={false}>
        <NewsEdit visible={visible} curr={curr} onCancel={onCancel} onSuccess={onSuccess}/>
    </Modal>
}

export default NewsEditModal