import {useMemo, useState} from "react";
import {Button, Form, Input, Select, Space} from "antd";
import QueryFormItem from "../../../components/QueryFormItem/QueryFormItem";
import {CustomerLevelList, ICustomerListQuery} from "../../../service/CustomerService";

export default function useQuery() {
    const [query, setQuery] = useState<Partial<ICustomerListQuery>>({page_no: 1, page_size: 10})

    const queryForm = useMemo(() => {
        function queryFormFinished(values: any) {
            setQuery((q) => ({
                ...q,
                ...values,
                page_no: 1,
            }));
        }

        function onFormRest() {
            setQuery((q) => ({
                page_size: q.page_size,
                page_no: 1,
            }));
        }

        return <Form layout={'inline'} onFinish={queryFormFinished} onReset={onFormRest} labelCol={{flex: '80px'}}>
            <QueryFormItem>
                <Form.Item name={'name'} label={'姓名'}>
                    <Input placeholder={'请输入用户名'} allowClear />
                </Form.Item>
            </QueryFormItem>
            <QueryFormItem>
                <Form.Item name={'phone'} label={'电话'}>
                    <Input placeholder={'请输入用户电话'} allowClear />
                </Form.Item>
            </QueryFormItem>
            <QueryFormItem>
                <Form.Item name={'level'} label={'等级'}>
                    <Select placeholder={'请选择'}>
                        {
                            CustomerLevelList.map((item) => {
                                return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </QueryFormItem>
            <QueryFormItem fixedRight width={'auto'}>
                <Form.Item>
                    <Space size={15}>
                        <Button htmlType={'submit'} type={'primary'}>
                            查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                    </Space>
                </Form.Item>
            </QueryFormItem>
        </Form>
    }, [])

    return {
        query, setQuery, queryForm
    }
}