import {Button, Form, Input, message, Modal, Select, Table} from "antd";
import {useMemo, useState} from "react";
import { useEffect } from "react";
import {PageContainer} from "../../layout/PageContainer";
import { CityList, ICity } from "../../service/CityService";
import {AreaList, IArea} from "../../service/AreaService";
import {DistrictDelete, DistrictList, IDistrictListParams, IDistrictVO} from "../../service/DistrictService";
import {ColumnsType} from "antd/es/table";
import {DistrictEditModal} from "./DistrictEditModal";
import PageCard from "../../layout/PageCard";
import {Helper} from "../../utils/Helper";
import {TablePaginationConfig} from "antd/lib/table/interface";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";

export function District() {
    const [cites, setCites] = useState<ICity[]>([])
    const [areas, setAreas] = useState<IArea[]>([]);
    const [selectedCityId, setSelectedCityId] = useState<number>();
    const [selectedAreaId, setSelectedAreaId] = useState<number>();
    const [queryForm] = Form.useForm()
    const [query, setQuery] = useState<Partial<IDistrictListParams>>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState(Helper.getDefaultPage())

    useEffect(() => {
        setLoading(true)
        CityList().then((data) => {
            setCites(data)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (selectedCityId) {
            setSelectedAreaId(undefined)
            queryForm.setFieldsValue({
                Area: undefined,
            })
            AreaList(selectedCityId).then((data) => {
                setAreas(data)
            })
        }
    }, [selectedCityId, queryForm])

    const [districtList, setDistrictList] = useState<IDistrictVO[]>([])
    useEffect(() => {
        setLoading(true)
        DistrictList(query).then((data) => {
            setDistrictList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    const [showEditModal, setShowEditModal] = useState(false)
    const [curr, setCurr] = useState<IDistrictVO>()
    const columns = useMemo<ColumnsType<IDistrictVO>>(() => {
        function doEdit(item: IDistrictVO) {
            return () => {
                setShowEditModal(true)
                setCurr(item)
            }
        }
        function doDelete(item: IDistrictVO) {
            return () => {
                Modal.confirm({
                    title: '确认删除',
                    content: '确认删除',
                    onOk() {
                        DistrictDelete(item.Id).then(() => {
                            message.success('操作成功')
                            setQuery({})
                        })
                    }
                })
            }
        }
        return [
            {
                title: 'ID',
                dataIndex: 'Id'
            },
            {
                title: '商圈名称',
                dataIndex: 'Name'
            },
            {
                title: '区域名称',
                dataIndex: 'AreaName'
            },
            {
                title: '城市',
                dataIndex: 'CityName'
            },
            {
                dataIndex: 'DictIndex',
                title: '拼音首字母'
            },
            {
                dataIndex: 'Sort',
                title: '排序值'
            },
            {
                dataIndex: 'Enable',
                title: '状态',
                render(t) {
                    if (t===1) {
                        return <span style={{color: "green"}}>启用</span>
                    } else if (t===2) {
                        return <span style={{color: "red"}}>禁用</span>
                    } else {
                        return t
                    }
                }
            },
            {
                title: '编辑',
                render(t, r) {
                    return (
                        <div>
                            <Button type={'link'} size={'small'} onClick={doEdit(r)}>编辑</Button>
                            <HasPermission code={API_DeleteData} inApi>
                                <Button type={'link'} size={'small'} danger onClick={doDelete(r)}>删除</Button>
                            </HasPermission>
                        </div>
                    )
                }
            }
        ]
    }, [])

    function onModalCancel() {
        setShowEditModal(false)
        setCurr(undefined)
    }
    function doNew() {
        setShowEditModal(true)
        setCurr(undefined)
    }
    function onModalSuccess() {
        setShowEditModal(false)
        setCurr(undefined)
        setQuery({})
    }
    function doQueryFinished(values: any) {
        // console.log(values)
        setQuery(q => ({...values, page_no: 1}))
    }

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery((q) => ({
            ...q,
            page_no: pagination.current,
            page_size: pagination.pageSize,
        }));
    }
    return (
        <PageContainer title={'商圈'}>
            <PageCard>
                <Form form={queryForm} layout='inline' onFinish={doQueryFinished}>
                    <Form.Item label='城市' name='city_id'>
                        <Select<number> placeholder='选择城市' value={selectedCityId} allowClear style={{ width: 120 }} onChange={(id) => {
                            setSelectedCityId(id)
                            queryForm.setFieldsValue({"area_id": undefined})
                        }}>
                            {
                                cites.map((item) => {
                                    return <Select.Option value={item.ID} key={item.ID}>{item.Name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label='区域' name='area_id'>
                        <Select<number> placeholder='选择区域' value={selectedAreaId} allowClear style={{ width: 120 }} onChange={(id) => { setSelectedAreaId(id) }}>
                            {
                                areas.map((item) => {
                                    return <Select.Option value={item.ID} key={item.ID}>{item.Name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label={"商圈"} name={"name"}>
                        <Input placeholder={"输入商圈名称"}/>
                    </Form.Item>

                    <Form.Item label={"状态"} name={"enable"}>
                        <Select style={{width: 100}} placeholder={'请选择'} allowClear>
                            <Select.Option value={1}>启用</Select.Option>
                            <Select.Option value={2}>禁用</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType='submit' type='primary'>查询</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType='reset'>重置</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType='button' type='primary' ghost onClick={doNew} >新增</Button>
                    </Form.Item>
                </Form>
            </PageCard>

            <PageCard>
                <Table
                    loading={loading}
                    columns={columns}
                    rowKey={'Id'}
                    dataSource={districtList}
                    bordered
                    pagination={page}
                    size={'middle'}
                    tableLayout={'fixed'}
                    onChange={onTableChange}
                />
            </PageCard>
            <DistrictEditModal visible={showEditModal} onCancel={onModalCancel} onSuccess={onModalSuccess} curr={curr}/>
        </PageContainer>
    )
}