import {IExecResult, IPage, IWithKey} from "./interface";
import {doPageQuery, Post} from "./r";

export interface IEmployeeVO extends IWithKey{
    id: number
    name: string
    login_name: string
    status: number
    phone: string
    email: string
}

export interface IEmployeeParams extends IPage {

}

export interface ICreateDto {
    name: string
    login_name: string
    phone: string
    email: string
}

export interface IEditDto extends ICreateDto {
    id: number
}

export const EmployeeService = {
    list(params: Partial<IEmployeeParams>) {
        return doPageQuery<IEmployeeVO>('/employee/list', params)
    },
    create(dto: ICreateDto) {
        return Post<IExecResult>("/employee/create", dto)
    },
    update(dto: IEditDto) {
        return Post<IExecResult>("/employee/update", dto)
    },
    delete(id: number) {
        return Post<IExecResult>("/employee/delete", {id})
    },
    disable(id: number) {
        return Post<IExecResult>("/employee/disable", {id})
    },
    enable(id: number) {
        return Post<IExecResult>("/employee/enable", {id})
    },
    // 离职
    joinOut(id: number) {
        return Post<IExecResult>("/employee/join_out", {id})
    },
    // 入职
    joinIn(id: number) {
        return Post<IExecResult>("/employee/join_in", {id})
    },
    // 重置密码 
    resetPassword(id: number) {
        return Post<IExecResult>("/employee/reset_password", {id})
    }
}