import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {ConfigProvider} from 'antd';

import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import styles from './App.module.css';
import {Login} from "./pages/Login/Login";
import {MainLayout} from "./layout/MainLayout";
import {ResetPassword} from "./pages/Login/ResetPassword";
import UserCenter from "./pages/UserCenter";
import {AppProvider} from "./global/GlobalContext";
import EventBus from "./components/EventBus/EventBus";

moment.locale('zh-cn');

function App() {
    return (
        <AppProvider>
            <div className={styles.App}>
                <ConfigProvider locale={zhCN}>
                    <Router>
                        <EventBus />
                        <Switch>
                            <Route path={'/login'} component={Login} exact/>
                            <Route path={'/reset_password'} component={ResetPassword} exact/>
                            <Route path={'/user_center'} component={UserCenter}/>
                            <MainLayout />
                        </Switch>
                    </Router>
                </ConfigProvider>
            </div>
        </AppProvider>
    );
}

export default App;
