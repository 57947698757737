import React, {useCallback, useContext, useState} from "react";
import styles from "../App.module.css";
import {SideMenu} from "./SideMenu";
import {Layout} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Route, Switch} from "react-router-dom";
import {routes} from "./routes";
import {HeaderUserInfo} from "./HeaderUserInfo";
import logoUrl from './logo.png';
import logoUrls from './logo-s.png';
import {GlobalContext} from "../global/GlobalContext";
import NoPermission from "../components/NoPermission/NoPermission";

const {Header, Sider, Content} = Layout;

export function MainLayout() {
    const { menuPermission, config } = useContext(GlobalContext)
    const [collapsed, setCollapsed] = useState<boolean>(false)
    const toggle = useCallback(() => {
        setCollapsed(v => !v)
    }, [])

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} theme={'light'} className={styles.sider}>
                <div className={styles.logo}>
                    {/*<div>智</div>*/}
                    {/*<div>远</div>*/}
                    {/*<div>企</div>*/}
                    {/*<div>服</div>*/}
                    <img src={collapsed ? logoUrls: logoUrl} alt="图片" />
                </div>
                <SideMenu />
            </Sider>
            <Layout className={styles.siteLayout}>
                <Header className={styles.siteLayoutHeader}>
                    {
                        React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: styles.trigger,
                                onClick: toggle,
                            }
                        )
                    }
                    <HeaderUserInfo />
                </Header>
                <Content className={styles.siteLayoutContent}>
                    <Switch>
                        {
                            routes.map((item) => {
                                let hasPermission = config.showAllMenu || (item.access === undefined || menuPermission[item.path])
                                return <Route key={item.path} path={item.path} exact component={hasPermission ? item.Component: NoPermission} />
                            })
                        }
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    )
}