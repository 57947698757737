import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {Button, Form, Input, message, Modal, Select, Table, Tag} from "antd";
import {useEffect, useMemo, useState} from "react";
import NewsEditModal from "./NewsEditModal";
import {ColumnsType} from "antd/es/table";
import {TablePaginationConfig} from "antd/lib/table/interface";
import {INewsParams, INewsVO, NewsService} from "../../service/NewsService";
import {Helper} from "../../utils/Helper";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";

export default function NewsList() {
    const [queryForm] = Form.useForm()
    const [list, setList] = useState<INewsVO[]>([])
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [loading, setLoading] = useState<boolean>(false)
    const [query, setQuery] = useState<Partial<INewsParams>>({ page_no: 1, page_size: 10 })
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [curr, setCurr] = useState<INewsVO>()
    const [editForm] = Form.useForm()

    const columns = useMemo<ColumnsType<INewsVO>>(() => {
        function doEdit(item: INewsVO) {
            return () => {
                setShowEditModal(true)
                setCurr(item)
                setTimeout(() => {
                   editForm.resetFields()
                })
            }
        }

        function doDelete(item: INewsVO) {
            return () => {
                Modal.confirm({
                    title: '确认删除',
                    content: "该操作是不可逆的，请谨慎操作",
                    onOk() {
                        NewsService.delete(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    }
                })
            }
        }

        function doPublish(item: INewsVO) {
            return () => {
                Modal.confirm({
                    title: '确认发布上线',
                    onOk() {
                        NewsService.publish(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    }
                })
            }
        }

        function doOffLine(item: INewsVO) {
            return () => {
                Modal.confirm({
                    title: '确认下线',
                    onOk() {
                        NewsService.offline(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    }
                })
            }
        }
        return [
            {
                title: '# ID',
                dataIndex: 'id',
                width: 120,
                align: 'center'
            },
            {
                title: '标题',
                dataIndex: 'title'
            },
            // {
            //     title: '类型',
            //     dataIndex: 'type',
            // },
            {
                title: '状态',
                dataIndex: 'status',
                width: 150,
                align: 'center',
                render(t) {
                    if (t === 1) {
                        return <Tag>待发布</Tag>
                    }
                    if (t === 2) {
                        return <Tag color={'green'}>已发布</Tag>
                    }
                    if (t === 3) {
                        return <Tag color={'red'}>已下架</Tag>
                    }
                }
            },
            {
                title: '发布时间',
                dataIndex: 'publish_time',
                width: 170,
                align: "center",
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '阅读次数',
                dataIndex: "read_counts",
                align: 'right',
                width: 150,
            },
            {
                title: '操作',
                width: 240,
                render(_, r) {
                    return <div>
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doEdit(r)}>编辑</Button>
                        <HasPermission code={API_DeleteData}>
                            <Button onClick={doDelete(r)} type={'link'} size={'small'} danger>删除</Button>
                        </HasPermission>
                        {
                            r.status === 1 || r.status === 3 ? <Button htmlType={'button'} type={'link'} size={'small'} onClick={doPublish(r)}>发布上线</Button> : null
                        }
                        {
                            r.status === 2 ? <Button htmlType={'button'} type={'link'} size={'small'} onClick={doOffLine(r)}>下线</Button> : null
                        }
                    </div>
                }
            }
        ]
    }, [editForm])

    useEffect(() => {
        setLoading(true)
        NewsService.list(query).then((res) => {
            setList(res.list)
            setPage(res.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    function onEditSuccess() {
        setShowEditModal(false)
        setQuery((q) => ({...q}))
    }

    function onEditCancel() {
        setShowEditModal(false)
    }

    function doClickNew() {
        setShowEditModal(true)
        setCurr(undefined)
    }

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    function onQueryFormFinished(values: any) {
        console.log(values)
        setQuery((q) => ({...values, page_no: 1}))
    }

    return <PageContainer title={'新闻'}>
        <PageCard>
            <Form layout={'inline'} form={queryForm} onFinish={onQueryFormFinished}>
                <Form.Item name={"title"}>
                    <Input placeholder={"标题"}/>
                </Form.Item>
                <Form.Item name={"status"}>
                   <Select placeholder={'选择新闻状态'} style={{width: 150}} allowClear>
                       <Select.Option value={1}>待发布</Select.Option>
                       <Select.Option value={2}>已发布</Select.Option>
                       <Select.Option value={3}>已下线</Select.Option>
                   </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'reset'}>重置</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'button'} type={'primary'} ghost onClick={doClickNew}>新增</Button>
                </Form.Item>
            </Form>
        </PageCard>

        <PageCard>
            <Table
                tableLayout={'fixed'}
                columns={columns}
                dataSource={list}
                size={'middle'}
                rowKey={'key'}
                pagination={page}
                loading={loading}
                onChange={onTableChange}
                bordered
            />
        </PageCard>

        <NewsEditModal visible={showEditModal} curr={curr} onSuccess={onEditSuccess} onCancel={onEditCancel} />
    </PageContainer>
}