import React from "react";

const pageCardStyle: React.CSSProperties = {
    padding: '12px',
    backgroundColor: "#fff",
    marginBottom: 12,
}

export interface IPageCardProps {
    isQueryForm?: boolean
}

const PageCard: React.FC<IPageCardProps> = ({isQueryForm,children}) => {
    // page-card--query-form css样式在 index.css 里面控制的
    return <div style={pageCardStyle} className={isQueryForm ? 'page-card--query-form' : ''}>{children}</div>
}

export default PageCard