import {Button, Form, Select, Table} from "antd";
import {useCallback, useState} from "react";
import {
    CustomerChangeService,
    ICustomerChangeLog,
    ICustomerChangeLogQuery,
    ICustomerChangeOptEnum, ICustomerChangeOptEnumList, ICustomerChangeOptEnumMap
} from "../../../service/CustomerChangeLogService";
import {useList} from "./useList";
import {TablePaginationConfig} from "antd/es/table";
import CustomerChangeLogCreateModal from "./CustomerChangeLogCreateModal";

interface ICustomerChangeLogProps {
    customer_id: number
}

export default function CustomerChangeLog(props: ICustomerChangeLogProps) {
    const { customer_id } = props;

    const [queryForm] = Form.useForm()
    
    const [query, setQuery] = useState<Partial<ICustomerChangeLogQuery>>({ page_no: 1, page_size: 10 })

    const onSubmitQuery = (values: any) => {
        setQuery(q => ({...q, ...values, page_no:1}))
    }

    const onRestQuery = () => {
        setQuery(q => ({
            page_no: 1,
            page_size: q.page_size || 10,
            opt_type: ICustomerChangeOptEnum.follow
        } as any));
    }

    const api = useCallback((params: any) => {
        params.customer_id = customer_id;
        return CustomerChangeService.list(params)
    }, [customer_id]);

    const {loading, list, page} = useList<ICustomerChangeLog>(query, api)

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    const [showWriteLogModal, setShowWriteLogModal] = useState(false)
    function doWriteLog() {
        setShowWriteLogModal(true)
    }
    
    return <div>
        <Form layout={'inline'} form={queryForm} onFinish={onSubmitQuery} onReset={onRestQuery}>
            <Form.Item label={'信息类型'} style={{ width: 220 }} name={'opt_type'}>
                <Select placeholder={'请选择'}>
                    {
                        ICustomerChangeOptEnumList.map((item) => {
                            return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>查询</Button>
            </Form.Item>
            <Form.Item>
                <Button type={'default'} htmlType={'reset'}>重置</Button>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} ghost htmlType={'button'} onClick={doWriteLog}>填写跟进信息</Button>
            </Form.Item>
        </Form>
        <div style={{marginBottom: 10}}/>

        <Table rowKey={'id'}
               pagination={page}
               size={'middle'}
               dataSource={list}
               loading={loading}
               onChange={onTableChange}
        >
            <Table.Column dataIndex={'content'} title={'信息'} ellipsis render={(t) => {
                return <span title={t} style={{cursor: 'pointer'}}>{t}</span>
            }}/>
            <Table.Column dataIndex={'opt_type'} title={'变更类型'} width={140} render={(t, r) => {
                return ICustomerChangeOptEnumMap.get(t) || t
            }}/>
            <Table.Column dataIndex={'create_by_name'} title={'操作人'} width={140} />
            <Table.Column dataIndex={'create_time_str'} title={'操作时间'} width={170}/>
        </Table>


        <CustomerChangeLogCreateModal
            customer_id={customer_id}
            visible={showWriteLogModal}
            onCancel={() => {
                setShowWriteLogModal(false)
            }}
            onSuccess={()=> {
                setShowWriteLogModal(false)
                setQuery(q => ({...q}))
            }}
        />
    </div>
}