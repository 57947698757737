import {Button, Form, Input} from "antd";
import style from "./Login.module.css"
import {useContext, useRef} from "react";
import {AuthService} from "../../service/AuthService";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../global/GlobalContext";

const wrapperCol = {
    span: 16
}
const labelCol = {
    span: 6
}

const noLabelWrapCol = {
    span: 16,
    offset: 6
}

export function Login() {
    const [form] = Form.useForm()
    const {reloadUserInfo} = useContext(GlobalContext)
    const instanceRef = useRef<{ isSubmitting: boolean }>({ isSubmitting: false })
    const history = useHistory()
    function doFormFinished(values: any) {
        // console.log(values)

        if (instanceRef.current.isSubmitting) {
            return
        }

        instanceRef.current.isSubmitting = true

        AuthService.login(values).then((data) => {
            // console.log(data)
            if (data.Status === 1) {
                history.replace("/reset_password")
                return
            }

            reloadUserInfo?.();
            history.replace('/')
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }

    return <div className={style.LoginPage}>
        <div className={style.LoginContainer}>
            <div className={style.LoginTitle}>智远企服后台系统</div>
            <Form onFinish={doFormFinished} form={form} wrapperCol={wrapperCol} labelCol={labelCol}>
                <Form.Item label={'用户名'} name={"login_name"} rules={[{required: true, message: '请输入用户名'}, {max: 50, message: '用户名长度不超过50'}, { min: 2,  message: '用户名长度需大于2' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={'密码'} name={"password"} rules={[{required: true, message: '请输入登录密码'}, {max: 50, message: '登录密码长度不超过50'}, { min: 6,  message: '登录密码长度需大于6' }]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={noLabelWrapCol}>
                    <Button htmlType={'submit'} type={'primary'}>登录</Button>
                    <Button htmlType={'reset'} className={style.RestBtn}>重置</Button>
                </Form.Item>
            </Form>
        </div>

    </div>
}