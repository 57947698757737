import {Descriptions, Drawer, Row, Col, Table, Typography} from "antd";
import {CustomerLevelMap, CustomerTypeMap, ICustomerVO, IDemandsVO, IPhoneVO} from "../../../service/CustomerService";
import CustomerChangeLog from "./CustomerChangeLog";
import {Helper} from "../../../utils/Helper";
import CustomerRoomList from "./CustomerRoomList";
import "./CustomerDetail.css"
const { Title } = Typography;

interface IProps {
    visible: boolean
    curr?: ICustomerVO
    onClose: () => void
}

export default function CustomerDetail(props: IProps) {
    const {visible, curr, onClose} = props;
    return <Drawer visible={visible} title={'客源详情'} onClose={onClose} width={'calc(100vw - 210px)'}>
        <div>
            <Title level={2}>客户基本信息</Title>
            <Descriptions layout={'horizontal'} className={"customer-detail"} size={'small'} bordered labelStyle={{width: 140}}>
                <Descriptions.Item label={'客别'}>{CustomerTypeMap.get(curr?.type!)}</Descriptions.Item>
                <Descriptions.Item label={'等级'}>{CustomerLevelMap.get(curr?.level!)}</Descriptions.Item>
                <Descriptions.Item label={'归属人'}>{curr?.owner_name}</Descriptions.Item>
                <Descriptions.Item label={'来源'}>{curr?.source}</Descriptions.Item>

                <Descriptions.Item label={'创建人'}>{curr?.create_by_name}</Descriptions.Item>
                <Descriptions.Item label={'最近修改人'}>{curr?.update_by_name}</Descriptions.Item>

                <Descriptions.Item label={'创建时间'}>{Helper.formatDate(curr?.create_time)}</Descriptions.Item>
                <Descriptions.Item label={'最近修改时间'} span={2}>{Helper.formatDate(curr?.update_time)}</Descriptions.Item>

                <Descriptions.Item label={'备注信息'} span={1}>
                    <CustomerRemark remark={curr?.remark} />
                </Descriptions.Item>
                <Descriptions.Item label={'联系方式'} span={2}>
                    <div style={{ maxWidth: 600}}>
                        <Table dataSource={curr?.phone_list as IPhoneVO[]} rowKey={t => t.name + t.phone} size={'small'} tableLayout={'fixed'} pagination={false}>
                            <Table.Column title={'客户称呼'} dataIndex={'name'} />
                            <Table.Column title={'电话'} dataIndex={'phone'} />
                            <Table.Column title={'职位'} dataIndex={'post'} />
                        </Table>
                    </div>

                </Descriptions.Item>
            </Descriptions>

            <div style={{marginTop: 15}}/>

            <Title level={2}>跟进信息</Title>
            {
                curr?.id ? <CustomerChangeLog customer_id={curr?.id} /> : null
            }

            <Title level={2}>客户需求</Title>

            {
                curr?.demands ? <CustomerRoomList demands={curr.demands as IDemandsVO} />: null
            }
        </div>
    </Drawer>
}

function CustomerRemark(params: {remark?: string}) {
    if (!params.remark) {
        return null
    }
    return <div>
        {
            params.remark.split("\n").map((item, index) => {
                return <div key={index}>{item}</div>
            })
        }
    </div>

}