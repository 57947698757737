import {useHistory} from 'react-router-dom'
import {useEffect} from "react";
export default function EventBus() {
    const history = useHistory()

    useEffect(() => {
        function pathEventHandler() {
            history.replace("/login")
        }

        document.addEventListener('my_route_replace', pathEventHandler, false)
        return () => {
            document.removeEventListener('my_route_replace', pathEventHandler)
        }
    }, [history])

    return null
}

export class HistoryHelper {
    static toLoginPage() {
        const event = document.createEvent('Event');
        event.initEvent('my_route_replace', true, true);
        document.dispatchEvent(event)
    }
}