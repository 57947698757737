import {Checkbox, Col, message, Modal, Row, Spin, Tree, Typography} from 'antd';
import { DataNode } from 'antd/lib/tree';
import {getMenuAccessListV2} from "../../../layout/permission";
import {BookOutlined, LinkOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {IApiPermissionPointVO, IRoleVO, RoleService} from "../../../service/RoleService";
import style from './AccessPermissionAssign.module.css'
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {ICurrSessionInfo} from "../../../service/AuthService";
const { Title } = Typography;

// 把菜单目录结构数据转换为Tree组件需要的数据结构
// 把按钮权限直接放在当前菜单的子节点上。
// 获取Tree组件的数据
function getTreeData() {
    const data: DataNode[] = [];
    const rawData = getMenuAccessListV2();

    function loopData(item: typeof rawData[number], container: DataNode[]) {
        const node: DataNode = {
            key: item.key,
            // label: item.name,
            title: item.name,
            selectable: false,
            icon: <LinkOutlined />,
            children: [],
        };

        // 处理按钮
        if (item.btns) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < item.btns.length; i++) {
                const nodeSub: DataNode = {
                    key: item.btns[i].key,
                    // label: item.btns[i].name,
                    title: item.btns[i].name,
                    selectable: false,
                    icon: <BookOutlined />,
                };
                node.children?.push(nodeSub);
            }
        }

        // 处理子菜单
        if (item.children) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < item.children.length; i++) {
                if (node.children) {
                    loopData(item.children[i], node.children);
                }
            }
        }

        container.push(node);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawData.length; i++) {
        loopData(rawData[i], data);
    }

    return data;
}

interface IProps {
    visible: boolean;
    onSuccess: () => void;
    onCancel: () => void;
    curr: IRoleVO
    userInfo: ICurrSessionInfo
}

export default function AccessPermissionAssign({ curr, userInfo, visible, onSuccess, onCancel }: IProps) {
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [apiPermissionPoint, setApiPermissionPoint] = useState<IApiPermissionPointVO[]>()

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<CheckboxValueType[]>()

    useEffect(() => {
        if (curr && visible) {
            setSelectedKeys((curr.menu_permission||'').split(','))
            setCheckedKeys((curr.api_permission|| '').split(','))
        } else {
            setSelectedKeys([])
            setCheckedKeys([])
        }
    }, [curr, visible])

    function doOk() {
        // console.log('selectedKeys', selectedKeys);
        // console.log('checkedKeys', checkedKeys);
        let data = {...curr}
        data.api_permission = checkedKeys?.join(',') || ''
        data.menu_permission = selectedKeys.join(',')
        data.btn_permission = ''
        data.latest_update_by = userInfo.ID
        data.latest_update_by_name = userInfo.Name

        RoleService.updatePermission(data).then((res) => {
            if (res.Error === null) {
                message.success('更新成功').then(() => void 0)
                onSuccess?.();
            } else {
                message.error(res.Error).then(() => void 0)
            }
        })
    }

    function doCancel() {
        if (onCancel) {
            onCancel();
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        setTreeData(getTreeData());
        RoleService.permissionList().then((data) => {
            setApiPermissionPoint(data)
        })
    }, []);

    function doCheckedChange(checked: any) {
        // console.log(checked)
        // console.log('checked', checked.checked)
        setSelectedKeys(checked.checked);
    }

    function doCheckboxGroupChange(val: CheckboxValueType[] | undefined) {
        // console.log(val);
        setCheckedKeys(val)
    }

    return (
        <Modal onOk={doOk} width={960} visible={visible} onCancel={doCancel} title={`页面菜单按钮权限访问控制 - ${curr?.role_name}`}>
            <Spin spinning={loading}>
                <Row>
                    <Col span={9}>
                        <div>
                            <Title level={5}>页面菜单按钮权限</Title>
                            <div>
                                {treeData.length > 0 ? (
                                    <Tree
                                        treeData={treeData}
                                        selectable={false}
                                        checkable
                                        defaultExpandAll={true}
                                        autoExpandParent
                                        showLine={{ showLeafIcon: false }}
                                        showIcon={true}
                                        checkStrictly={true}
                                        onCheck={doCheckedChange}
                                        checkedKeys={selectedKeys}
                                        // height={500}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </Col>
                    <Col span={15}>
                        <div className={style.apiPermissionPoints}>
                            <Title level={5}>数据权限</Title>
                            <div>
                                <Checkbox.Group onChange={doCheckboxGroupChange} value={checkedKeys}>
                                    {
                                        apiPermissionPoint?.map((item) => {
                                            return <div key={item.Name}>
                                                <div>{item.Name}</div>
                                                <div>
                                                    {item.Permissions.map((pp) => {
                                                        return <Checkbox value={pp.Code} key={pp.Code}>{pp.Name}</Checkbox>
                                                    })}
                                                </div>
                                            </div>
                                        })
                                    }
                                </Checkbox.Group>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}