import {Button, Divider, Form, Select, Table} from "antd";
import {
    IRoomChangeLog,
    IRoomChangeLogQuery, IRoomChangeOptEnumList,
    IRoomChangeOptEnumMap,
    RoomChangeLogService
} from "../../../service/RoomChangeLogService";
import {useEffect, useState} from "react";
import {TablePaginationConfig} from "antd/es/table";
import {Helper} from "../../../utils/Helper";

interface IProps {
    roomId?: string
    freshFlag: number
}

export default function RoomChangeLog(props: IProps) {
    const {roomId, freshFlag} = props
    const [list, setList] = useState<IRoomChangeLog[]>([])
    const [page, setPage] = useState<TablePaginationConfig>(Helper.getDefaultPage())
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState<Partial<IRoomChangeLogQuery>>({page_no: 1, page_size: 10})

    const [queryForm] = Form.useForm()

    useEffect(() => {
        if (freshFlag !== 0) {
            setQuery(q => ({...q}))
        }
    }, [freshFlag])

    useEffect(() => {
        if (roomId) {
            setList([])
            setPage(Helper.getDefaultPage())
            setQuery((query) => ({...query, page_no: 1, room_id: roomId}))
        } else {
          setQuery(q => ({page_no: 1, page_size: q.page_size}))
          setList([])
          setPage(Helper.getDefaultPage())
        }
    }, [roomId])

    useEffect(() => {
        if (query.room_id) {
            setLoading(true)
            RoomChangeLogService.list({...query}).then((data) => {
                setList(data.list)
                setPage(data.page)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [query])

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    const onSubmitQuery = (values: any) => {
        setQuery(q => ({...q, ...values, room_id: roomId, page_no:1}))
    }

    const onRestQuery = () => {
        setQuery(q => ({page_no: 1, page_size: q.page_size || 10, room_id: roomId}))
    }

    return (
        <>
            <Form layout={'inline'} form={queryForm} onFinish={onSubmitQuery} onReset={onRestQuery}>
                <Form.Item label={'信息类型'} style={{ width: 220 }} name={'opt_type'}>
                    <Select placeholder={'请选择'}>
                        {
                            IRoomChangeOptEnumList.map((item) => {
                                return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type={'primary'} htmlType={'submit'}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button type={'default'} htmlType={'reset'}>重置</Button>
                </Form.Item>
            </Form>

            <Divider type={'horizontal'} />

            <Table rowKey={'id'}
                   pagination={page}
                   size={'middle'}
                   dataSource={list}
                   loading={loading}
                   onChange={onTableChange}
            >
                <Table.Column dataIndex={'content'} title={'信息'} ellipsis render={(t) => {
                    return <span title={t} style={{cursor: 'pointer'}}>{t}</span>
                }}/>
                <Table.Column dataIndex={'opt_type'} title={'变更类型'} width={140} render={(t, r) => {
                    return IRoomChangeOptEnumMap.get(t) || t
                }}/>
                <Table.Column dataIndex={'create_by_name'} title={'操作人'} width={140} />
                <Table.Column dataIndex={'create_time_str'} title={'操作时间'} width={170}/>
            </Table>
        </>
    )
}