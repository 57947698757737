import {PageContainer} from "../../layout/PageContainer";
import {Button, Form, Input, message, Modal, Table, Tag} from "antd";
import {useEffect, useMemo, useRef, useState} from "react";
import {ColumnsType} from "antd/es/table";
import PageCard from "../../layout/PageCard";
import {Helper} from "../../utils/Helper";
import {EmployeeService, IEmployeeParams, IEmployeeVO} from "../../service/EmployeeService";
import {TablePaginationConfig} from "antd/lib/table/interface";
import {AuthService, ICurrSessionInfo} from "../../service/AuthService";
import {UserRoleSetting} from "./components/UserRoleSetting";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";
import HasPermission from "../../components/HasPermission/HasPermission";

export default function EmployeeList() {
    const [queryForm] = Form.useForm()
    const [showEditModal, setShowEditModal] = useState(false)
    const [curr, setCurr] = useState<IEmployeeVO>()
    const [list, setList] = useState<IEmployeeVO[]>([])
    const [query, setQuery] = useState<Partial<IEmployeeParams>>({page_no: 1, page_size: 10})
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [loading, setLoading] = useState<boolean>(false)

    const [editForm] = Form.useForm()

    const instanceRef = useRef<{isSubmitting: boolean}>({isSubmitting: false})
    const [userInfo, setUserInfo] = useState<ICurrSessionInfo>()

    const [showUserRoleModal, setShowUserRoleModal] = useState<boolean>(false)

    const columns = useMemo<ColumnsType<IEmployeeVO>>(() => {
        function doEdit(item: IEmployeeVO) {
            return () => {
                setCurr(item)
                setShowEditModal(true)
                setTimeout(() => {
                    editForm.resetFields()
                }, 100)
            }
        }

        function doEditUserRole(item: IEmployeeVO) {
            return () => {
                setCurr(item)
                setShowUserRoleModal(true)
            }
        }

        function doDelete(item: IEmployeeVO) {
            return () => {
                Modal.confirm({
                    title: '确认删除',
                    content: "该操作是不可逆的，确认后该员工的数据将会从数据库里面删除，请谨慎操作！",
                    onOk() {
                        EmployeeService.delete(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    },
                    onCancel() {

                    }
                })
            }
        }

        // 禁用账号
        function doDisableAccount(item: IEmployeeVO) {
            return () => {
                Modal.confirm({
                    title: '确认禁用该账号',
                    content: "禁用后，该账号将会禁止登录，后面可以通过点击【启用】按钮恢复登录。",
                    onOk() {
                        EmployeeService.disable(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    },
                    onCancel() {
                        
                    }
                })
            }
        }

        // 启用账号
        function doEnableAccount(item: IEmployeeVO) {
            return () => {
                Modal.confirm({
                    title: '确认启用该账号',
                    content: "启用后，该账号将会解除限制登录",
                    onOk() {
                        EmployeeService.enable(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    },
                    onCancel() {
                        
                    }
                })
            }
        }

        // 离职
        function doJoinOut(item: IEmployeeVO) {
            return () => {
                Modal.confirm({
                    title: '确认设置账号为离职状态',
                    content: "设置后离职状态后，该账号将不能再登录系统，后面可以通过【入职】按钮恢复为入职状态。",
                    onOk() {
                        EmployeeService.joinOut(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    },
                    onCancel() {
                        
                    }
                })
            }
        }

        // 入职
        function doJoinIn(item: IEmployeeVO) {
            return () => {
                Modal.confirm({
                    title: '确认设置账号为入职状态',
                    content: "设置后入职状态后，该账号将会恢复为正常使用状态",
                    onOk() {
                        EmployeeService.joinIn(item.id).then((res) => {
                            if (res.RowsAffected !== 0) {
                                message.success('操作成功').then(() => {})
                                setQuery((q) => ({...q}))
                            }
                        })
                    },
                    onCancel() {
                        
                    }
                })
            }
        }


        return [
            {
                title: "ID",
                dataIndex: 'id',
                width: 100,
                align: 'center'
            },
            {
                title: "姓名",
                dataIndex: "name"
            },
            {
                title: "登录名",
                dataIndex: "login_name"
            },
            {
                title: "邮箱",
                dataIndex: "email"
            },
            {
                title: "电话",
                dataIndex: "phone",
                width: 140,
                align: 'center'
            },
            {
                title: "状态",
                dataIndex: "status",
                width: 140,
                align: 'center',
                render(t) {
                    if (t === 2 || t === 1) {
                        return <Tag color={'green'}>正常</Tag>
                    }
                    if (t===3) {
                        return <Tag color={'red'}>已禁用</Tag>
                    }
                    if (t===4) {
                        return <Tag color='gold'>已离职</Tag>
                    }
                    return null
                }
            },
            {
                title: '操作',
                width: 280,
                fixed: 'right',
                render(t,r) {
                    return <div>
                        <Button type="link" size={'small'} onClick={doEditUserRole(r)}>角色设置</Button>
                        <Button type="link" size={'small'} onClick={doEdit(r)}>编辑</Button>
                        {
                            r.status === 2 || r.status === 1 ? <Button type="link" size={'small'} onClick={doDisableAccount(r)}>禁用</Button> : null
                        }

                        {
                            r.status === 3 ? <Button type={"link"} size={'small'} onClick={doEnableAccount(r)}>启用</Button> : null
                        }

                        {
                            r.status !== 4 ? <Button type={"link"} size={'small'} onClick={doJoinOut(r)}>离职</Button> : null
                        }

                        {
                            r.status === 4 ? <Button type="link" size={'small'} onClick={doJoinIn(r)}>入职</Button> : null
                        }
                        <HasPermission code={API_DeleteData} inApi>
                            <Button type={"link"} size={'small'} danger onClick={doDelete(r)}>删除</Button>
                        </HasPermission>
                    </div>
                }
            }
        ]
    }, [editForm])

    useEffect(() => {
        setLoading(true)
        EmployeeService.list(query).then((data) => {
            setList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    useEffect(()=> {
        AuthService.info().then((user) => {
            setUserInfo(user)
        })
    }, [])

    function onModalCancel() {
        setShowEditModal(false)
        setCurr(undefined)
    }

    function doNew() {
        setShowEditModal(true)
        setCurr(undefined)
        setTimeout(() => {
            editForm.resetFields()
        }, 100)
    }


    function onQueryFormFinished(values: any) {
        console.log(values)
        setQuery((q) => ({...values, page_no: 1}))
    }

    function onEditFormFinished(values: any) {
        console.log(values)
        if (instanceRef.current.isSubmitting) {
            return
        }
        instanceRef.current.isSubmitting = true

        let api = EmployeeService.create
        let params = {...values}

        if (curr) {
            params.id = curr.id
            api = EmployeeService.update
        }

        api(params).then((data) => {
            message.success('操作成功')
            console.log('这里是消息', data)
            setQuery(() => ({...query}))
            setShowEditModal(false)
        }).finally(() => {
            setTimeout(() => {
                instanceRef.current.isSubmitting = false
            }, 100)
        })
    }

    function onTableChange(pagination: TablePaginationConfig) {
        // console.log(pagination)
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    return <PageContainer title={'员工管理'}>
        <PageCard>
            <Button type={'primary'} ghost htmlType={'button'} onClick={doNew}>新增</Button>
        </PageCard>
        <PageCard>
            <Form form={queryForm} layout='inline' onFinish={onQueryFormFinished}>
                <Form.Item label={'姓名'} name={"name"}>
                    <Input style={{width: 130}}/>
                </Form.Item>
                <Form.Item label={'登录名'} name={"login_name"}>
                    <Input style={{width: 130}}/>
                </Form.Item>
                <Form.Item label={'电话号码'} name={"phone"}>
                    <Input style={{width: 130}}/>
                </Form.Item>
                <Form.Item>
                    <Button type={"primary"} htmlType={'submit'}>
                        查询
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'reset'}>
                        重置
                    </Button>
                </Form.Item>
            </Form>
        </PageCard>

        <PageCard>
            <Table
                tableLayout={'fixed'}
                columns={columns}
                dataSource={list}
                size={'middle'}
                rowKey={'key'}
                pagination={page}
                loading={loading}
                scroll={{x: 1280}}
                onChange={onTableChange}
                bordered
            />
        </PageCard>

        <Modal visible={showEditModal} title={curr ? '编辑' : '新增'} onCancel={onModalCancel} footer={false}>
            <Form form={editForm} initialValues={curr} wrapperCol={{span: 18}} labelCol={{span: 4}} onFinish={onEditFormFinished}>
                <Form.Item
                    label={"姓名"}
                    name={"name"}
                    extra={<div>员工的真实姓名，例如: 张三</div>}
                    rules={[{ required: true, message: "该项必填" }, { min: 2, message: "最少2个字" }, {max: 10, message: '最长10个字'}]}>
                    <Input placeholder={"输入员工姓名"}/>
                </Form.Item>

                <Form.Item label={"登录名"} name={"login_name"}
                           rules={[{required: true, message: "登录名必须填写"}, { min: 2, message: "最少2个字" }, {max: 20, message: '最长20个字'}]}
                           extra={<div>登录名用于登录后台系统, 不能重复, 建议使用拼音加数字；例如：zhangs 。员工的默认密码为登录名+000000。例如：zhangs 的默认密码是 zhangs000000。用户首次登录的时候系统会要求强制修改密码。</div>}>
                    <Input placeholder={"输入登录名"}/>
                </Form.Item>

                <Form.Item
                    label={"手机号"} name={"phone"}
                    extra={<div>手机号用于员工辅助登录和找回密码</div>}
                    rules={[{ required: true, message: "手机号必填" }, {pattern: /^1\d{10}$/, message: "请输入正确的手机号"}]}>
                    <Input placeholder={"输入手机号"}/>
                </Form.Item>

                <Form.Item label={"邮箱"} name={"email"} rules={[{type: "email", message: "邮箱格式不正确"}]} extra={<div>邮箱用于辅助员工找回密码</div>}>
                    <Input placeholder={"输入邮箱"}/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 4, span: 18}}>
                    <Button htmlType={'submit'} type={'primary'}>保存</Button>
                    <Button htmlType={'reset'} style={{marginLeft: 25}}>重置</Button>
                </Form.Item>
            </Form>
        </Modal>

        <UserRoleSetting
            userInfo={userInfo!}
            visible={showUserRoleModal}
            curr={curr!}
            onSuccess={() => {
                setShowUserRoleModal(false)
            }}
            onCancel={() => {
                setShowUserRoleModal(false)
            }}
        />
    </PageContainer>
}