import {message, Modal, Select} from "antd";
import {useEffect, useState} from "react";
import {EmployeeService, IEmployeeVO} from "../../service/EmployeeService";
import {IExecResult} from "../../service/interface";

interface IProps {
    ids: string[]
    onSuccess: () => void
    onCancel: () => void
    visible: boolean
    api: (ids: string[], userid: number) => Promise<IExecResult>
}

export default function SelectEmployeeModal({ids, onSuccess, visible, onCancel, api}: IProps) {
    const [useList, setUserList] = useState<IEmployeeVO[]>([])
    const [uid, setUid] = useState<number>()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (visible) {
            setUid(undefined)
            EmployeeService.list({page_no: 1, page_size: 100}).then((data) => {
                setUserList(data.list)
            })
        }
    }, [visible])

    function doCancel() {
        onCancel()
    }

    function doOk() {
        if (!uid) {
            message.error('请选择用户').then(() => {})
            return
        }
        if (!ids || ids.length === 0) {
            message.error('请选择用户').then(() => {})
            return
        }

        // console.log(ids, uid)
        setIsSubmitting(true)
        api(ids, uid).then((res) => {
            if (res.Error === null) {
                message.success('操作成功').then(() => {})
                onSuccess();
            }
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    return <Modal title={'请选择用户'} visible={visible}
                  onOk={ isSubmitting ? undefined : doOk}
                  onCancel={ isSubmitting ? undefined : doCancel}
                  okButtonProps={{loading: isSubmitting, disabled: uid === undefined}}
                  cancelButtonProps={{disabled: isSubmitting}}
    >
        <div style={{marginBottom: 6}}><span style={{color: 'red'}}>*</span>选择用户</div>
        <Select style={{width: '100%'}} value={uid} onChange={setUid} showSearch filterOption={(input, option) =>
            (option as any).children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        } placeholder={'请选择'}>
            {
                useList.map((item) => {
                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                })
            }
        </Select>
    </Modal>
}