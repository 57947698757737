import PageCard from "../../layout/PageCard";
import {Button, message, Modal, Table, Tag} from "antd";
import useQuery from "./components/useQuery";
import {useContext, useMemo, useState} from "react";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {useList} from "./components/useList";
import {useFunction} from "../../components/hooks/useFunction";
import {
    CustomerLevelMap,
    CustomerLevelStatus,
    CustomerService,
    CustomerType,
    CustomerTypeMap,
    ICustomerVO,
    IPhoneVO
} from "../../service/CustomerService";
import {Helper} from "../../utils/Helper";
import If from "../../components/Logic/If";
import CustomerDetail from "./components/CustomerDetail";
import CustomerEditModal from "./components/CustomerEditModal";
import {SetCustomerLevelModal} from "./components/SetCustomerLevelModal";
import {SetCustomerTypeModal} from "./components/SetCustomerTypeModal";
import {CustomerChangeService, ICustomerChangeOptEnum} from "../../service/CustomerChangeLogService";
import {CustomerChangeAuditService} from "../../service/CustomerChangeAuditService";
import useAccess from "../../components/hooks/useAccess";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";
import HasPermission from "../../components/HasPermission/HasPermission";
import {GlobalContext} from "../../global/GlobalContext";


interface IProps {
    type?: CustomerType
}

export default function CustomerList(props: IProps) {
    const { type } = props
    const {query, setQuery, queryForm} = useQuery()

    const {currUserInfo} = useContext(GlobalContext)

    const api = useFunction((params: any) => {
        if (type) {
            params.type = type
        }

        if (type === CustomerType.Private) {
            params.owner = currUserInfo?.ID
        }

        return CustomerService.list(params)
    })

    const {loading, list, page} = useList<ICustomerVO>(query, api)

    const [showDetail, setShowDetail] = useState(false)
    const [curr, setCurr] = useState<ICustomerVO>()

    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showSetLevelModal, setShowSetLevelModal] = useState(false)
    const [showSetTypeModal, setShowSetTypeModal] = useState(false)

    const access = useAccess()

    function doNewCustomer() {
        setShowEditModal(true)
        setCurr(undefined)
    }

    const columns = useMemo<ColumnsType<ICustomerVO>>(() => {
        function showDetail(info: ICustomerVO) {
            setShowDetail(true)
            setCurr(info)
        }

        function doEdit(info: ICustomerVO) {
            setShowEditModal(true)
            setCurr(info)
        }

        function deleteInfo(info: ICustomerVO) {
            Modal.confirm({
                title: '确认删除？',
                content: '该操作是不可逆的，请谨慎操作',
                 onOk() {
                    async function req() {
                        await CustomerService.deleteCustomer({customer_id: info.id})
                        await CustomerChangeAuditService.deleteByCustomerId({customer_id: info.id})
                        await CustomerChangeService.delete({customer_id: info.id})
                    }
                    return req().then(() => {
                        message.success('操作成功').then(() => {})
                        setQuery(q => ({...q}))
                    });
                 },

            })
        }

        function setPrivate (info: ICustomerVO) {
            Modal.confirm({
                title: '确定抢客?',
                content: '该操作会把本客源设置为您的私客',
                onOk() {
                    async function reqFun() {
                        let res1 = await CustomerChangeService.create({customer_id: info.id, content: '设置为私客', opt_type: ICustomerChangeOptEnum.changeType})
                        if (res1.Error !== null) {
                            throw new Error('保存便跟记录失败了， 请联系管理员处理')
                        }

                        let res2 = await CustomerService.setToPrivate(info.id);
                        if (res2.Error!== null) {
                            throw new Error('设置客户状态失败了， 请联系管理员处理')
                        }

                        message.success('抢客成功').then(() => {})
                        setQuery(q => ({...q}));
                    }

                    return reqFun()
                }
            })
        }

        function setPublic(info: ICustomerVO) {
            setCurr(info)
            setShowSetTypeModal(true)
            // Modal.confirm({
            //     title: '确定设置为公客?',
            //     content: '设置为公客是需要审核的，确定提交审核',
            //     onOk() {
            //         return CustomerService.setToPublic(info.id).then(() => {
            //             message.success('提交成功').then(() => {})
            //             setQuery(q => ({...q}));
            //         })
            //     }
            // })
        }

        // 设置客户的等级
        function setLevel(info: ICustomerVO) {
            setCurr(info);
            setShowSetLevelModal(true)
        }

        return [
            { title: 'ID', dataIndex: 'id', width: 80, align: 'right'},
            { title: '用户', dataIndex: 'phone_list', render(t, r) {
                if (r.phone_list) {
                    return <>
                        {
                            (r.phone_list as IPhoneVO[]).map((item, index) => {
                                return <div key={index}>
                                    {item.name}
                                    {item.phone}
                                    ({item.post})
                                </div>
                            })
                        }
                    </>
                }
                return ''
            }},
            { title: '类型', width: 120, render(t, r) {
                return (CustomerTypeMap.get(r.type) || r.type) as any
            } },
            { title: '等级', width: 120, render(t,r) {
                return (CustomerLevelMap.get(r.level) || r.level) as any
                } },
            {
              title: '等级变更状态', dataIndex: 'level_status', width: 120, render(t ,r) {
                  if (t === 2) {
                      return <Tag color={'gold'}>审核中</Tag>
                  }
                  return <Tag color={'green'}>正常</Tag>
                }
            },
            {
                title: '客别变更状态', dataIndex: 'type_status', width: 120, render(t ,r) {
                    if (t === 2) {
                        return <Tag color={'gold'}>审核中</Tag>
                    }
                    return <Tag color={'green'}>正常</Tag>
                }
            },
            { title: '备注信息', dataIndex: 'remark' },
            ...(type === undefined ? [{ title: '归属人', width: 120, dataIndex: 'owner_name' }]:[]),
            { title: '创建人', width: 120, dataIndex: 'create_by_name' },
            { title: '创建时间', width: 180, dataIndex: 'create_time', render(t) {
                return Helper.formatDate(t)
            } },
            { title: '更新人', width: 120, dataIndex: 'update_by_name' },
            { title: '更新时间', width: 180, dataIndex: 'update_time', render(t) {
                    return Helper.formatDate(t)
                } },
            { title: '操作', width: 240, fixed: 'right', render(t, r) {
                return <div>
                    <Button type={'link'} size={'small'} onClick={() => showDetail(r)}>详情</Button>

                    {/* 普通用只能编辑个人的，管理员可以任何模式里面编辑 */}
                    <If condition={type === CustomerType.Private || access.customer_edit_without_audit}>
                        <Button type={'link'} size={'small'} onClick={() => doEdit(r)}>编辑</Button>
                    </If>

                    <If condition={type === CustomerType.Public}>
                        <Button type={'link'} size={'small'} onClick={() => setPrivate(r)}>设为私客</Button>
                    </If>

                    <If condition={type === CustomerType.Private}>
                        <Button type={'link'} size={'small'} onClick={() => setPublic(r)}>设为公客</Button>
                    </If>

                    <If condition={r.level_status !== CustomerLevelStatus.isAuditing && type === CustomerType.Private}>
                        <Button type={'link'} size={'small'} onClick={() => setLevel(r)}>设置客户等级</Button>
                    </If>

                    <HasPermission code={API_DeleteData}>
                        <Button type={'link'} danger size={'small'} onClick={() => deleteInfo(r)}>删除</Button>
                    </HasPermission>
                </div>
            } },
        ]
    }, [setQuery, type, access.customer_edit_without_audit])

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    return <>
        <PageCard>
            <Button type={'primary'} onClick={doNewCustomer}>新增</Button>
        </PageCard>

        <PageCard isQueryForm>
            { queryForm }
        </PageCard>

        <PageCard>
            <Table
                tableLayout={'fixed'}
                rowKey={(t) => t.key}
                dataSource={list}
                pagination={page}
                onChange={onTableChange}
                loading={loading}
                bordered
                columns={columns}
                scroll={{ x: type === undefined ? 2000 : 1900 }}
            />
        </PageCard>

        <CustomerDetail onClose={() => {
            setCurr(undefined)
            setShowDetail(false)
        }} visible={showDetail} curr={curr} />

        <CustomerEditModal
            visible={showEditModal}
            curr={curr}
            onSuccess={() => {
                setShowEditModal(false)
                setQuery((q) => ({...q}))
            }}
            onClose={() => {
                setShowEditModal(false)
            }}
        />

        <SetCustomerLevelModal
            visible={showSetLevelModal}
            curr={curr}
            onSuccess={() => {
                setCurr(undefined)
                setShowSetLevelModal(false)
                setQuery((q) => ({...q}))
            }}
            onClose={() => {
                setCurr(undefined)
                setShowSetLevelModal(false)
            }}
        />

        <SetCustomerTypeModal
            visible={showSetTypeModal}
            curr={curr}
            onSuccess={() => {
                setCurr(undefined)
                setShowSetTypeModal(false)
                setQuery((q) => ({...q}))
            }}
            onClose={() => {
                setCurr(undefined)
                setShowSetTypeModal(false)
            }}
        />
    </>
}