import { useMemo } from 'react';
import {useLocation} from "react-router-dom";

interface IQuerySet {
    [key: string]: string;
}

// 获取URL上的查询参数
// T 表示接受的参数列表 例如: "uid" | "vin"。 在url上传递的参数有 uid 和 vin, 也可以没有
export function useQuery<T extends keyof any>(): { [P in T]: string | undefined } {
    const location = useLocation();
    const queryMemo = useMemo(() => {
        // console.log(location.search)
        const searchParams = new URLSearchParams(location.search);
        const resultObj: IQuerySet = {};
        searchParams.forEach((value, key) => {
            resultObj[key] = value;
        });
        return resultObj;
    }, [location]);

    return queryMemo as any;
}