import type {IPage, IWithKey} from "./interface";
import {doPageQuery, ExecSql, Post} from "./r";
import {IExecResult} from "./interface";

export const RoomStatusList = [
    [ 1, '草稿' ],
    [ 2, '上架' ],
    [ 3, '下架' ],
]

export const RoomsStatusMap = new Map(RoomStatusList as any);

export const RoomPhoneTypeList = [
    [ 0, '未设置' ],
    [ 1, '本人' ],
    [ 2, '代理人' ],
    [ 3, '开发商' ],
]

export const RoomPhoneTypeMap = new Map(RoomPhoneTypeList as any)

export interface IRoomVO extends IWithKey{
    area: number
    building_id: string // "11ec02a3-1144-6606-a952-5396f50a5177"
    can_register: 1 | 2
    cover_image: string // "5"
    day_rent: number // 430 需要除以100
    detail: string // "33333333"
    divide_desc: string// ""
    divisible: 1 | 2
    id: string // "11ec0d90-dbdb-3a3c-b2ad-3090429b4176"
    images: string // "4"
    month_rent: number // 12000
    pay_type: number
    phone: string // "18026306020"
    rent_contain: string // "jjjjjjj"
    room_no: string // "T1-123"
    room_status: string // "12222222222"
    status: number // 0
    title: string // "简介"
    update_time: string // "2021-09-04T23:00:36+08:00"
    city_id: number
    area_id: number
    district_id: number
    city_name: string
    area_name: string
    district_name: string
    district_area?: [number, number, number, string, string, string]
    property_party?: string // 产权方
    phone_name: string // 联系人姓名
    phone_type: string // 联系人类型
    phone_list: string
    create_by_name: string
    create_time: string
    latest_update_name: string
    latest_update_time: string
    owner_name: string
}

export interface IRoomParams extends IPage {
    title: string
    city_id?: number
    area_id?: number
    district_id?: number
    building_id?: string
    status: number
}

export interface IRoomCreateDto {

}

export interface IRoomUpdateDto {
    id: string
}

export const RoomService = {
    list(params: Partial<IRoomParams> = {}) {
        return doPageQuery<IRoomVO>("/room/list", params)
    },
    create(p: IRoomCreateDto) {
        return Post<IExecResult>("/room/create", p)
    },
    update(p: IRoomUpdateDto) {
        return Post<IExecResult>("/room/update", p)
    },
    delete(id: string) {
        return Post<IExecResult>("/room/delete", {id})
    },
    online(id: string) {
        return Post<IExecResult>("/room/online", {id})
    },
    offline(id: string) {
        return Post<IExecResult>("/room/offline", {id})
    },
    // 更新归属
    assignOwner(roomIds: string[], userid: number) {
        let idsList = roomIds.map((item) => {
            return `UUID_TO_BIN('${item}')`
        })

        const sql = `update room set owner = ${userid} where id in (${idsList.join(',')})`

        // console.log(sql)

        return ExecSql(sql)
    }
}