import {AuthService, ICurrSessionInfo} from "../service/AuthService";
import React, {useCallback, useEffect, useState} from "react";
import {RoleService} from "../service/RoleService";
import {IButtonAccess} from "../layout/permission";

const config = {
    debug: true,
    showAllMenu: false
}

interface IGlobalContextInfo {
    currUserInfo?: ICurrSessionInfo
    reloadUserInfo?: () => void
    menuPermission: Record<string, boolean>
    btnPermission: Partial<IButtonAccess<boolean>>
    apiPermission: Record<string, boolean>
    roles: string[]
    config: typeof config
    isLoadingPermission: boolean
}

const defaultContextInfo: IGlobalContextInfo = {
    currUserInfo: undefined,
    menuPermission: {},
    btnPermission: {},
    apiPermission: {},
    roles: [],
    config,
    isLoadingPermission: true
}

export const GlobalContext = React.createContext<IGlobalContextInfo>(defaultContextInfo)

export const AppProvider: React.FC = ({children}) => {
    const [currUserInfo, setCurrUserInfo] = useState<ICurrSessionInfo | undefined>(undefined)
    const [menuPermission, setMenuPermission] = useState<Record<string, boolean>>({})
    const [btnPermission, setBtnPermission] = useState<Record<string, boolean>>({})
    const [apiPermission, setApiPermission] = useState<Record<string, boolean>>({})
    const [roles, setRoles] = useState<string[]>([])
    // 标识权限是否正在加载中
    const [isLoadingPermission, setIsLoadingPermission] = useState<boolean>(true)

    const freshUserInfoCallback = useCallback(() => {
        AuthService.info().then((data) => {
            setCurrUserInfo(data)
        })
    }, [])

    // 获取用户信息
    useEffect(() => {
        freshUserInfoCallback();
    }, [freshUserInfoCallback])

    useEffect(() => {
        if (currUserInfo) {
            setIsLoadingPermission(true)
            RoleService.userRolePermission(currUserInfo.ID).then((data) => {
                // console.log('权限数据', data)
                setMenuPermission(data.menuPermission)
                setBtnPermission(data.btnPermission)
                setApiPermission(data.apiPermission)
                setRoles(data.roles)
            }).finally(() => {
                setIsLoadingPermission(false)
            })
        }
    }, [currUserInfo])

    const val: IGlobalContextInfo = {
        currUserInfo,
        reloadUserInfo: freshUserInfoCallback,
        menuPermission,
        btnPermission,
        apiPermission,
        roles,
        config,
        isLoadingPermission
    }
    return <GlobalContext.Provider value={val}>{children}</GlobalContext.Provider>
}