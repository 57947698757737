import {Button, Form, Input, Modal, Space} from "antd";
import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {CustomerChangeService, ICustomerChangeOptEnum} from "../../../service/CustomerChangeLogService";

interface IProps {
    customer_id: number
    visible: boolean
    onSuccess: () => void
    onCancel: () => void
}

export default function CustomerChangeLogCreateModal(props: IProps) {

    const { customer_id, visible, onCancel, onSuccess } = props

    const [editForm] = useForm()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (visible) {
            editForm.resetFields();
        }
    }, [visible, editForm])

    function doFormFinished(values: {content: string}) {
        setIsSubmitting(true)

        CustomerChangeService.create({ content: values.content, customer_id, opt_type: ICustomerChangeOptEnum.follow }).then(() => {
            onSuccess?.()
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    return (
        <Modal
            forceRender
            title={'填写跟进信息'}
            zIndex={2000}
            visible={visible}
            onCancel={onCancel}
            footer={false}
        >
            <Form form={editForm} layout={'vertical'} onFinish={doFormFinished}>
                <Form.Item
                    label={"跟进信息"}
                    name={'content'} required
                    extra={'最大长度不超过1024个字'}
                    rules={[{required: true, message: '请填写跟进信息'}, { max: 1024, message: '最大长度不超过1024个字' }]}>
                    <Input.TextArea autoSize placeholder={'请填写跟进信息'} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type={'primary'} htmlType={'submit'} loading={isSubmitting}>确认</Button>
                        <Button htmlType={'button'} onClick={onCancel} disabled={isSubmitting}>取消</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
};