import {Button, Form, Input, message, Modal, Table} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {PageContainer} from "../layout/PageContainer";
import {CityCreate, CityDelete, CityEdit, CityList, ICity} from "../service/CityService";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "./common";
import PageCard from "../layout/PageCard";
import HasPermission from "../components/HasPermission/HasPermission";
import {API_DeleteData} from "../components/HasPermission/apiPermission";

export function City() {
    const history = useHistory()
    const [editForm] = Form.useForm<ICity>()
    const columns = useMemo<ColumnsType<ICity>>(() => {
        function doCLickSettingArea(item: ICity) {
            return () => {
                history.push("/area?id="+item.ID)
            }
        }
        function doCLickEdit(item: ICity) {
            return () => {
                setCurr(item)
                setShowEditModal(true)
                setTimeout(() => {
                    editForm.resetFields()
                }, 100)
            }
        }

        function doDelete(item: ICity) {
            return () => {
                Modal.confirm({
                    title: '确定删除',
                    content: '该操作很危险，该操作很危险！',
                    onOk() {
                        CityDelete(item.ID).then(() => {
                            message.success('操作成功')
                            setQuery({})
                        })
                    }
                })
            }
        }

        return [
            { title: '#', dataIndex: "ID", width: 120 },
            { title: '城市', dataIndex: "Name" },
            {
                title: '操作',
                render(t, r) {
                    return <div>
                        <Button type={'link'} size={'small'} onClick={doCLickSettingArea(r)}>设置区域</Button>
                        <Button type={'link'} size={'small'} onClick={doCLickEdit(r)}>编辑</Button>
                        <HasPermission code={API_DeleteData} inApi>
                            <Button type={'link'} size={'small'} danger onClick={doDelete(r)}>删除</Button>
                        </HasPermission>
                    </div>
                }
            }
        ]
    }, [history, editForm])

    const [list, setList] = useState<ICity[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [curr, setCurr] = useState<ICity>()
    const [query, setQuery] = useState<any>();

    useEffect(() => {
        setLoading(true)
        CityList().then((data) => {
            setList(data)
        }).finally(()=> {
            setLoading(false)
        })
    }, [query])

    function doNew() {
       setShowEditModal(true)
       setCurr(undefined)
       setTimeout(() => {
           editForm.resetFields()
       }, 100)
    }
    function doFinish(values: ICity) {
        if (curr?.ID) {
            CityEdit({
                id: curr.ID,
                name: values.Name
            }).then(() => {
                setShowEditModal(false)
                setQuery({})
            })
        } else {
            CityCreate(values.Name).then(() => {
                setShowEditModal(false)
                setQuery({})
            })
        }
    }

    function onEditModalCancel() {
        setShowEditModal(false)
        setCurr(undefined)
    }

    return (
        <PageContainer title={'城市'}>
            <PageCard>
                <Button type={'primary'} onClick={doNew}>新增</Button>
            </PageCard>

            <PageCard>
                <Table
                    tableLayout={'fixed'}
                    bordered
                    rowKey={r => r.ID}
                    columns={columns}
                    dataSource={list}
                    size={'middle'}
                    pagination={false}
                    loading={loading}
                />
            </PageCard>

            <Modal visible={showEditModal} title={!curr ? '新增' : '编辑'} footer={false} onCancel={onEditModalCancel}>
                <Form form={editForm} wrapperCol={wrapperCol} labelCol={labelCol} initialValues={curr} onFinish={doFinish}>
                    <Form.Item name={"Name"} label={'城市名称'}>
                        <Input placeholder={'输入城市名称'} autoFocus/>
                    </Form.Item>
                    <Form.Item wrapperCol={noLabelWrapperCol}>
                        <Button type={'primary'} htmlType={'submit'}>保存</Button>
                        <Button style={formButtonStyle} htmlType={'reset'}>重置</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </PageContainer>
    )
}