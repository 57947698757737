import {useEffect, useState} from "react";
import {Helper} from "../../../utils/Helper";

export function useList<T>(
    query: any,
    api: (params: any) => Promise<any>
) {
    const [list, setList] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState(Helper.getDefaultPage())


    useEffect(() => {
        setLoading(true)
        function doQuery(params: any) {
            api(params).then((data: any) => {
                setList(data.list)
                setPage(data.page)
            }).finally(() => {
                setLoading(false);
            }).catch(() => {});
        }

        doQuery(query)

    }, [query, api])

    return {
        list,
        loading,
        page,
    };
}