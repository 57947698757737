import {Input} from "antd";
import React, {useEffect, useRef, useState} from "react";

type Value = [string?, string?]
interface IProps {
    value?: Value
    onChange?: (val: Value) => void
}
export default function InputBetween({ onChange, value }: IProps) {
    const [v1, setV1] = useState<string>()
    const [v2, setV2] = useState<string>()
    const instanceRef = useRef<Value>()

    function onV1Change(e: React.ChangeEvent<HTMLInputElement>) {
        setV1(e.target.value)
        instanceRef.current = [e.target.value, v2]
        onChange?.(instanceRef.current)
    }

    function onV2Change(e: React.ChangeEvent<HTMLInputElement>) {
        setV2(e.target.value)
        instanceRef.current = [v1, e.target.value]
        onChange?.(instanceRef.current)
    }

    useEffect(() => {
        if (value !== instanceRef.current) {
            if (value) {
                setV1(value[0])
                setV2(value[1])
            } else {
                setV1(undefined)
                setV2(undefined)
            }
        }
    }, [value])

    return <Input.Group>
        <Input style={{width: '40%'}} value={v1} onChange={onV1Change} placeholder={"最小值"} />
        <Input
            style={{
                width: '20%',
                borderLeft: 0,
                borderRight: 0,
                textAlign: 'center',
                pointerEvents: 'none',
                backgroundColor: '#fff'
            }}
            placeholder={'至'}
            disabled
        />
        <Input style={{width: '40%'}} value={v2} onChange={onV2Change} placeholder={"最大值"} />
    </Input.Group>
}