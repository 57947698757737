import {IPage, IWithKey} from "./interface";
import SqlString from 'sqlstring'
import doSqlPageQuery, {doCreateDefaultPageQueryResult} from "./SqlHelper";
import {AuthService} from "./AuthService";
import {ExecSql} from "./r";
import {CURRENT_TIMESTAMP} from "../utils/sql";

export enum CustomerLevel {
    A = 1,
    B = 2,
    C = 3
}

export const CustomerLevelList = [
    [  CustomerLevel.A, 'A'],
    [  CustomerLevel.B, 'B'],
    [  CustomerLevel.C, 'C'],
]

export const CustomerLevelMap = new Map<number, string>(CustomerLevelList as any)

export enum CustomerType {
    Public = 1, // 公客人
    Private = 2, // 私客
}

export const CustomerTypeList = [
    [ CustomerType.Private, '私客' ],
    [ CustomerType.Public, '公客' ]
]

export const CustomerTypeMap = new Map<number, string>(CustomerTypeList as any)

export enum CustomerLevelStatus {
    normal = 1, // 正常状态
    isAuditing = 2 // 正在审核中
}

export enum CustomerTypeStatus {
    normal = 1,
    isAuditing
}

export interface ICustomerListQuery extends IPage {
    phone: string
    name: string
    type: number
    level: number
    owner: number
}

export interface IPhoneVO {
    "name": string,
    "post": string,
    "phone": string
}

// 客户需求信息
export interface IDemandsVO {
    // 客户期望房源的面积
    area?: [string, string] // ['300', '500']
    // 期望区域
    district_area?: [number, number, number, string, string, string]// [1, 1, 15, '北京', '朝阳区', '朝青板块']
    // 价格
    price?: [string, string ]// ['3.5', '4.5']
}

export interface ICustomerVO extends IWithKey {
    id: number
    phone_list: string | IPhoneVO[]
    demands: string | IDemandsVO
    remark: string
    type: CustomerType
    level: CustomerLevel
    create_time: string
    create_by: number
    create_by_name: string
    update_time: string
    update_by: number
    update_by_name: string
    source: string
    owner_name: string
    level_status?: CustomerLevelStatus
    type_status?: CustomerTypeStatus
}

const TABLE_NAME = '`customer`'

export const CustomerService = {
    // 分页查询
    list(query: Partial<ICustomerListQuery>) {
        if (query.type === CustomerType.Private && !query.owner) {
            return doCreateDefaultPageQueryResult<ICustomerVO>()
        }

        const where = []
        let whereStr = ''
        let sql = `select * from ${TABLE_NAME}`

        if (query.phone) {
            where.push(`JSON_CONTAINS(phone_list, JSON_OBJECT('phone', ${SqlString.escape(query.phone)}))`)
        }

        if (query.name) {
            where.push(`JSON_CONTAINS(phone_list, JSON_OBJECT('name', ${SqlString.escape(query.name)}))`)
        }

        if (query.type) {
            where.push(`type = ${SqlString.escape(query.type)}`)
        }
        if (query.level) {
            where.push(`level = ${SqlString.escape(query.level)}`)
        }
        if (query.owner) {
            where.push(`owner = ${SqlString.escape(query.owner)}`)
        }

        if (where.length > 0) {
            whereStr += ' where ' + where.join(' and ') + ' '
        }

        sql += whereStr

        let countSql = `select count(1) as count from ${TABLE_NAME}` + whereStr

        return doSqlPageQuery<ICustomerVO>(sql, countSql, query).then((data) => {
            // 规整数据
            data.list.forEach((item) => {
                // handle phone_list
                if (item.phone_list && typeof item.phone_list === 'string') {
                    try {
                        item.phone_list = JSON.parse(item.phone_list)
                    } catch (e) {
                        item.phone_list = []
                    }
                } else {
                    item.phone_list = []
                }

                // handle demands
                if (item.demands && typeof item.demands === 'string') {
                    try {
                        item.demands = JSON.parse(item.demands)
                    } catch (e) {
                        item.demands = {}
                    }
                } else {
                    item.demands = {}
                }
            })

            return data;
        })
    },

    async update(customer: ICustomerVO) {
        const {ID, Name} = await AuthService.info()
        const phoneList = JSON.stringify(customer.phone_list)
        const demands = JSON.stringify(customer.demands)

        let sql = SqlString.format(`update customer set
            type = ?, level = ?, remark = ?,  source = ?, phone_list = ?, demands = ?,
            update_time = ?, update_by =?, update_by_name = ?
            where id = ?
            `, [
                customer.type, customer.level, customer.remark,   customer.source, phoneList, demands,
                CURRENT_TIMESTAMP, ID, Name,
                customer.id
        ])

        // console.log(sql)

        return ExecSql(sql);
    },

    async add(customer: ICustomerVO) {
        const {ID, Name} = await AuthService.info()
        const phoneList = JSON.stringify(customer.phone_list)
        const demands = JSON.stringify(customer.demands)

        let owner: number | null = ID
        let ownerName: string | null= Name

        if (customer.type === CustomerType.Public) {
            owner = null;
            ownerName = null;
        }

        let sql = SqlString.format(`insert into customer(
            type, level, remark,   source, phone_list, demands,
            owner, owner_name,
            create_time, create_by, create_by_name, 
            update_time, update_by, update_by_name) values (
            ?,?,?, ?,?,?,
            ?,?,
            ?,?,?,
            ?,?,?
            )`, [
            customer.type, customer.level, customer.remark, customer.source, phoneList, demands,
            owner, ownerName,
            CURRENT_TIMESTAMP, ID, Name,
            CURRENT_TIMESTAMP, ID, Name
        ])

        console.log(sql)

        return ExecSql(sql)
    },

    delete(id: number) {
        return ExecSql(`delete from ${TABLE_NAME} where id = ${SqlString.escape(id)}`)
    },

    // 设置为私有
    async setToPrivate(id: number) {
        const {ID, Name} = await AuthService.info()
        const sql = SqlString.format(`update ${TABLE_NAME} 
            set owner = ?, 
            owner_name = ?, 
            type = ${CustomerType.Private},
            update_by = ?, 
            update_by_name = ? where id = ?`, [ID, Name, ID, Name, id])
        return ExecSql(sql)
    },

    // 设置为公有
    async setToPublic(id: number) {
        const {ID, Name} = await AuthService.info()
        const sql = SqlString.format(`update ${TABLE_NAME} set owner = null, owner_name = null, type = ${CustomerType.Public}, update_by = ?, update_by_name = ? where id = ?`, [ID, Name, id])
        return ExecSql(sql)
    },

    // 设置 level 变更正在审核中
    async setLevelIsChangeAudit(params: {customer_id: number}) {
        const {customer_id} = params
        const sql = `update ${TABLE_NAME} set level_status = ${CustomerLevelStatus.isAuditing} where id = ${customer_id}`
        return ExecSql(sql)
    },

    // 设置 type 变更正在审核中
    async setTypeIsChangeAudit(params: {customer_id: number}) {
        const {customer_id} = params
        const sql = `update ${TABLE_NAME} set type_status = ${CustomerTypeStatus.isAuditing} where id = ${customer_id}`
        return ExecSql(sql)
    },


    // 只有审核人员才可以调用的接口
    async setLevel(params: {customer_id: number, level: number}) {
        const { customer_id, level } = params
        const sql = `update ${TABLE_NAME} set level = ${level}, level_status = ${CustomerLevelStatus.normal} where id = ${customer_id}`
        return ExecSql(sql)
    },

    async setType(params: {customer_id: number, type: number}) {
        const { customer_id, type } = params
        const sql = `update ${TABLE_NAME} set type = ${type}, type_status = ${ CustomerTypeStatus.normal } where id = ${customer_id}`
        return ExecSql(sql)
    },

    async setTypeNormal(params: {customer_id: number}) {
        const { customer_id } = params
        const sql = `update ${TABLE_NAME} set type_status = ${ CustomerTypeStatus.normal } where id = ${customer_id}`
        return ExecSql(sql)
    },

    async deleteCustomer(params: {customer_id: number}) {
        const {customer_id} = params
        const sql = `delete from ${TABLE_NAME} where id = ${customer_id}`
        return ExecSql(sql)
    }



}