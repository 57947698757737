import {Form, Select} from "antd";
import {useEffect, useState} from "react";
import {BuildingService, IBuildingVO} from "../../service/BuildingService";
import {FormItemProps} from "antd/lib/form/FormItem";
import {Helper} from "../../utils/Helper";

const doSearch = Helper.debounce(BuildingService.selector, 1000)

export default function BuildingSelector(props: FormItemProps) {
    const [list, setList] = useState<IBuildingVO[]>()

    useEffect(() => {
        handleSearch('');
    }, [])

    function handleSearch(value: string) {
        doSearch((data) => {
            setList(data.list)
        },{name: value.trim()})
    }
    return <Form.Item {...props}>
        <Select placeholder={'输入大厦名称搜索'} allowClear showSearch onSearch={handleSearch} filterOption={false}>
            {
                list?.map((item) => {
                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                })
            }
        </Select>
    </Form.Item>
}