/**
 * 客户列表
 * @constructor
 */
import {PageContainer} from "../../layout/PageContainer";
import {Tabs} from "antd";
import CustomerList from "./CustomerList";
import {CustomerType} from "../../service/CustomerService";
import CustomerInfoChangeAuditList from "./CustomerInfoChangeAuditList";
import useAccess from "../../components/hooks/useAccess";

const { TabPane } = Tabs;
export default function Index() {
    const access = useAccess()
    return <>
        <PageContainer title={'客源'}>
            <Tabs destroyInactiveTabPane defaultActiveKey="1" tabBarStyle={{backgroundColor: "#fff", paddingLeft: 10, paddingRight: 10}}>
                <TabPane tab="私客" key="1">
                    <CustomerList type={CustomerType.Private}/>
                </TabPane>
                <TabPane tab="公客" key="2">
                    <CustomerList type={CustomerType.Public}/>
                </TabPane>
                {
                    access.customer_all ? <TabPane tab="全部" key="3">
                        <CustomerList />
                    </TabPane> : null
                }

                {
                    access.customer_audit ? <TabPane tab="变更审核" key="4">
                        <CustomerInfoChangeAuditList />
                    </TabPane> : null
                }

            </Tabs>
        </PageContainer>
    </>
}