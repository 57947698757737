import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {Button, Form, Input, message, Modal, Table} from "antd";
import React, {useContext, useMemo, useState} from "react";
import {Helper} from "../../utils/Helper";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {BuildingService, IBuildingParams, IBuildingVO} from "../../service/BuildingService";
import { useEffect } from "react";
import BuildingEditModal from "./BuildingEditModal";
import CityAreaDistrict from "../../components/CityAreaDistrict/CityAreaDistrict";
import QueryFormItem from "../../components/QueryFormItem/QueryFormItem";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";
import SelectEmployeeModal from "../Room/SelectEmployeeModal";
import {GlobalContext} from "../../global/GlobalContext";

export default function BuildingList() {
    const [queryForm] = Form.useForm()
    const [query, setQuery] = useState<Partial<IBuildingParams>>({page_no: 1, page_size: 10})
    const [showEditModal, setShowEditModal] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [curr, setCurr] = useState<IBuildingVO>()
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [list, setList] = useState<IBuildingVO[]>([])

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    const [showTransferRoom, setShowTransferRoom] = useState<boolean>(false)
    const [ids, setIds] = useState<string[]>([])

    const {btnPermission} = useContext(GlobalContext)

    const columns = useMemo<ColumnsType<IBuildingVO>>(() => {

        function doEdit(item: IBuildingVO) {
            return () => {
                if (item.city_id && item.area_id && item.district_id) {
                    item.district_area = [item.city_id, item.area_id, item.district_id, item.city_name, item.area_name, item.district_name]
                } else {
                    item.district_area = undefined
                }
                item.latlng = {lat: item.latitude, lng: item.longitude}
                setShowEditModal(true)
                setCurr(item)
            }
        }

        function doDelete(item: IBuildingVO) {
            return () => {
                Modal.confirm({
                    title: '确定删除',
                    content: "对应的房源数据也将会删除，请谨慎操作。",
                    onOk() {
                        BuildingService.delete(item.id).then((res) => {
                            if (res.RowsAffected > 0) {
                                message.success("操作成功").then(() => {})
                                setQuery(q => ({...q}))
                            } else {
                                message.error("操作失败").then(() => {})
                            }
                        })
                    }
                })
            }
        }
        return [
            // {
            //     title: '#ID',
            //     dataIndex: 'id',
            //     width: 320,
            // },
            {
                title: "名称",
                dataIndex: "name",
            },
            {
                title: "总面积",
                dataIndex: "total_area",
                render(t) {
                    return `${t}平方米`
                }
            },
            {
                title: "产权年限",
                dataIndex: "property_year",
                width: 120,
                render(t) {
                    return `${t}年`
                }
            },
            {
                title: "物业",
                dataIndex: "property_company",
            },
            {
                title: "开发商",
                dataIndex: "developer",
            },
            {
                title: "区域",
                render(t, r) {
                    return <span>{r.city_name}/{r.area_name}/{r.district_name}</span>
                }
            },
            {
                title: "地址",
                dataIndex: "address",
            },
            {
                title: '空调开放时间',
                dataIndex: 'air_type',
                render(t) {
                    if (t === 1) {
                        return '24小时制度'
                    } else {
                        return '非24小时制度'
                    }
                }
            },
            {
                title: '层高',
                dataIndex: 'floor_height',
                width: 80,
            },
            {
                title: '创建人',
                dataIndex: 'create_by_name',
                width: 120,
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '最近更新人',
                dataIndex: 'latest_update_name',
                width: 90,
            },
            {
                title: '最近更新时间',
                dataIndex: 'latest_update_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '所属人',
                dataIndex: 'owner_name'
            },
            {
                title: '操作',
                width: 120,
                fixed: 'right',
                render(_,r) {
                    return <div>
                        {/*<Button htmlType={'button'} type={'link'} size={'small'} onClick={doShowDetail(r)}>详情</Button>*/}
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doEdit(r)}>编辑</Button>
                        <HasPermission code={API_DeleteData} inApi>
                            <Button htmlType={'button'} type={'link'} size={'small'} danger onClick={doDelete(r)}>删除</Button>
                        </HasPermission>
                    </div>
                }
            }
        ]
    }, [])

    useEffect(() => {
        setLoading(true)
        BuildingService.list(query).then((res) => {
            // console.log('这里是res')
            res.list.forEach((item) => {
                item.images = Helper.formatToUrlStringArr(item.images)
            })
            setList(res.list)
            setPage(res.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    function onQueryFormFinished(values: any) {
        // console.log(values)
        let params: any = {...values};
        if (params.district_area) {
            const [ city_id, area_id, district_id ] = params.district_area
            params.city_id = city_id || 0;
            params.area_id = area_id || 0;
            params.district_id = district_id || 0;
        }
        setQuery({...params, page_no: 1})
    }

    function doNew() {
        setShowEditModal(true)
        setCurr(undefined)
    }

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    function onEditModalCancel() {
        setShowEditModal(false)
    }

    function onEditModalSuccess() {
        setShowEditModal(false)
        setQuery(q => ({...q}))
    }

    function doSetOwner() {
        // console.log('xxxx',selectedRowKeys);
        const ids = list.filter((item) => {
            return selectedRowKeys.includes(item.key)
        }).map((item) => {
            return item.id
        })

        setShowTransferRoom(true)
        setIds(ids)
    }

    function onSelectChange(_selectedRowKeys: any) {
        setSelectedRowKeys(_selectedRowKeys)
    }

    return <PageContainer title={"大厦&项目"}>
        <PageCard>
            <Button type='primary' ghost onClick={doNew}>新增</Button>
        </PageCard>
        <PageCard isQueryForm>
            <Form form={queryForm} layout='inline' onFinish={onQueryFormFinished} labelCol={{ flex: '80px' }}>
                <QueryFormItem span={2}>
                    <Form.Item label='区域' name='district_area'>
                        <CityAreaDistrict flex={"1 0"}/>
                    </Form.Item>
                </QueryFormItem>
                <QueryFormItem>
                <Form.Item label='大厦名称' name='name'>
                    <Input/>
                </Form.Item>
                </QueryFormItem>
                <QueryFormItem>
                    <Form.Item label='创建人' name='create_by'>
                        <Input placeholder={'输入姓名搜索'} />
                    </Form.Item>
                </QueryFormItem>
                <QueryFormItem width={'auto'} fixedRight={true}>
                    <Form.Item>
                        <Button htmlType='submit' type='primary'>查询</Button>
                        <Button htmlType='reset' style={{marginLeft: 15}}>重置</Button>
                    </Form.Item>
                </QueryFormItem>
            </Form>
        </PageCard>
        <PageCard>
            <HasPermission code="room_re_assign">
                <div style={{marginBottom: 10}}>
                    <Button type={'primary'} disabled={selectedRowKeys.length === 0} onClick={doSetOwner}>房源归属转派</Button>
                </div>
            </HasPermission>
            <Table
                rowSelection={btnPermission.room_re_assign ? {
                    selectedRowKeys,
                    onChange: onSelectChange
                } : undefined}
                tableLayout={'fixed'}
                columns={columns}
                dataSource={list}
                size={'middle'}
                rowKey={'key'}
                pagination={page}
                loading={loading}
                onChange={onTableChange}
                scroll={{x: 1800}}
                bordered
            />
        </PageCard>

        <BuildingEditModal visible={showEditModal} curr={curr} onClose={onEditModalCancel} onSuccess={onEditModalSuccess}/>

        <SelectEmployeeModal
            visible={showTransferRoom}
            ids={ids}
            onCancel={() => {
                setIds([])
                setShowTransferRoom(false)
            }}
            onSuccess={() => {
                setIds([])
                setShowTransferRoom(false)
                setQuery((q) => ({...q}))
            }}
            api={BuildingService.assignOwner}
        />
    </PageContainer>
}