import {IExecResult, IPage, IWithKey} from "./interface";
import SqlString from "sqlstring"
import doSqlPageQuery from "./SqlHelper";
import {ExecQuery, ExecSql, Post} from "./r";

export interface IRoleVO extends IWithKey{
    id: number
    role_name: string
    desc: string
    create_time: string
    latest_update_time: string
    create_by: number
    create_by_name: string
    latest_update_by: number
    latest_update_by_name: string
    menu_permission: string
    btn_permission: string
    api_permission: string
}

export interface IRoleListQuery extends IPage {
    role_name: string
}

export interface IApiPermissionPointVO {
    Name: string
    Permissions: {Code: string, Name: string}[]
}

export interface IUserRoleUpdateVO {
    user_id: number
    role_id: number
    create_by: number
    create_by_name: string
}

export interface IUserRoleVO extends IUserRoleUpdateVO {
    create_time: number
}

export const RoleService = {
    list(query: Partial<IRoleListQuery>) {
        const whereValue = []
        let whereStr = ""
        if (query.role_name) {
            whereValue.push("`role_name` like " + SqlString.escape('%' + query.role_name + '%'))
        }
        let sql = `select * from role`;
        if (whereValue.length > 0) {
            whereStr = " where " + whereValue.join(' and ') + ""
        }

        const countSql = `select count(1) as count from role` + whereStr
        return doSqlPageQuery<IRoleVO>(sql, countSql, query)
    },
    create(vo: IRoleVO) {
        let sql = SqlString.format("insert into role(" +
            "`role_name`, `desc`, " +
            "`create_by`, `create_by_name`, " +
            "`latest_update_by`, `latest_update_by_name`) " +
            "values(" +
            "?,?," +
            "?,?," +
            "?,?)",
            [
                vo.role_name,vo.desc,
                vo.create_by, vo.create_by_name,
                vo.latest_update_by, vo.latest_update_by_name])

        console.log('sql', sql)
        // return Promise.resolve(1)
        return  ExecSql(sql)
    },
    update(vo: IRoleVO) {
        let sql = SqlString.format("update role set `role_name` = ?, `desc` = ?, `latest_update_by` = ?, `latest_update_by_name` = ? where id = ?", [vo.role_name, vo.desc, vo.latest_update_by, vo.latest_update_by_name, vo.id])
        console.log('sql', sql)
        return  ExecSql(sql)
    },

    async delete(id: number) {
        let sql = SqlString.format("delete from role where id = ?", [id])
        let deleteSql = SqlString.format("delete from user_role where role_id = ?", [id])

        try {
            let res1 =await ExecSql(sql)
            if (res1.Error === null) {
                await ExecSql(deleteSql)
            }
            return res1
        } catch (e) {
            return {
                Error: "执行删除任务失败了",
                RowsAffected: 0,
            } as IExecResult
        }
    },

    updatePermission(vo: IRoleVO) {
        let sql = SqlString.format("" +
            "update role set menu_permission = ?, btn_permission = ?, api_permission = ? where id = ?",
            [vo.menu_permission, vo.btn_permission, vo.api_permission, vo.id])
        return ExecSql(sql)
    },

    permissionList() {
        return Post<IApiPermissionPointVO[]>("/permission/permission_point_list")
    },

    userRoleList(userId: number) {
        let sql = SqlString.format("select * from user_role where user_id = ?", [userId])
        return ExecQuery<IUserRoleVO[]>(sql)
    },

    async updateUserRole(obj: IUserRoleUpdateVO[], userId: number) {
        let deleteSql = SqlString.format("delete from user_role where user_id = ?", [userId]);
        try {
            let res1 = await ExecSql(deleteSql)

            if (res1.Error !== null) {
                return res1.Error
            }

            if (obj.length > 0) {
                let insertSql = "insert into user_role(`user_id`, `role_id`, `create_by`, `create_by_name`) values " + (
                    obj.map((item) => {
                        return `(${item.user_id}, ${item.role_id}, ${item.create_by}, ${SqlString.escape(item.create_by_name)})`
                    }).join(" , ")
                )
                let res2 = await ExecSql(insertSql)

                if (res2.Error !== null) {
                    return res2.Error
                }
            }
            return true
        } catch (err) {
            return err
        }
    },

    async userRolePermission(user_id: number) {
        let sql = SqlString.format("select " +
            "ur.role_id" +
            ", role.id" +
            ", role.role_name" +
            ", role.menu_permission " +
            // ", role.btn_permission" +
            ", role.api_permission " +
            "from user_role as ur " +
            "left join role on ur.role_id = role.id " +
            "where ur.user_id = ? ", [user_id])

        let res = await ExecQuery<{id?: number, role_id: number, menu_permission: string, api_permission: string, role_name: string}[]>(sql)
        let menuPermission: Record<string, boolean> = {}
        let btnPermission: Record<string, boolean> = {}
        let apiPermission: Record<string, boolean> = {}
        let roles: string[] = []
        res.forEach((item) => {
            if (!item.id) {
                 return
            }

            roles.push(item.role_name)

            let pArr = item.menu_permission.split(',')
            pArr.forEach((str) => {
                if (str.startsWith("/")) {
                    menuPermission[str] = true
                } else {
                    btnPermission[str] = true
                }
            })

            let apiArr = (item.api_permission || '').split(',')
            apiArr.forEach((str) => {
                apiPermission[str] = true
            })
        })

        return {
            menuPermission,
            btnPermission,
            roles,
            apiPermission
        }
    }
}