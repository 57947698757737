import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {useEffect, useMemo, useState} from "react";
import {
    IUserListParams,
    IUserVO,
    UserService,
    UserStatusListMap,
    UserTpeList,
    UserTpeListMap
} from "../../service/UserService";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {Helper} from "../../utils/Helper";
import {Button, Form, Input, Select} from "antd";
import {Table} from "antd/es";
import SetUserType from "./SetUserType";
import VipHistoryRecord from "./VipHistoryRecord";

export default function UsersList() {
    const [list , setList] = useState<IUserVO[]>([])
    const [page, setPage] = useState<TablePaginationConfig>(Helper.getDefaultPage())
    const [query, setQuery] = useState<Partial<IUserListParams>>({page_no: 1, page_size: 10})
    const [loading, setLoading] = useState<boolean>(false)
    const [queryForm] = Form.useForm()

    const [showSetUserTypeModal, setShowSetUserTypeModal] = useState<boolean>(false)
    const [curr, setCurr] = useState<IUserVO>();

    const [showVipHistoryModal, setShowVipHistoryModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        UserService.list(query).then((data) => {
            setList(data.list)
            setPage(data.page)
        }).finally(()=> {
            setLoading(false)
        })
    }, [query])

    const columns = useMemo<ColumnsType<IUserVO>>(() => {
        function doSetting(item: IUserVO) {
            return () => {
                setCurr(item)
                setShowSetUserTypeModal(true)
            }
        }
        function doVipSettingRecord(item: IUserVO) {
            return () => {
                setCurr(item)
                setShowVipHistoryModal(true)
            }
        }
        return [
            { dataIndex: 'name', title: '姓名' },
            { dataIndex: 'phone', title: '电话号码' },
            { dataIndex: 'email', title: '邮箱' },
            { dataIndex: 'type', title: '用户类型', render(t) {
                let item = UserTpeListMap.get(t);
                if (t === 2) {
                    return <span style={{color: '#f50'}}>{item}</span>
                }
                if (t === 3) {
                    return <span style={{color: 'green'}}>{item}</span>
                }
                return UserTpeListMap.get(t)
            }},
            { dataIndex: 'wechat_openid', title: '微信OPENID' },
            { dataIndex: 'status', title: '状态', render(t) {
                if (t === 1) {
                    return <span style={{color: 'green'}}>{UserStatusListMap.get(t)}</span>
                }
                if (t === 2) {
                    return <span style={{color: 'red'}}>{UserStatusListMap.get(t)}</span>
                }
                return t
            }},
            { dataIndex: 'create_time_str', title: '创建时间',width: 170 },
            { title: '操作', render(t, r) {
                return <>
                    <Button size={'small'} type={'link'} onClick={doSetting(r)}>设置</Button>
                    <Button size={'small'} type={'link'} onClick={doVipSettingRecord(r)}>VIP记录</Button>
                    {/*<Button size={'small'} type={'link'}>编辑</Button>*/}
                    {/*<Button size={'small'} type={'link'} danger>删除</Button>*/}
                </>
                } }
        ]
    }, [])

    function doTableChange(pagination: TablePaginationConfig) {
        setQuery((q) => ({
            ...q,
            page_no: pagination.current,
            page_size: pagination.pageSize,
        }))
    }

    function doQueryFormFinished(values: any) {
        setQuery(q =>({...values, page_no: 1, page_size: q.page_size || 10}))
    }

    return <PageContainer title={'用户管理'}>
        {/*<PageCard>*/}
        {/*    <Button>新增</Button>*/}
        {/*</PageCard>*/}

        <PageCard>
            <Form layout={'inline'} form={queryForm} onFinish={doQueryFormFinished}>
                <Form.Item name={'name'} label={'姓名'}>
                    <Input style={{width: 100}}/>
                </Form.Item>
                <Form.Item name={'phone'} label={'电话号码'}>
                    <Input style={{width: 140}}/>
                </Form.Item>
                <Form.Item name={'type'} label={'用户类型'}>
                    <Select style={{width: 120}} allowClear placeholder={'请选择'}>
                        {
                            UserTpeList.map((item) => {
                                return <Select.Option value={item.type} key={item.type}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'reset'}>重置</Button>
                </Form.Item>
            </Form>
        </PageCard>

        <PageCard>
            <Table
                loading={loading}
                dataSource={list}
                columns={columns}
                pagination={page}
                bordered
                size={'middle'}
                rowKey={'uuid'}
                onChange={doTableChange}
            />
        </PageCard>

        <SetUserType visible={showSetUserTypeModal} curr={curr} onSuccess={() => {
            setQuery({...query})
            setShowSetUserTypeModal(false)
        }} onClose={() => {
            setShowSetUserTypeModal(false)
        }}/>

        <VipHistoryRecord visible={showVipHistoryModal} curr={curr} onClose={() => {
            setShowVipHistoryModal(false)
        }}/>
    </PageContainer>
}