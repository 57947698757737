import {IPage, IWithKey} from "./interface";
import {ExecQuery} from "./r";
import {TablePaginationConfig} from "antd/lib/table/interface";
import {Helper} from "../utils/Helper";


export function getLimit(page: Partial<IPage>) {
    let pageNo = page.page_no || 1
    let pageSize = page.page_size || 10
    return ` limit ${(pageNo - 1) * pageSize}, ${pageNo * pageSize} `
}

export async function doCreateDefaultPageQueryResult<T extends IWithKey>() {
    return Promise.resolve({
        page: {
            total: 0,
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共${total}条数据`
            }
        } as TablePaginationConfig,
        list: [] as T[],
    })
}

export default async function doSqlPageQuery<T extends IWithKey>(listSql: string, countSql: string, query: Partial<IPage>) {
    listSql += getLimit(query)

    let dataList = await ExecQuery<T[]>(listSql)
    let countList = await ExecQuery<{count: number}[]>(countSql)

    let total = countList[0].count
    let pageNo = query.page_no || 1
    let pageSize = query.page_size || 10

    const page: TablePaginationConfig = {
        total: total,
        current: pageNo,
        pageSize: pageSize,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => {
            return `共${total}条数据`
        }
    }

    if (dataList) {
        dataList.forEach((item) => {
            item.key = Helper.genKey()
        })
    }

    return {
        page,
        list: dataList || []
    }
}