import PageCard from "../../layout/PageCard";
import useChangeQuery from "./components/useChangeQuery";
import {useList} from "./components/useList";
import {
    CustomerChangeAuditService,
    EnumCustomerChangeAuditStatus,
    EnumCustomerChangeAuditStatusMap,
    EnumCustomerChangeType,
    EnumCustomerChangeTypeMap,
    ICustomerChangeAuditVO
} from "../../service/CustomerChangeAuditService";
import {Button, Form, Input, message, Modal, Space, Table, Tag} from "antd";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {useCallback, useMemo, useRef, useState} from "react";
import {Helper} from "../../utils/Helper";
import {CustomerLevelMap, CustomerService, CustomerType, IPhoneVO} from "../../service/CustomerService";
import {CustomerChangeService, ICustomerChangeOptEnum} from "../../service/CustomerChangeLogService";

export default function CustomerInfoChangeAuditList() {
    const {query, setQuery, queryForm, reload } = useChangeQuery()

    const { list, page, loading } = useList<ICustomerChangeAuditVO>(query, CustomerChangeAuditService.list)

    const [showAuditModal, setShowAuditModal] = useState(false)

    const [curr, setCurr] = useState<ICustomerChangeAuditVO>()
    const [form] = Form.useForm<{ audit_opinion: string  }>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const instanceRef = useRef<{isSubmitting: boolean}>({isSubmitting: false})

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    const columns = useMemo<ColumnsType<ICustomerChangeAuditVO>>(() => {
        function doAudit(info: ICustomerChangeAuditVO) {
            setShowAuditModal(true)
            setCurr(info)
        }

        return [
            { dataIndex: 'id', title: 'ID', width: 80, align: 'right' },
            { dataIndex: 'customer_id', title: '客户ID', width: 80, align: 'right' },
            { dataIndex: '', title: '客户姓名', width: 240, render(t, r) {
                if (r.phone_list) {
                    const phone_list = JSON.parse(r.phone_list)
                    if (Helper.isArray(phone_list)) {
                        return <>
                            {
                                (phone_list as IPhoneVO[]).map((item, index) => {
                                    return <div key={index}>
                                        {item.name}
                                        {item.phone}
                                        ({item.post})
                                    </div>
                                })
                            }
                        </>
                    }
                }
                return ''
            }},
            { dataIndex: 'type', title: '变更类型', width: 120, render(t) {
                return `${EnumCustomerChangeTypeMap.get(t)}`
                } },
            { dataIndex: 'payload', title: '申请内容', render(t, r) {
                if (r.type === EnumCustomerChangeType.level) {
                    try {
                        let payload = JSON.parse(r.payload)
                        return `${CustomerLevelMap.get(payload.old_level)} -> ${CustomerLevelMap.get(payload.new_level)}`
                    } catch (e) {
                        return '变更客户等级'
                    }
                }
                if (r.type === EnumCustomerChangeType.type) {
                    return '设置为公客'
                }
                return 'todo'
            }},
            { dataIndex: 'reason', title: '申请原因' },

            { dataIndex: 'status', title: '状态', width: 140, render(t) {
                if (t === EnumCustomerChangeAuditStatus.needAudit) {
                    return <Tag color={'gold'}>{EnumCustomerChangeAuditStatusMap.get(t) as string}</Tag>
                }
                if (t === EnumCustomerChangeAuditStatus.pass) {
                    return <Tag color={'green'}>{EnumCustomerChangeAuditStatusMap.get(t) as string}</Tag>
                }
                if (t === EnumCustomerChangeAuditStatus.reject) {
                    return <Tag color={'red'}>{EnumCustomerChangeAuditStatusMap.get(t) as string}</Tag>
                }

                return `${EnumCustomerChangeAuditStatusMap.get(t)}`
            } },
            { dataIndex: 'applicant_name', title: '申请人', width: 120, },

            { dataIndex: 'create_time', title: '申请时间', width: 170, render(t) {
                    return Helper.formatDate(t)
                } },
            { dataIndex: 'audit_opinion', title: '审批意见' },
            { dataIndex: 'auditor_name', title: '审批人', width: 120, },
            { dataIndex: 'audit_time', title: '审核时间', width: 170, render(t) {
                    return Helper.formatDate(t)
                } },
            {
                title: '操作', fixed: 'right', width: 140, render(t, r) {
                    return <div>
                        {
                            r.status === EnumCustomerChangeAuditStatus.needAudit ? <Button type={'link'} size={'small'} onClick={() => doAudit(r)}>审核</Button>: null
                        }
                    </div>
                }
            }
        ];
    }, [])

    const onCancel = useCallback(() => {
        setShowAuditModal(false)
        setCurr(undefined)
        form.resetFields();
    }, [form])

    function doAudit(status: EnumCustomerChangeAuditStatus.pass | EnumCustomerChangeAuditStatus.reject) {
        if (instanceRef.current.isSubmitting) {
            return;
        }

        setIsSubmitting(true)
        instanceRef.current.isSubmitting = true

        async function handleFun() {
            if (!curr) {
                // message.error('请先选择审核记录').then(() => {})
                throw new Error("请先选择审核记录")
            }

            const values = await form.validateFields()
            const {audit_opinion} = values

            const {customer_id, id, payload, reason, type} = curr
            let newLevel: number
            let oldLevel: number
            try {
                const {new_level, old_level} = JSON.parse(payload)
                newLevel = new_level
                oldLevel = old_level
            } catch (e) {
                message.error('申请数据解析失败了, 请联系系统管理员协助处理').then(() => {})
                throw new Error("申请数据解析失败了, 请联系系统管理员协助处理")
            }


            let opt_type
            if (type === EnumCustomerChangeType.level) {
                opt_type = ICustomerChangeOptEnum.changeLevel
            }
            if (type === EnumCustomerChangeType.type) {
                opt_type = ICustomerChangeOptEnum.changeType
            }

            if (opt_type === undefined) {
                throw new Error('不支持的审核类型')
            }


            let res1 = await CustomerChangeAuditService.audit({
                audit_opinion,
                id,
                status
            })
            if (res1.Error != null) {
                throw new Error('审核失败')
            }

            // 审核 客户等级的变更
            if (type === EnumCustomerChangeType.level) {
                // 只有在审核通过的时候才变更 客户的状态
                if (status === EnumCustomerChangeAuditStatus.pass) {
                    let res2 = await CustomerService.setLevel({customer_id, level: newLevel})
                    if (res2.Error !== null) {
                        throw new Error('修改客户状态失败，请联系管理员处理')
                    }

                    const content = `管理员审核： 修改客户等级 ${CustomerLevelMap.get(oldLevel)} -> ${CustomerLevelMap.get(newLevel)}; 修改原因：${reason}; 审核意见：${audit_opinion}`

                    let res3 = await CustomerChangeService.create({ customer_id, content, opt_type })
                    if(res3.Error !== null) {
                        throw new Error('保存变更信息失败，请联系管理员处理')
                    }
                }

                // 拒绝
                if (status === EnumCustomerChangeAuditStatus.reject) {
                    let res2 = await CustomerService.setLevel({customer_id, level: oldLevel})
                    if (res2.Error !== null) {
                        throw new Error('修改客户状态失败，请联系管理员处理')
                    }
                }
            }

            if (type === EnumCustomerChangeType.type) {
                if (status === EnumCustomerChangeAuditStatus.pass) {
                    let res2 = await CustomerService.setType({customer_id, type: CustomerType.Public})
                    if (res2.Error !== null) {
                        throw new Error('设置客户客别信息失败，请联系管理员处理')
                    }

                    const content = `管理员审核：设置客别为公客; 修改原因：${reason}; 审核意见：${audit_opinion}`

                    let res3 = await CustomerChangeService.create({ customer_id, content, opt_type })
                    if(res3.Error !== null) {
                        throw new Error('保存变更信息失败，请联系管理员处理')
                    }
                }

                // 拒绝
                if (status === EnumCustomerChangeAuditStatus.reject) {
                    let res2 = await CustomerService.setTypeNormal({customer_id})
                    if (res2.Error !== null) {
                        throw new Error('修改客户状态失败，请联系管理员处理')
                    }
                }
            }
        }

        handleFun().then(() => {
            setShowAuditModal(false)
            setCurr(undefined)
            reload()
        }).catch((err: Error) => {
            message.error(err.message).then(() => {})
        }).finally(() => {
            setIsSubmitting(false)
            instanceRef.current.isSubmitting = false
        })

    }

    return (
        <>
            <PageCard>
                {queryForm}
            </PageCard>
            <PageCard>
                <Table
                    tableLayout={'fixed'}
                    rowKey={(t) => t.key}
                    dataSource={list}
                    pagination={page}
                    onChange={onTableChange}
                    loading={loading}
                    bordered
                    columns={columns}
                    scroll={{ x: 2000 }}
                />
            </PageCard>

            <Modal visible={showAuditModal} title={'审核'} onCancel={onCancel} footer={false}>
                <Form form={form} layout={'vertical'}>
                    <Form.Item label={'审核意见'} name={'audit_opinion'} rules={[{required: true, message: '请填写审核意见'}]}>
                        <Input.TextArea placeholder={'填写审核意见'}/>
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type={'primary'} loading={isSubmitting} htmlType={'button'} onClick={() => doAudit(EnumCustomerChangeAuditStatus.pass)}>审核通过</Button>
                            <Button danger type={'primary'} loading={isSubmitting} htmlType={'button'} onClick={() => doAudit(EnumCustomerChangeAuditStatus.reject)}>审核不通过</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
