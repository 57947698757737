import {IPage, IWithKey} from "./interface";
import doSqlPageQuery from "./SqlHelper";
import SqlString from "sqlstring";

export interface IUserLikeRoomQuery extends IPage {
    user_name: string
    phone: string
}

export interface IUserLikeRoomVO extends IWithKey{
    id: number
    room_id_str: string
    user_id_str: string
    room_title: string
    room_no: string
}
export const UserLikeRoomService= {
    list(query: Partial<IUserLikeRoomQuery>) {
        const where = []
        let whereStr = ''
        let sql = "select " +
            "u.id, bin_to_uuid(u.room_id) as room_id_str, bin_to_uuid(u.user_id) as user_id_str, " +
            "r.`title` as room_title, r.`room_no` as room_no," +
            "a.name as user_name, a.phone as user_phone " +
            "from user_like_room as u " +
            "left join users as a on a.id = u.user_id " +
            "left join room as r on r.id = u.room_id ";

        if (query.user_name) {
            where.push("`a`.`name` like " + SqlString.escape("%" + query.user_name +"%"))
        }

        if (query.phone) {
            where.push("`a`.`phone` = " + SqlString.escape(query.phone))
        }

        if (where.length > 0) {
            whereStr = ' where ' + where.join(' and ') + ' '
        }

        sql += whereStr

        let countSql = "select count(1) as count from user_like_room as u " +
            "left join users as a on a.id = u.user_id " +
            "left join room as r on r.id = u.room_id " + whereStr

        return doSqlPageQuery<IUserLikeRoomVO>(sql, countSql, query)
    }
}