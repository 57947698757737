import {routes} from "./routes";
import {Menu} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation, matchPath} from "react-router-dom";
import {MenuInfo} from "rc-menu/lib/interface";
import {GlobalContext} from "../global/GlobalContext";

export function SideMenu() {
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const history = useHistory()
    const {menuPermission, config} = useContext(GlobalContext)
    function onMenuClick(info: MenuInfo) {
        // console.log(info)
        history.push(info.key)
        // setSelectedKeys(info.keyPath)
    }

    const location = useLocation()
    useEffect(() => {
        // console.log('------------')
        // console.log(location.pathname)
        setSelectedKeys([location.pathname])
        for (let i = 0; i < routes.length; i++) {
            let match = matchPath(location.pathname, {
                path: routes[i].path,
                exact: true
            })
            // console.log('match', match)
            if (match) {
                setSelectedKeys([routes[i].selectPath || routes[i].path])
                break;
            }
        }
        // function doPopState(event: PopStateEvent) {
        //     console.log('===',event.state, document.location)
        // }
        // window.addEventListener('popstate', doPopState)
        // return () => {
        //     window.removeEventListener('popstate', doPopState)
        // }
    }, [location])

    return (
        <Menu theme="light" mode="inline" selectedKeys={selectedKeys} onClick={onMenuClick}>
            {
                routes.map((item) => {
                    let hasPermission = item.access === undefined || menuPermission[item.path]
                    if (item.visible === false || (!config.showAllMenu && !hasPermission)) {
                        return null
                    }
                    return (
                        <Menu.Item key={item.path} icon={item.icon ? React.createElement(item.icon, {}) : null}>
                            {item.title} {hasPermission ? null: '!'}
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )
}