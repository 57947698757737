import React from "react";

interface IProps {
    condition: boolean
}

const If: React.FC<IProps> = ({condition, children}) => {
    return condition ? <>{children}</>: null
}

export default If;