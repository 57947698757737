import {Button, Result} from "antd";
import {useHistory} from 'react-router-dom'
export default function NoPermission() {
    const history = useHistory()
    return (
        <Result
            status="403"
            title="403"
            subTitle="对不起，你没有权限访问该页面"
            extra={<Button type="primary" onClick={() => history.replace('/')}>返回首页</Button>}/>
    )
}