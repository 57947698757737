import {routes} from "./routes";

export interface IButtonAccess<T> {
    room_re_assign: T;
    room_edit_phone: T;
    setting_banner: T
    setting_config: T
    setting_role: T
    customer_all: T // 查看所有的客源
    customer_audit: T // 审核变更信息
    customer_edit_without_audit: T // 编辑客户信息不需要审核
    customer_edit_phone: T // 编辑电话信息
}

export interface IButtonAccessDetail {
    name: string; // 按钮的名称
    description: string; // 按钮的描述信息
    menuOwner: string; // 按钮的所属菜单页面路径。这里所有的按钮权限，必须要关联到一个菜单下面。主要的目的是为了方便查看
}

const buttonAccessListDetail:IButtonAccess<IButtonAccessDetail> = {
    room_re_assign: {
        name: '房源归属转分配',
        description: '房源归属转分配',
        menuOwner: '/room'
    },
    room_edit_phone: {
      name: '编辑电话',
      description: '',
      menuOwner: '/room'
    },
    setting_banner: {
        name: "首页banner配置",
        description: '',
        menuOwner: "/settings"
    },
    setting_config: {
        name: "业务参数配置",
        description: '',
        menuOwner: "/settings"
    },
    setting_role: {
        name: "角色权限",
        description: '',
        menuOwner: '/settings'
    },
    customer_all: {
        name: '查看全部的客户信息',
        description: '',
        menuOwner: '/customer'
    },

    customer_audit: {
        name: '审核客户信息变更',
        description: '',
        menuOwner: '/customer'
    },
    customer_edit_without_audit: {
        name: '无限制地编辑客户信息',
        description: '可以在任何场景下面编辑客户信息，设置为公客和私客',
        menuOwner: '/customer'
    },
    customer_edit_phone: {
        name: '编辑电话信息',
        description: '编辑电话信息',
        menuOwner: '/customer'
    }
}

export function getButtonAccessKeys(): string[] {
    return Object.keys(buttonAccessListDetail);
}

export function getMenuAccessListV2() {
    const urlBtnsMap = new Map<string, any>();
    getButtonAccessKeys().forEach((item) => {
        // @ts-ignore
        let detail: IButtonAccessDetail = buttonAccessListDetail[item];
        let btnList = urlBtnsMap.get(detail.menuOwner);
        if (btnList === undefined) {
            btnList = [];
            urlBtnsMap.set(detail.menuOwner, btnList);
        }

        btnList.push({
            key: item,
            name: detail.name,
            description: detail.description,
        });
    });

    const menus = find2(routes, (item: any) => item.access);

    function loopMenu(menuItem: IFind2Item) {
        menuItem.btns = urlBtnsMap.get(menuItem.key);
        if (menuItem.children) {
            for (let i = 0; i < menuItem.children.length; i++) {
                loopMenu(menuItem.children[i]);
            }
        }
    }

    for (let i = 0; i < menus.length; i++) {
        loopMenu(menus[i]);
    }

    // console.log('----', menus);

    return menus;
}

interface IFind2Item {
    name: string;
    key: string;
    children?: IFind2Item[];
    btns?: { key: string; name: string; description: string }[];
}

function find2(routes: any, filter: (item: any) => boolean) {
    let result: IFind2Item[] = [];

    function loop(item: any, children: IFind2Item[]) {
        const itemItem = { name: item.title, key: item.path, children: [] };
        if (filter(item)) {
            children.push(itemItem);
        }

        if (item.routes) {
            for (let i = 0; i < item.routes.length; i++) {
                loop(item.routes[i], itemItem.key === '/' ? children : itemItem.children);
            }
        }
    }

    for (let i = 0; i < routes.length; i++) {
        loop(routes[i], result);
    }

    return result;
}
