import {Button, Form, Input, message, Modal, Select} from "antd";
import {IUserVO, UserService, UserTpeList, UserTpeListMap} from "../../service/UserService";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import {useEffect} from "react";

interface IProps {
    visible?: boolean
    onSuccess?: () => void
    curr?: IUserVO,
    onClose?: ()=>void
}

function SetUserTypeForm({visible, curr, onSuccess}: IProps) {
    const [form] = Form.useForm()
    function doFinished(values: any) {
        console.log(values)
        if (curr) {
            UserService.setUserType({ desc: values.desc, type: values.newType, uid: curr.uuid }).then(() => {
                message.success('保存成功了')
                onSuccess?.()
            })
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [visible, curr, form])

    return <Form initialValues={curr} form={form} labelCol={labelCol} wrapperCol={wrapperCol} onFinish={doFinished}>
        <Form.Item name={'oldType'} label={'旧用户类型'}>
            <span className="ant-form-text">{ curr?.type ? UserTpeListMap.get(curr?.type) : '' }</span>
        </Form.Item>

        <Form.Item name={'newType'} label={'新用户类型'} rules={[{  required: true, message: '请选择用户类型'}]}>
            <Select placeholder={'请选择用户类型'}>
                {
                    UserTpeList.map((item) => {
                        return <Select.Option value={item.type} key={item.type}>{item.name}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
        <Form.Item label={'备注信息'} name={'desc'} required rules={[{ required: true, message: '请填写备注信息' }]} extra={'这里一般填写修改修改用户类型的原因，方便日后统计排查'}>
            <Input.TextArea placeholder={'这里填写备注信息'}/>
        </Form.Item>
        <Form.Item wrapperCol={noLabelWrapperCol}>
            <Button htmlType={'submit'} type={'primary'}>保存</Button>
            <Button htmlType={'reset'} style={formButtonStyle}>重置</Button>
        </Form.Item>
    </Form>
}


export default function SetUserType({visible, onClose, onSuccess, curr}: IProps) {
    return <Modal visible={visible} title={'设置用户类型'} footer={false} width={500} onCancel={onClose}>
        <SetUserTypeForm visible={visible} onClose={onClose} onSuccess={onSuccess} curr={curr}/>
    </Modal>
}