import React, {useEffect, useMemo, useState} from "react";
import {IDemandsVO} from "../../../service/CustomerService";
import CityAreaDistrict from "../../../components/CityAreaDistrict/CityAreaDistrict";
import {Button, Form, message, Table, Tag} from "antd";
import QueryFormItem from "../../../components/QueryFormItem/QueryFormItem";
import InputBetween from "../../../components/InputBetween/InputBetween";
import {Helper} from "../../../utils/Helper";
import {IRoomParams, IRoomVO, RoomService, RoomsStatusMap} from "../../../service/RoomService";
import {getPayTypeList} from "../../../service/ConfigService";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import RoomDetail from "../../Room/components/RoomDetail";

interface IProps {
    demands: IDemandsVO
}

export default function CustomerRoomList(props: IProps) {
    const { demands } = props
    const [queryForm] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [list, setList] = useState<IRoomVO[]>([])

    const [payTypeMap, setPayTypeMap] = useState<Record<number, string>>({})

    const [query, setQuery] = useState<Partial<IRoomParams>>(() => {
        let params = handleParams(demands)
        return {...params, page_no: 1, page_size: 10}
    })

    const [showDetail, setShowDetail] = useState(false);
    const [curr, setCurr] = useState<IRoomVO>()

    const columns = useMemo<ColumnsType<IRoomVO>>(() => {
        function doShowDetail(item: IRoomVO) {
            return () => {
                setShowDetail(true)
                setCurr(item)
            }
        }

        return [
            {
                title: "标题",
                dataIndex: "title",
            },
            {
                title: "房间号",
                dataIndex: "room_no",
                width: 180,
            },
            {
                title: "项目&大厦",
                dataIndex: "building_name",
            },
            {
                title: "区域",
                width: 180,
                render(t, r) {
                    return <span>{r.city_name}/{r.area_name}/{r.district_name}</span>
                }
            },
            {
                title: "面积(平方)",
                dataIndex: "area",
                width: 100,
                align: 'right',
            },
            {
                title: "日租金(元/平方/天)",
                dataIndex: "day_rent",
                width: 140,
                align: 'right',
            },
            {
                title: "月租金(元)",
                dataIndex: "month_rent",
                width: 100,
                align: 'right',
            },
            {
                title: "状态",
                dataIndex: "status",
                width: 100,
                align: 'center',
                render(t) {
                    let str = RoomsStatusMap.get(t) as string
                    if (t === 1) {
                        return <Tag color={'orange'}>{str}</Tag>
                    }
                    if (t === 2) {
                        return <Tag color={'green'}>{str}</Tag>
                    }
                    if (t === 3) {
                        return <Tag color={'red'}>{str}</Tag>
                    }
                    return str
                }
            },
            {
                title: "支付方式",
                dataIndex: "pay_type",
                width: 100,
                align: 'center',
                render(t) {
                    return payTypeMap[t]
                }
            },
            {
                title: '创建人',
                dataIndex: 'create_by_name',
                width: 100,
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '最近更新人',
                dataIndex: 'latest_update_name',
                width: 100,
            },
            {
                title: '最近更新时间',
                dataIndex: 'latest_update_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '所属人',
                dataIndex: 'owner_name',
                width: 100
            },
            {
                title: '操作',
                width: 80,
                fixed: 'right',
                render(_, r) {
                    return <div>
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doShowDetail(r)}>详情</Button>
                    </div>
                }
            }
        ]
    }, [payTypeMap])

    useEffect(() => {
        setLoading(true)
        RoomService.list({...query, status: 2}).then((data) => {
            data.list.forEach((item) => {
                item.area = (item.area / 100)
                item.day_rent = (item.day_rent / 100)
                item.month_rent = (item.month_rent / 100)
                item.cover_image = Helper.formatToUrlStringArr(item.cover_image)
                item.images = Helper.formatToUrlStringArr(item.images)
            })
            setList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    useEffect(() => {
        getPayTypeList().then((value) => {
            const val: Record<number, string> = {};
            value.forEach((item) => {
                val[item.value] = item.label
            })
            setPayTypeMap(val)
        })
    }, [])

    useEffect(() => {
        if (demands) {
            console.log('ddd',demands)
            queryForm.setFieldsValue(demands)
            let params = handleParams(demands)
            setQuery({...params, page_no: 1, page_size: 10})
        }
    }, [demands, queryForm])

    function doRest() {
        if (demands) {
            console.log('ddd',demands)
            queryForm.setFieldsValue(demands)
            let params = handleParams(demands)
            setQuery({...params, page_no: 1, page_size: 10})
        } else {
            queryForm.resetFields();
        }
    }

    function handleParams(values: any) {
        const {district_area, area, price} = values
        let params: any = {};

        if (district_area) {
            const [city_id, area_id, district_id] = district_area
            params.city_id = city_id || 0;
            params.area_id = area_id || 0;
            params.district_id = district_id || 0;
        }

        function getNum(s: string | undefined) {
            const numRegx = /^[1-9]\d*(\.\d*[1-9])?$|^0(\.\d*[1-9])?$/
            if (s !== undefined) {
                let ss = s.trim()
                if (numRegx.test(ss)) {
                    // 如果等于0 就设置为1，主要是为了服务端的查询方便
                    return {num: (parseFloat(ss) * 100 | 0) || 1, err: null}
                } else if (ss !== '') {
                    return {num: undefined, err: "不是数字"}
                }
            }
            return {num: undefined, err: null}
        }

        if (area) {
            const [s, e] = area
            let {num, err} = getNum(s)
            if (err) {
                message.warn("面积的最小值不是一个数字")
            }
            if (num !== undefined) {
                params.area_start = num
            }

            let {num: num1, err: err1} = getNum(e);
            if (err1) {
                message.warn("面积的最大值不是一个数字")
            }

            if (num1 !== undefined) {
                params.area_end = num1
            }
        }

        if (price) {
            const [s, e] = price
            let {num, err} = getNum(s)
            if (err) {
                message.warn("价格的最小值不是一个数字")
            }
            if (num !== undefined) {
                params.price_start = num
            }

            let {num: num1, err: err1} = getNum(e);
            if (err1) {
                message.warn("价格的最大值不是一个数字")
            }

            if (num1 !== undefined) {
                params.price_end = num1
            }
        }
        return params
    }

    function onQueryFormFinished(values: any) {
        const params = handleParams(values);
        setQuery({...params, page_no: 1}) // 只是查询上架的
    }

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    return (
        <div>
            <div>
                <Form form={queryForm} layout='inline' onReset={doRest} onFinish={onQueryFormFinished} labelCol={{flex: '80px'}}>
                    <QueryFormItem span={2}>
                        <Form.Item label='区域' name='district_area'>
                            <CityAreaDistrict flex={"1 0"}/>
                        </Form.Item>
                    </QueryFormItem>
                    <QueryFormItem>
                        <Form.Item label='价格' name='price'>
                            <InputBetween/>
                        </Form.Item>
                    </QueryFormItem>

                    <QueryFormItem>
                        <Form.Item label='面积' name='area'>
                            <InputBetween/>
                        </Form.Item>
                    </QueryFormItem>

                    <QueryFormItem width={'auto'} fixedRight={true}>
                        <Form.Item>
                            <Button htmlType='submit' type='primary'>查询</Button>
                            <Button htmlType='reset' style={{marginLeft: 15}}>重置</Button>
                        </Form.Item>
                    </QueryFormItem>

                </Form>
            </div>
            <div>
                <Table
                    tableLayout={'fixed'}
                    columns={columns}
                    dataSource={list}
                    size={'middle'}
                    rowKey={'key'}
                    pagination={page}
                    loading={loading}
                    onChange={onTableChange}
                    scroll={{x: 2000}}
                    bordered
                />
            </div>
            <RoomDetail visible={showDetail} room={curr} onClose={() => {
                setShowDetail(false)
                setCurr(undefined)
            }}/>
        </div>
    );
}