import {Modal, Table} from "antd";
import {useEffect, useState} from "react";
import {IUserVO, UserService, UserTpeListMap} from "../../service/UserService";

interface IProps {
    visible: boolean
    onClose?: () => void
    curr?: IUserVO
}

export default function VipHistoryRecord({curr, onClose, visible}: IProps) {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (curr && visible) {
            setLoading(true)
            UserService.vipHistoryList(curr.uuid).then((data) => {
                setList(data as any)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [visible, curr])

    return <Modal visible={visible} title={`${curr?.name}的用户记录`} onCancel={onClose} width={720} footer={false}>
        <Table rowKey={'id'}
               pagination={false} size={'middle'} dataSource={list} loading={loading}>
            <Table.Column dataIndex={'type'} title={'用户类型'} render={(t) => {
                return UserTpeListMap.get(t)
            }}/>
            <Table.Column dataIndex={'create_by_name'} title={'操作人'}/>
            <Table.Column dataIndex={'create_time_str'} title={'操作时间'} width={170}/>
            <Table.Column dataIndex={'desc'} title={'备注信息'} ellipsis width={300} render={(t) => {
                return <span title={t} style={{cursor: 'pointer'}}>{t}</span>
            }}/>
        </Table>
    </Modal>
}