import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {Button, Form, Input, message, Modal, Select, Table, Tag} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Helper} from "../../utils/Helper";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {IRoomParams, IRoomVO, RoomService, RoomsStatusMap, RoomStatusList} from "../../service/RoomService";
import RoomEditModal from "./RoomEditModal";
import CityAreaDistrict from "../../components/CityAreaDistrict/CityAreaDistrict";
import BuildingSelector from "../../components/BuildingSelector/BuildingSelector";
import {getPayTypeList} from "../../service/ConfigService";
import QueryFormItem from "../../components/QueryFormItem/QueryFormItem";
import InputBetween from "../../components/InputBetween/InputBetween";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";
import SelectEmployeeModal from "./SelectEmployeeModal";
import ChangeLogModal from "./components/ChangeLogModal";
import {GlobalContext} from "../../global/GlobalContext";
import RoomDetail from "./components/RoomDetail";
import {IRoomChangeOptEnum} from "../../service/RoomChangeLogService";
import RoomChangeLogInfo from "./components/RoomChangeLogInfo";

export function RoomList() {
    const [queryForm] = Form.useForm()
    const [query, setQuery] = useState<Partial<IRoomParams>>({page_no: 1, page_size: 10})
    const [showEditModal, setShowEditModal] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [curr, setCurr] = useState<IRoomVO>()
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [list, setList] = useState<IRoomVO[]>([])
    const [payTypeMap, setPayTypeMap] = useState<Record<number, string>>({})

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    const [showTransferRoom, setShowTransferRoom] = useState<boolean>(false)
    const [ids, setIds] = useState<string[]>([])

    const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false)
    const [showChangeHistory, setShowChangeHistory] = useState<boolean>(false)
    const [showDetail, setShowDetail] = useState(false);

    const {btnPermission} = useContext(GlobalContext)

    useEffect(() => {
        getPayTypeList().then((value) => {
            const val: Record<number, string> = {};
            value.forEach((item) => {
                val[item.value] = item.label
            })
            setPayTypeMap(val)
        })
    }, [])
    const columns = useMemo<ColumnsType<IRoomVO>>(() => {
        function doEdit(item: IRoomVO) {
            return () => {
                if (item.city_id && item.area_id && item.district_id) {
                    item.district_area = [item.city_id, item.area_id, item.district_id, item.city_name, item.area_name, item.district_name]
                } else {
                    item.district_area = undefined
                }

                setCurr(item)
                setShowEditModal(true)
            }
        }

        function doChangeHistory(item: IRoomVO) {
            return () => {
                setShowChangeHistory(true)
                setCurr(item)
            }
        }

        function doShowDetail(item: IRoomVO) {
            return () => {
                setShowDetail(true)
                setCurr(item)
            }
        }

        function doOnline(item: IRoomVO) {
            return () => {
                Modal.confirm({
                    title: '确认上架',
                    content: '操作后，用户可以在前台网站上看得见该房源信息',
                    onOk() {
                        RoomService.online(item.id).then((data) => {
                            if (data.Error === null) {
                                message.success("操作成功")
                                setQuery(q => ({...q}))
                            } else {
                                message.error("操作失败")
                            }
                        })
                    }
                })
            }
        }

        function doOffline(item: IRoomVO) {
            return () => {
                setShowOfflineModal(true)
                setCurr(item)
                console.log('334234')

                /*Modal.confirm({
                    title: '确认下架',
                    content: '操作后，用户在前台网站上看不见该房源信息',
                    onOk() {
                        RoomService.offline(item.id).then((data) => {
                            if (data.Error === null) {
                                message.success("操作成功")
                                setQuery(q => ({...q}))
                            } else {
                                message.error("操作失败")
                            }
                        })
                    }
                })*/
            }
        }

        function doDelete(item: IRoomVO) {
            return () => {
                Modal.confirm({
                    title: '确定删除',
                    content: "改操作不可逆，请谨慎操作",
                    onOk() {
                        RoomService.delete(item.id).then((res) => {
                            if (res.RowsAffected > 0) {
                                message.success("操作成功")
                                setQuery(q => ({...q}))
                            } else {
                                message.error("操作失败")
                            }
                        })
                    }
                })
            }
        }

        return [
            // {
            //     title: '#ID',
            //     dataIndex: 'id',
            //     width: 320,
            //     hidden: true,
            // },
            {
                title: "标题",
                dataIndex: "title",
            },
            {
                title: "房间号",
                dataIndex: "room_no",
                width: 180,
            },
            {
                title: "项目&大厦",
                dataIndex: "building_name",
            },
            {
                title: "区域",
                width: 180,
                render(t, r) {
                    return <span>{r.city_name}/{r.area_name}/{r.district_name}</span>
                }
            },
            {
                title: "面积(平方)",
                dataIndex: "area",
                width: 100,
            },
            {
                title: "日租金(元/平方/天)",
                dataIndex: "day_rent",
                width: 140,
            },
            {
                title: "月租金(元)",
                dataIndex: "month_rent",
                width: 100,
            },
            {
                title: "状态",
                dataIndex: "status",
                width: 100,
                render(t) {
                    let str = RoomsStatusMap.get(t) as string
                    if (t === 1) {
                        return <Tag color={'orange'}>{str}</Tag>
                    }
                    if (t === 2) {
                        return <Tag color={'green'}>{str}</Tag>
                    }
                    if (t === 3) {
                        return <Tag color={'red'}>{str}</Tag>
                    }
                    return str
                }
            },
            {
                title: "支付方式",
                dataIndex: "pay_type",
                width: 100,
                render(t) {
                    return payTypeMap[t]
                }
            },
            // {
            //     title: '电话号码',
            //     dataIndex: 'phone',
            //     width: 140,
            // },
            {
                title: '创建人',
                dataIndex: 'create_by_name',
                width: 100,
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '最近更新人',
                dataIndex: 'latest_update_name',
                width: 100,
            },
            {
                title: '最近更新时间',
                dataIndex: 'latest_update_time',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                title: '所属人',
                dataIndex: 'owner_name',
                width: 100
            },
            {
                title: '操作',
                width: 180,
                fixed: 'right',
                render(_, r) {
                    return <div>
                        {
                            r.status === 1 || r.status === 3 ? <Button htmlType={'button'} type={'link'} size={'small'}
                                                                       onClick={doOnline(r)}>上架</Button> : null
                        }
                        {
                            r.status === 2 ? <Button htmlType={'button'} type={'link'} size={'small'} danger
                                                     onClick={doOffline(r)}>下架</Button> : null
                        }
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doEdit(r)}>编辑</Button>
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doChangeHistory(r)}>跟进信息</Button>
                        <Button htmlType={'button'} type={'link'} size={'small'} onClick={doShowDetail(r)}>详情</Button>
                        <HasPermission code={API_DeleteData}>
                            <Button htmlType={'button'} type={'link'} size={'small'} danger
                                    onClick={doDelete(r)}>删除</Button>
                        </HasPermission>
                    </div>
                }
            }
        ]
    }, [payTypeMap])

    useEffect(() => {
        setLoading(true)
        RoomService.list(query).then((data) => {
            data.list.forEach((item) => {
                item.area = (item.area / 100)
                item.day_rent = (item.day_rent / 100)
                item.month_rent = (item.month_rent / 100)
                item.cover_image = Helper.formatToUrlStringArr(item.cover_image)
                item.images = Helper.formatToUrlStringArr(item.images)
            })
            setList(data.list)
            setPage(data.page)
            setSelectedRowKeys([])
            setIds([])
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    const doEditModalClose = useCallback((fresh?: boolean) => {
        setShowEditModal(false)
        if (fresh) {
            setQuery((q) => ({...q}))
        }
    }, [])

    function onQueryFormFinished(values: any) {
        const {building_id, district_area, title, status, area, price, phone, room_no, create_by} = values || {}
        let params: any = {};
        if (building_id) {
            params.building_id = building_id
        }
        if (district_area) {
            const [city_id, area_id, district_id] = district_area
            params.city_id = city_id || 0;
            params.area_id = area_id || 0;
            params.district_id = district_id || 0;
        }

        if (title) {
            params.title = title
        }

        if (phone) {
            params.phone = phone
        }
        if (room_no) {
            params.room_no = room_no
        }

        if (create_by) {
            params.create_by = create_by
        }

        function getNum(s: string | undefined) {
            const numRegx = /^[1-9]\d*(\.\d*[1-9])?$|^0(\.\d*[1-9])?$/
            if (s !== undefined) {
                let ss = s.trim()
                if (numRegx.test(ss)) {
                    // 如果等于0 就设置为1，主要是为了服务端的查询方便
                    return {num: (parseFloat(ss) * 100 | 0) || 1, err: null}
                } else if (ss !== '') {
                    return {num: undefined, err: "不是数字"}
                }
            }
            return {num: undefined, err: null}
        }

        if (area) {
            const [s, e] = area
            let {num, err} = getNum(s)
            if (err) {
                message.warn("面积的最小值不是一个数字")
            }
            if (num !== undefined) {
                params.area_start = num
            }

            let {num: num1, err: err1} = getNum(e);
            if (err1) {
                message.warn("面积的最大值不是一个数字")
            }

            if (num1 !== undefined) {
                params.area_end = num1
            }
        }

        if (price) {
            const [s, e] = price
            let {num, err} = getNum(s)
            if (err) {
                message.warn("价格的最小值不是一个数字")
            }
            if (num !== undefined) {
                params.price_start = num
            }

            let {num: num1, err: err1} = getNum(e);
            if (err1) {
                message.warn("价格的最大值不是一个数字")
            }

            if (num1 !== undefined) {
                params.price_end = num1
            }
        }
        console.log(params)
        setQuery({...params, page_no: 1, status})
    }

    function doNew() {
        setShowEditModal(true)
        setCurr(undefined)
    }

    function onTableChange(pagination: TablePaginationConfig) {
        setQuery(q => ({...q, page_no: pagination.current, page_size: pagination.pageSize}))
    }

    function onSelectChange(_selectedRowKeys: any) {
        // console.log('selectedRowKeys changed: ', _selectedRowKeys);
        setSelectedRowKeys(_selectedRowKeys)
    }

    function doSetOwner() {
        // console.log('xxxx',selectedRowKeys);
        const ids = list.filter((item) => {
            return selectedRowKeys.includes(item.key)
        }).map((item) => {
            return item.id
        })

        setShowTransferRoom(true)

        // console.log(ids)
        setIds(ids)
    }

    return <PageContainer title={'房源'}>
        <PageCard>
            <Button type='primary' ghost onClick={doNew}>新增</Button>
        </PageCard>
        <PageCard isQueryForm>
            <Form form={queryForm} layout='inline' onFinish={onQueryFormFinished} labelCol={{flex: '80px'}}>
                <QueryFormItem span={2}>
                    <Form.Item label='区域' name='district_area'>
                        <CityAreaDistrict flex={"1 0"}/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='标题' name='title'>
                        <Input placeholder={'输入标题查询'}/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <BuildingSelector label={'大厦'} name={'building_id'}/>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='电话号码' name='phone'>
                        <Input placeholder={'输入电话号码'}/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='房间号' name='room_no'>
                        <Input placeholder={'输入房间号查询'}/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='价格' name='price'>
                        <InputBetween/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='面积' name='area'>
                        <InputBetween/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label={'状态'} name={"status"}>
                        <Select placeholder={'请选择'}>
                            {
                                RoomStatusList.map((item) => {
                                    return <Select.Option value={item[0]} key={item[0]}>{item[1]}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem>
                    <Form.Item label='创建人' name='create_by'>
                        <Input placeholder={'输入姓名搜索'}/>
                    </Form.Item>
                </QueryFormItem>

                <QueryFormItem width={'auto'} fixedRight={true}>
                    <Form.Item>
                        <Button htmlType='submit' type='primary'>查询</Button>
                        <Button htmlType='reset' style={{marginLeft: 15}}>重置</Button>
                    </Form.Item>
                </QueryFormItem>
            </Form>
        </PageCard>

        <PageCard>
            <HasPermission code={"room_re_assign"}>
                <div style={{marginBottom: 10}}>
                    <Button type={'primary'} disabled={selectedRowKeys.length === 0} onClick={doSetOwner}>房源归属转派</Button>
                </div>
            </HasPermission>
            <Table
                rowSelection={ btnPermission.room_re_assign ? {
                    selectedRowKeys,
                    onChange: onSelectChange
                } : undefined}
                tableLayout={'fixed'}
                columns={columns}
                dataSource={list}
                size={'middle'}
                rowKey={'key'}
                pagination={page}
                loading={loading}
                onChange={onTableChange}
                scroll={{x: 2200}}
                bordered
            />
        </PageCard>
        <RoomEditModal visible={showEditModal} curr={curr} onClose={doEditModalClose}/>

        <SelectEmployeeModal
            visible={showTransferRoom}
            ids={ids}
            onCancel={() => {
                setIds([])
                setShowTransferRoom(false)
            }}
            onSuccess={() => {
                setIds([])
                setShowTransferRoom(false)
                setQuery((q) => ({...q}))
            }}
            api={RoomService.assignOwner}
        />

        <ChangeLogModal
            optType={IRoomChangeOptEnum.offline}
            visible={showOfflineModal}
            room={curr}
            onCancel={() => {
                setShowOfflineModal(false)
            }}
            onSuccess={() => {
                setQuery(q => ({...q}))
                setShowOfflineModal(false)
            }}
        />

        <RoomDetail visible={showDetail} room={curr} onClose={() => {
            setShowDetail(false)
            setCurr(undefined)
        }}/>

        <RoomChangeLogInfo
            onClose={() => {
                setShowChangeHistory(false)
            }}
            visible={showChangeHistory}
            room={curr}
        />
    </PageContainer>
}