import { Drawer, Table, Button, Modal, Form, Input, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CooperationService,
  ICooperationTypeVO,
} from "../../service/CooperationService";
import {
  formButtonStyle,
  labelCol,
  noLabelWrapperCol,
  wrapperCol,
} from "../common";

interface IEditFormProps {
  visible: boolean;
  curr?: ICooperationTypeVO;
  doSubmit: (value: ICooperationTypeVO) => void;
}

function EditForm({ visible, curr, doSubmit }: IEditFormProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible, curr, form]);

  function doFormFinished(values: any) {
    doSubmit(values);
  }

  return (
    <Form
      form={form}
      initialValues={curr}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      onFinish={doFormFinished}
    >
      <Form.Item
        label="类型名称"
        name="name"
        rules={[
          { required: true, message: "该项必填" },
          { max: 100, message: "长度不超过100个字" },
        ]}
        extra="该项必填，并且不能重复"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="描述信息"
        name="desc"
        extra="描述备注信息"
        rules={[{ max: 200, message: "长度不超过200个字" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={noLabelWrapperCol}>
        <Button type="primary" htmlType="submit">
          保存
        </Button>
        <Button htmlType="reset" style={formButtonStyle}>
          重置
        </Button>
      </Form.Item>
    </Form>
  );
}

interface ICooperationTypeDrawProps {
  visible: boolean;
  onClose?: () => void;
}

export default function CooperationTypeDraw({
  visible,
  onClose,
}: ICooperationTypeDrawProps) {
  const [showEditModal, setSHowEditModal] = useState<boolean>(false);
  const [list, setList] = useState<ICooperationTypeVO[]>();
  const [curr, setCurr] = useState<ICooperationTypeVO>();
  const [loading, setLoading] = useState<boolean>(false);
  const instanceRef = useRef<{ isSubmitting: boolean }>({
    isSubmitting: false,
  });

  const getTypeList = useCallback(() => {
    setLoading(true);
    CooperationService.typeList()
      .then((data) => {
        // console.log(data)
        setList(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getTypeList();
  }, [getTypeList]);

  function doNew() {
    setSHowEditModal(true);
    setCurr(undefined);
  }

  useEffect(() => {}, []);

  function doClose() {
    onClose?.();
  }

  function doEdit(item: ICooperationTypeVO) {
    return () => {
      setCurr(item);
      setSHowEditModal(true);
    };
  }

  function doDelete(item: ICooperationTypeVO) {
    return () => {
      Modal.confirm({
        title: "确认删除",
        onOk() {
          CooperationService.deleteType(item).then((data) => {
            if (data.Error === null) {
              message.success("删除成功");
              getTypeList();
              setSHowEditModal(false);
            } else {
              message.error(data.Error);
            }
          });
        },
      });
    };
  }

  function doSubmit(values: any) {
    if (instanceRef.current.isSubmitting) {
      message.warn("不要重复提交表单哦");
      return;
    }
    instanceRef.current.isSubmitting = true;
    if (curr) {
      CooperationService.updateType({ ...curr, ...values })
        .then((data) => {
          if (data.Error === null) {
            message.success("更新成功");
            getTypeList();
            setSHowEditModal(false);
          } else {
            message.error(data.Error);
          }
        })
        .finally(() => {
          instanceRef.current.isSubmitting = false;
        });
    } else {
      const { name, desc } = values;
      CooperationService.newType(name, desc)
        .then((data) => {
          if (data.Error === null) {
            message.success("添加成功");
            getTypeList();
            setSHowEditModal(false);
          } else {
            message.error(data.Error);
          }
        })
        .finally(() => {
          instanceRef.current.isSubmitting = false;
        });
    }
  }

  return (
    <Drawer visible={visible} title="类型列表" onClose={doClose} width={700}>
      <div>
        <div style={{ marginBottom: 15 }}>
          <Button onClick={doNew}>新增</Button>
        </div>
        <Table
          dataSource={list}
          size="middle"
          pagination={false}
          rowKey="id"
          loading={loading}
        >
          <Table.Column dataIndex="id" title="#ID" />
          <Table.Column dataIndex="name" title="类型名称" />
          <Table.Column dataIndex="desc" title="描述信息" />
          <Table.Column<ICooperationTypeVO>
            dataIndex="desc"
            title="编辑"
            render={(t, r) => {
              return (
                <>
                  <Button
                    htmlType="button"
                    type="link"
                    size="small"
                    onClick={doEdit(r)}
                  >
                    编辑
                  </Button>
                  <Button type="link" danger size="small" onClick={doDelete(r)}>
                    删除
                  </Button>
                </>
              );
            }}
          />
        </Table>
      </div>
      <Modal
        footer={false}
        visible={showEditModal}
        onCancel={() => {
          setSHowEditModal(false);
        }}
        title={curr ? "编辑类型" : "新增类型"}
      >
        <EditForm visible={showEditModal} curr={curr} doSubmit={doSubmit} />
      </Modal>
    </Drawer>
  );
}
