import {Descriptions} from "antd";
import {useEffect, useState} from "react";
import {AuthService, ICurrSessionInfo} from "../../../service/AuthService";

export default function UserInfo() {

    const [info, setInfo] = useState<ICurrSessionInfo>()

    useEffect(() => {
        AuthService.info().then((data) => {
            setInfo(data)
        })
    }, [])

    return <div>
        <Descriptions title="用户信息">
            <Descriptions.Item label={'用户ID'}>{info?.ID}</Descriptions.Item>
            <Descriptions.Item label={'用户名'}>{info?.Name}</Descriptions.Item>
            <Descriptions.Item label={'登录名'}>{info?.LoginName}</Descriptions.Item>
            <Descriptions.Item label={'手机号'}>{info?.Phone}</Descriptions.Item>
            <Descriptions.Item label={'邮箱'}>{info?.Email}</Descriptions.Item>
        </Descriptions>
    </div>
}