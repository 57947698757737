import {PageContainer} from "../../layout/PageContainer";
import PageCard from "../../layout/PageCard";
import {Button, Form, Input, Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import {
    EntrustService,
    EntrustStatusList, EntrustStatusListMap, EntrustTypeEnumList,
    EntrustTypeListMap,
    IEntrustListQuery,
    IEntrustVO
} from "../../service/EntrustService";
import {Helper} from "../../utils/Helper";
import {Table} from "antd/es";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import HandleEntrustModal from "./HandleEntrustModal";

export default function EntrustList() {
    const [form] = Form.useForm()
    const [query, setQuery] = useState<Partial<IEntrustListQuery>>({ page_size: 10, page_no: 1 })
    const [list, setList] = useState<IEntrustVO[]>([])
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [loading, setLoading] = useState<boolean>(false)

    const [curr, setCurr] = useState<IEntrustVO>()
    const [showHandleModal, setShowHandleModal] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        EntrustService.list(query).then((data) => {
            setList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        })
    }, [query])

    const columns = useMemo<ColumnsType<IEntrustVO>>(() => {
        function doHandle(item: IEntrustVO) {
            return () => {
                setShowHandleModal(true)
                setCurr(item)
            }
        }
        return [
            { dataIndex: 'user_name', title: '客户姓名', width: 100 },
            { dataIndex: 'phone', title: '电话号码', width: 120 },
            { dataIndex: 'email', title: '邮箱', width: 190,},
            { dataIndex: 'type', title: '类型', width: 100, render(t) {
                return EntrustTypeListMap.get(t)
                } },
            { dataIndex: 'ip', title: 'IP', width: 140, },
            { dataIndex: 'detail', title: '内容详情' },
            { dataIndex: 'create_time_str', title: '提交时间', width: 170 },
            { dataIndex: 'status', title: '状态', width: 90, render(t) {
                return EntrustStatusListMap.get(t)
                } },
            { dataIndex: 'last_update_by_name', title: '处理人', width: 90, },
            { dataIndex: 'update_time_str', title: '处理时间' , width: 170 },
            { dataIndex: 'remarks', title: '处理备注信息' },
            { title: '操作', width: 80, fixed: 'right', render(t, r) {
                return <>
                    <Button htmlType={'button'} type={'link'} size={'small'} onClick={doHandle(r)}>处理</Button>
                </>
            } }
        ]
    }, [])

    function doQueryFormFinished(values: any) {
        setQuery(q => ({...q, ...values, page_no: 1 }))
    }

    function doTableChange(pagination: TablePaginationConfig) {
        setQuery((q) => ({
            ...q,
            page_no: pagination.current,
            page_size: pagination.pageSize,
        }))
    }

    return <PageContainer title={'委托找房&放盘'}>
        <PageCard>
            <Form layout={'inline'} form={form} onFinish={doQueryFormFinished}>
                <Form.Item name={'user_name'} label={"客户姓名"}>
                    <Input />
                </Form.Item>
                <Form.Item name={'phone'} label={"客户电话"}>
                    <Input />
                </Form.Item>
                <Form.Item name={'type'} label={"类型"}>
                    <Select style={{width: 120}} allowClear placeholder={'请选择'}>
                        {
                            EntrustTypeEnumList.map((item) => {
                                return <Select.Option value={item.type} key={item.type}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item name={'status'} label={"状态"}>
                    <Select style={{width: 120}} allowClear placeholder={'请选择'}>
                        {
                            EntrustStatusList.map((item) => {
                                return <Select.Option value={item.type} key={item.type}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type={'primary'} htmlType={'submit'}>查询</Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'reset'}>重置</Button>
                </Form.Item>
            </Form>
        </PageCard>
        <PageCard>
            <Table
                loading={loading}
                dataSource={list}
                columns={columns}
                pagination={page}
                bordered
                size={'middle'}
                rowKey={'id'}
                onChange={doTableChange}
                scroll={{x: 1780}}
            />
        </PageCard>
        <HandleEntrustModal
            visible={showHandleModal}
            curr={curr}
            onSuccess={() => {
                setQuery((q) => ({...q}))
                setShowHandleModal(false)
            }}
            onClose={() => {
                setShowHandleModal(false)
            }}
        />
    </PageContainer>
}