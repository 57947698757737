import {Col, Row, Select} from "antd";
import {useEffect, useRef, useState} from "react";
import {CityList, ICity} from "../../service/CityService";
import {AreaList, IArea} from "../../service/AreaService";
import {DistrictList, IDistrictVO} from "../../service/DistrictService";

type Value = [number?, number?, number?, string?, string?, string?]

interface IProps {
    onChange?: (values: Value) => void
    value?: Value
    flex?: string | number
}

enum Status {
    ready = 0,
    initIng = 1,
}

async function loadInitData(c1: number | undefined, a1 : number | undefined) {
    let areaList: IArea[] = []
    let districtList: IDistrictVO[] = []
    if (c1) {
        areaList = await AreaList(c1)
        if (a1) {
            districtList = (await DistrictList({ page_size: 500, page_no: 1, area_id: a1, city_id: c1 })).list
        }
    }

    return {
        areaList,
        districtList
    }
}

export default function CityAreaDistrict({onChange, value, flex}: IProps) {
    const [city, setCity] = useState<number>()
    const [area, setArea] = useState<number>()
    const [district, setDistrict] = useState<number>()

    const [cityList, setCityList] = useState<ICity[]>([])
    const [areaList, setAreaList] = useState<IArea[]>([])
    const [districtList, setDistrictList] = useState<IDistrictVO[]>([])

    const localRef = useRef<{localVal?: Value}>({localVal: undefined})
    const changRef = useRef<typeof onChange>(onChange)

    const statusRef = useRef<Number>(Status.ready)

    useEffect(() => {
        changRef.current = onChange
    }, [onChange])

    useEffect(() => {
        CityList().then((list) => {
            setCityList(list)
        })
    } , [])

    function triggerChange(val: Value) {
        localRef.current.localVal = val;
        changRef.current?.(val)
    }

    useEffect(() => {
        function triggerChange(val: Value) {
            localRef.current.localVal = val;
            changRef.current?.(val)
        }

        if (localRef.current.localVal !== value) {
            // console.log('不想等哦，需要同步状态', localRef.current.localVal, value)
            if (value) {
                // 正在更新中，不接受其他的更新
                if (statusRef.current === Status.initIng) {
                    return
                }

                statusRef.current = Status.initIng
                const [c1, a1, d1] = value
                loadInitData(c1, a1).then((data) => {
                    // console.log('获取的值', data, value)
                    setCity(c1)
                    setArea(a1)
                    setDistrict(d1)
                    setAreaList(data.areaList || [])
                    setDistrictList(data.districtList || [])
                    if (data.districtList && data.districtList.length > 0 && d1) {
                        let ll = data.districtList.filter((item) => item.Id === d1)
                        if (ll.length > 0) {
                            triggerChange([c1, a1, d1, ll[0].CityName, ll[0].AreaName, ll[0].Name])
                        }
                    } else {
                        triggerChange(value)
                    }

                    setTimeout(() => {
                        statusRef.current = Status.ready
                    }, 50)
                })
            } else {
                setCity(undefined)
                setDistrict(undefined)
                setArea(undefined)
                setAreaList([])
                setDistrictList([])
            }
        } else {
            // console.log("棒棒的状态相等哈")
        }
    }, [value])

    function onCityChange(value: number) {
        setCity(value)
        setArea(undefined)
        setDistrict(undefined)
        triggerChange([value, undefined, undefined])
        if (value) {
            AreaList(value).then((list) => {
                setAreaList(list || [])
            })
        } else {
            setAreaList([])
        }

        setDistrictList([])
    }

    function onAreaChange(value: number) {
        setArea(value)
        setDistrict(undefined)
        triggerChange([city, value, undefined])
        if (value)  {
            DistrictList({ enable: 1, city_id: city, area_id: value, page_no: 1, page_size: 1000 }).then((list) => {
                setDistrictList(list.list || [])
            })
        } else {
            setDistrictList([])
        }

    }

    function onDistrictChange(val: number) {
        setDistrict(val)
        let ll = districtList.filter((item) => item.Id === val)
        if (ll.length > 0) {
            triggerChange([city, area, val, ll[0].CityName, ll[0].AreaName, ll[0].Name])
        } else {
            triggerChange([city, area, val])
        }
    }

    return <Row gutter={15}>
        <Col flex={flex || '150px'}>
            <Select<number> placeholder={'请选择城市'} value={city} onChange={onCityChange} allowClear>
                {
                    cityList.map((item) => {
                        return <Select.Option key={item.ID} value={item.ID}>{item.Name}</Select.Option>
                    })
                }
            </Select>
        </Col>
        <Col flex={flex || '150px'}>
            <Select<number> placeholder={'请选择区域'} value={area} onChange={onAreaChange} allowClear>
                {
                    areaList?.map((item) => {
                        return <Select.Option key={item.ID} value={item.ID}>{item.Name}</Select.Option>
                    })
                }
            </Select>
        </Col>
        <Col flex={flex || '150px'}>
            <Select
                placeholder={'请选择商圈'}
                showSearch
                value={district}
                onChange={onDistrictChange} allowClear
                filterOption={(input, option) => {
                    if (option) {
                        return (option as any).children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false;
                    // option?.children?.toLowerCase.().indexOf(input.toLowerCase()) >= 0
                }
            }>
                {
                    districtList?.map((item) => {
                        return <Select.Option key={item.Id} value={item.Id}>{item.Name}</Select.Option>
                    })
                }
            </Select>
        </Col>
    </Row>
}