import React from "react";

export interface IProps {
    src: string
}

const style: React.CSSProperties = {
    backgroundColor: '#eee',
    objectFit: 'contain',
    width: 100,
    height: 80
}

export default function ImageViewer({src}: IProps) {
    return <img alt={'图片'} src={src} style={style}/>
}