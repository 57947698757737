import style from "./Login.module.css"
import {Button, Form, Input} from "antd";
import {useRef} from "react";
import {useHistory} from "react-router-dom";
import {AuthService} from "../../service/AuthService";

const wrapperCol = {
    span: 16
}
const labelCol = {
    span: 6
}

const noLabelWrapCol = {
    span: 16,
    offset: 6
}

export function ResetPasswordForm() {
    const [form] = Form.useForm()

    const instanceRef = useRef<{ isSubmitting: boolean }>({ isSubmitting: false })
    const history = useHistory()

    function doFormFinished(values: any) {
        // console.log(values)

        if (instanceRef.current.isSubmitting) {
            return
        }

        instanceRef.current.isSubmitting = true

        AuthService.resetPassword(values).then((data) => {
            // console.log(data)
            history.replace('/login')
        }).finally(() => {
            instanceRef.current.isSubmitting = false
        })
    }
    return (
        <Form onFinish={doFormFinished} form={form} wrapperCol={wrapperCol} labelCol={labelCol}>
            <Form.Item label={'原密码'} name={"old_password"} rules={[{required: true, message: '请输入原密码'}, {max: 50, message: '密码长度不超过50'}, { min: 6,  message: '密码长度需大于6' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label={'新密码'} name={"new_password"} rules={[{required: true, message: '请输入新密码'}, {max: 50, message: '密码长度不超过50'}, { min: 6,  message: '密码长度需大于6' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label={'确认密码'} name={"re_new_password"} rules={[{required: true, message: '请重新输入新密码'}, {max: 50, message: '密码长度不超过50'}, { min: 6,  message: '密码长度需大于6' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={noLabelWrapCol}>
                <Button htmlType={'submit'} type={'primary'}>提交</Button>
                <Button htmlType={'reset'} className={style.RestBtn}>重置</Button>
            </Form.Item>
        </Form>
    )
}

export function ResetPassword() {
    return <div className={style.LoginPage}>
        <div className={style.LoginContainer}>
            <div className={style.LoginTitle}>重设登录密码</div>
            <ResetPasswordForm />
        </div>
    </div>
}