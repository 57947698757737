import {Button, Form, Input, message, Modal, Space} from "antd";
import {useEffect, useState} from "react";
import {IRoomVO, RoomService} from "../../../service/RoomService";
import {IRoomChangeOptEnum, RoomChangeLogService} from "../../../service/RoomChangeLogService";
import {useForm} from "antd/es/form/Form";

interface IOffLineModalProps {
    visible: boolean
    onSuccess: () => void
    onCancel: () => void
    room?: IRoomVO
    optType: IRoomChangeOptEnum
}

export default function ChangeLogModal(props: IOffLineModalProps) {
    const {visible, onCancel, room, onSuccess, optType} = props;

    const [content, setContent] = useState('')
    const [editForm] = useForm()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (visible) {
            editForm.resetFields();
        }
    }, [visible, editForm])

    function doSuccess() {
        if (!content && content.length === 0) {
            message.error('保存成功').then(() => {})
            return
        }
    }

    function doFormFinished(values: {content: string}) {
        if (!room) {
            return
        }
        setIsSubmitting(true)
        const {id} = room
        function updateLog() {
            RoomChangeLogService.create({content: values.content, room_id: id, opt_type: optType}).then(() => {
                onSuccess?.();
            }).finally(() => {
                setIsSubmitting(false)
            })
        }

        // 针对下架房源需要做特殊的处理
        if (optType === IRoomChangeOptEnum.offline) {
            RoomService.offline(id).then((data) => {
                if (data.Error === null) {
                    message.success("操作成功").then(() => {})
                    // setQuery(q => ({...q}))
                    updateLog()
                } else {
                    message.error("操作失败").then(() => {})
                }
            }).catch(() => {
                setIsSubmitting(false)
            })
        } else {
            updateLog()
        }
    }

    let title = '下架房源'
    let label = '下架原因'
    let requiredMsg = '请填写原因'
    if (optType === IRoomChangeOptEnum.follow) {
        title = '填写跟进信息'
        label = '跟进信息'
        requiredMsg = '请填写跟进信息'
    }

    return <Modal forceRender title={title} zIndex={2000} visible={visible} okButtonProps={{loading: isSubmitting}} cancelButtonProps={{disabled: isSubmitting}} onCancel={onCancel} onOk={doSuccess} footer={false}>
        <Form form={editForm} layout={'vertical'} onFinish={doFormFinished}>
            <Form.Item
                label={label} name={'content'} required
                extra={'最大长度不超过1024个字'}
                rules={[{required: true, message: requiredMsg}, { max: 1024, message: '最大长度不超过1024个字' }]}>
                <Input.TextArea autoSize onChange={e => setContent(e.target.value)} value={content} placeholder={requiredMsg} />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type={'primary'} htmlType={'submit'}>确认</Button>
                    <Button htmlType={'button'} onClick={onCancel}>取消</Button>
                </Space>
            </Form.Item>
        </Form>
    </Modal>
}