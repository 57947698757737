import React, {useCallback, useEffect, useRef, useState} from "react";
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import {Button, message, Modal} from "antd";
import {arrayMoveImmutable as arrayMove} from 'array-move';
import style from './ImageUploader.module.css'
import {Post} from "../../service/r";
import {DeleteOutlined, EyeOutlined, UploadOutlined} from "@ant-design/icons";

const fileStyle: React.CSSProperties = {
    display: "none"
}

interface IFileItem {
    name: string
    status: 'uploading' | 'done'
    percent: number
    uid: string
    url: string
    preview: string
}

function formatBytes(a: number,b: number = 2){
    if(0 === a) {
        return "0 Bytes";
    }
    const c = 1024;
    const d = b || 2;
    const e = ["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"];
    const f = Math.floor(Math.log(a)/Math.log(c));
    return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]
}

function getBase64(file: Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}

function readeImageWithAndHeight(file: Blob) {
    return new Promise<{width: number, height: number}>((resolve, reject) => {
        getBase64(file).then((fileSrc) => {
            const img = new Image();
            img.onload = function () {
                const width = img.width
                const height = img.height
                resolve({width, height})
            }
            img.onerror = () => {
                reject(new Error('获取图片尺寸大小失败'))
            }
            img.src = fileSrc
        }).catch(() => {
            reject(new Error("读取图片内容失败"))
        })
    })

}

function createId(): string {
    return `u-${new Date().getTime()}-${Math.random().toString(16).substr(2)}`
}

function createFileItem(file: File): IFileItem {
    return {
        name: file.name,
        percent: 0,
        status: 'uploading',
        uid: createId(),
        url: '',
        preview: ''
    }
}

// function createTestFile():IFileItem {
//     let url = 'https://zhiyuqifu.oss-cn-beijing.aliyuncs.com/test/9eabed83739edbca882337a7e5c2c7ac.jpg'
//     return {
//         url: url,
//         name: url.substring(url.lastIndexOf('/') + 1),
//         percent: 0,
//         status: 'uploading',
//         uid: createId(),
//         // url: '',
//         preview: ''
//     }
// }

function createFileByUrl(url: string): IFileItem {
    return {
        url: url,
        name: url.substring(url.lastIndexOf('/') + 1),
        percent: 0,
        status: 'uploading',
        uid: createId(),
        // url: '',
        preview: ''
    }
}

const SortableItem = SortableElement(({item, doPreviewImg, doDeleteImg}: {item: IFileItem, doPreviewImg: () => void,doDeleteImg: ()=>void }) => {
    return <div key={item.uid} className={style.previewItem}>
        <div className={style.previewControl}>
            <Button type={'link'} size={'small'} onClick={doPreviewImg}><EyeOutlined className={style.previewIcon} /></Button>
            <Button type={'link'} size={'small'} onClick={doDeleteImg}><DeleteOutlined className={style.previewDelete}/></Button>
        </div>
        <img src={item.url || item.preview || undefined} alt="预览图片" className={style.previewImage}/>
    </div>
})

const SortableList = SortableContainer(({items, doPreviewImg, doDeleteImg}: {items: IFileItem[], doPreviewImg: any, doDeleteImg: any}) => {
    return (
        <div className={`${style.previewContainer} ${items.length > 0 ? '' : style.previewContainerEmpty}`}>
            {
                items.map((item, index)=> {
                    return (
                        <SortableItem
                            key={item.uid}
                            index={index}
                            item={item}
                            doDeleteImg={doDeleteImg(item)}
                            doPreviewImg={doPreviewImg(item)}  />
                    )
                })
            }
        </div>
    )
})


export interface LimitImageWH {
    w?: number
    h?: number
}

export interface IImageUploaderProps {
    onChange?: (values: string[]) => void,
    value?: string[]
    multiple?: boolean
    limitSize?: number // 限制文件上传大小
    limitWidth?: number // 限制图片的宽度为
    limitHeight?: number // 限制图片的高度为
    aspectRatio?: number // 设置期待图片的宽高比例
    aspectRatioStr?: string
}

const ImageUploader: React.FC<IImageUploaderProps> = (
    {
        value,
        onChange,
        multiple,
        limitWidth, limitHeight,
        aspectRatio,
        aspectRatioStr,
        limitSize
    }
) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [fileList, setFileList] = useState<IFileItem[]>([])
    const [previewImage, setPreviewImage] = useState<string>()
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [previewTitle, setPreviewTitle] = useState<string>()

    useEffect(() => {
        function updateFileItem(item: IFileItem) {
            setFileList((ll) => {
                return ll.map((l) => {
                    if (l.uid === item.uid) {
                        return item
                    }
                    return l
                })
            })
        }

        function uploadFile(file: File) {
            let data = new FormData()
            data.append('file', file)
            return Post<string>("/uploader/upload", data)
        }

        function handleFile(file: File, fileItem: IFileItem) {
            if (multiple) {
                setFileList((ll) => ([...ll, fileItem]))
            } else {
                setFileList([fileItem])
            }

            getBase64(file).then((localUrl: string) => {
                // fileItem.preview = localUrl
                // console.log('getBase64 === ')
                updateFileItem({...fileItem, preview: localUrl})
            })

            uploadFile(file).then((url) => {
                // console.log('uploading ====')
                updateFileItem({...fileItem, url})
            })
        }

        // 验证图片文件
        async function checkImageFile(file: File) {
            const {size} = file
            if (limitSize === undefined) {
                return Promise.resolve(true)
            }

            if (size > limitSize) {
                return Promise.reject(new Error("图片大小不能超过" + formatBytes(limitSize)))
            }

            if (limitWidth === undefined && limitHeight === undefined && aspectRatio === undefined) {
                return Promise.resolve(true)
            }

            const {width, height} = await readeImageWithAndHeight(file)

            // console.log('=====', aspectRatio, width/height)
            if (aspectRatio !== undefined) {
                if (aspectRatio !== (width/height)) {
                    return Promise.reject(new Error(aspectRatioStr ? `请上传宽高比例为${aspectRatioStr}的图片` : "请上传指定比例的图片"))
                }
            }

            // 只是检查宽度
            if (limitWidth !== undefined && limitHeight === undefined) {
                if (width !== limitWidth) {
                    return Promise.reject(new Error(`图片的宽度必须是${limitWidth}px`))
                }
            }

            // 只是检测图片的高度
            if (limitHeight !== undefined && limitWidth === undefined) {
                if (height !== limitHeight) {
                    return Promise.reject(new Error(`图片的高度必须是${limitHeight}px`))
                }
            }

            // 检测图片为固定的尺寸
            if (limitWidth !== undefined && limitHeight !== undefined) {
                if (width !== limitWidth && height !== limitHeight) {
                    return Promise.reject(new Error(`图片宽度必须为${limitWidth}像素，高度必须为${limitHeight}像素`))
                }
            }

            return Promise.resolve(true)
        }

        function fileChangeHandler(e: Event) {
            let el = e.target as HTMLInputElement
            // console.log(el.files)
            if (el.files === null) {
                return;
            }

            // 支持选择多个文件的特殊场景
            for (let i = 0; i < el.files.length; i++) {
                const file = el.files[i]
                checkImageFile(file).then(() => {
                    let fileItem = createFileItem(file)
                    handleFile(file, fileItem)
                }).catch((err) => {
                    message.error(err.message).then(() => {})
                })

                // 如果不是多文件处理，在第一个文件处理好后就退出
                if (!multiple) {
                    break
                }
            }
        }

        if (inputRef.current) {
            inputRef.current.addEventListener('change', fileChangeHandler)
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            inputRef.current && inputRef.current.removeEventListener('change', fileChangeHandler)
        }
    }, [multiple, limitHeight, limitWidth, limitSize, aspectRatio, aspectRatioStr])

    const changeRef = useRef<typeof onChange>(onChange)

    useEffect(() => {
        changeRef.current = onChange
    }, [onChange])

    useEffect(() => {
        let ll = fileList.filter(i => i.url).map(i => i.url);

        (ll as any).formRenderFlag = true;
        changeRef.current?.(ll);
    }, [fileList])

    useEffect(() => {
        // console.log('get the value: ', value)

        if (!value) {
            setFileList([])
            return
        }

        if (value && (value as any).formRenderFlag) {
            return
        }

        if (multiple) {
            setFileList(value.map(item => createFileByUrl(item)))
        } else {
            let firstItem = value[0]
            if (firstItem) {
                setFileList([createFileByUrl(firstItem)])
                return
            }
            setFileList([])
        }
    }, [value, multiple])

    function handleClickTrigger() {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    function doPreviewImg(item: IFileItem) {
        return () => {
            setPreviewImage(item.preview || item.url)
            setPreviewVisible(true)
            setPreviewTitle(item.name)
        }
    }

    function doDeleteImg(item: IFileItem) {
        return () => {
            const ll = fileList.filter(i => i.uid !== item.uid)
            setFileList(ll)
        }
    }

    const sortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        const val = arrayMove(fileList, oldIndex, newIndex);
        setFileList(val)
    };

    const doCancel = useCallback(() => {
        setPreviewVisible(false)
    }, [])

    return <div>
        <input type="file" style={fileStyle} multiple={multiple} ref={inputRef} accept="image/*"/>
        <Button onClick={handleClickTrigger}>
            <UploadOutlined />
            选择图片
        </Button>

        <SortableList
            items={fileList}
            axis={'xy'}
            doPreviewImg={doPreviewImg}
            doDeleteImg={doDeleteImg}
            onSortEnd={sortEnd}
        />

        <Modal visible={previewVisible} title={previewTitle} onCancel={doCancel} footer={false}>
            <img src={previewImage} className={style.modalPreviewImg} alt="hh"/>
        </Modal>
    </div>
}

ImageUploader.defaultProps = {}

export default ImageUploader