import {Post} from "./r";

export interface ICity {
    ID: number,
    Name: string
}



export function CityList() {
    return Post<ICity[]>("/city/list")
}

export function CityCreate(name: string) {
    return Post("/city/create", { name })
}

export function CityEdit(city: {id: number, name: string}) {
    return Post("/city/update", city)
}

export function CityDelete(id: number) {
    return Post("/city/delete", {id})
}