import PageCard from "../../layout/PageCard";
import {Button, message, Space} from "antd";
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json'
import React, {useEffect, useState} from "react";
import {getBusinessConfig, saveBusinessConfig} from "../../service/ConfigService";

const warningStyle: React.CSSProperties = {
    color: "#a11",
    marginBottom: 6,
    fontSize: 14
}

export default function BusinessConfigSettings() {
    const [value, setValue] = useState<string>('');
    const [query, setQuery] = useState<number>(0);
    useEffect(() => {
        getBusinessConfig().then((data) => {
            // console.log('这里数据12313123123',data);
            if (data && data.length > 0) {
                try{
                    let d = JSON.parse(data[0].value);
                    setValue(JSON.stringify(d, undefined, 2));
                } catch (e) {
                    console.error('保存JSON失败')
                }
            }
        })
    }, [query])

    function doSave() {
        saveBusinessConfig(value).then((msg) => {
            if (!msg.Error) {
                message.success("保存成功");
            } else {
                message.error(msg.Error)
            }
        })
    }

    function doRefresh() {
        setQuery(q => q + 1)
    }

    return <div>
        <PageCard>
            <Space size={15}>
                <Button onClick={doSave} type={'primary'}>保存</Button>
                <Button onClick={doRefresh} type={'primary'} ghost>刷新</Button>
            </Space>
        </PageCard>
        <PageCard>
            <div style={warningStyle}>注意：这里重要的业务参数配置，请不要随意修改！</div>
            <CodeMirror
                value={value}
                height="auto"
                extensions={[json()]}
                onChange={(value, viewUpdate) => {
                    // console.log('value', value)
                    setValue(value);
                }}
            />
        </PageCard>
    </div>
}