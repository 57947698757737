import PageCard from "../../layout/PageCard";
import React, {useEffect, useMemo, useState} from "react";
import AccessPermissionAssign from "./components/AccessPermissionAssign";
import {Button, Form, Input, message, Modal} from "antd";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {IRoleListQuery, IRoleVO, RoleService} from "../../service/RoleService";
import {Helper} from "../../utils/Helper";
import {Table} from "antd/es";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import {AuthService, ICurrSessionInfo} from "../../service/AuthService";
import HasPermission from "../../components/HasPermission/HasPermission";
import {API_DeleteData} from "../../components/HasPermission/apiPermission";

interface IProps {
    visible: boolean
    onSuccess?: () => void
    onCancel?: () => void
    curr?: IRoleVO
    userInfo: ICurrSessionInfo
}

const RoleEditForm: React.FC<IProps>= ({ userInfo,visible, onSuccess, onCancel, curr }) => {
    const [form] = Form.useForm()
    // const {} = useContext()
    useEffect(() => {
        if (visible) {
            form.resetFields()
        }
    }, [curr, form, visible])

    function doFinished(values: IRoleVO) {
        values.latest_update_by = userInfo.ID
        values.latest_update_by_name = userInfo.Name
        if (curr) {
            values.id = curr.id
            RoleService.update(values).then((res) => {
                if(res.Error == null) {
                    onSuccess?.()
                } else {
                    message.error(res.Error).then(() => void 0)
                }
            })
        } else {
            console.log(values);
            values.create_by = userInfo.ID
            values.create_by_name = userInfo.Name
            RoleService.create(values).then((res)=> {
                if(res.Error == null) {
                    onSuccess?.()
                } else {
                    message.error(res.Error).then(() => void 0)
                }
            })
        }
    }

    return (
        <Form form={form} initialValues={curr} labelCol={labelCol} wrapperCol={wrapperCol} onFinish={doFinished}>
            <Form.Item name={'role_name'} label={'角色名称'} rules={[{required: true, message: '该信息必填'}, {max: 20, type: "string", message: "长度超过20个字"}]}>
                <Input />
            </Form.Item>
            <Form.Item name={'desc'} label={'描述信息'} rules={[{max: 100, type: "string", message: "长度超过100个字"}]}>
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={noLabelWrapperCol}>
                <Button type={'primary'} htmlType={'submit'}>保存</Button>
                <Button style={formButtonStyle} htmlType={'reset'}>重置</Button>
            </Form.Item>
        </Form>
    )
}

const RoleEditModal: React.FC<IProps> = ({userInfo, curr, visible, onCancel, onSuccess}) => {
    return (
        <Modal visible={visible} forceRender title={curr ? '编辑' : '新增'} footer={false} onCancel={onCancel}>
            <RoleEditForm userInfo={userInfo} visible={visible} onSuccess={onSuccess} onCancel={onCancel} curr={curr}/>
        </Modal>
    )
}

export default function RoleSetting() {
    const [showAccessModal, setShowAccessModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [query, setQuery] = useState<Partial<IRoleListQuery>>({page_no: 1, page_size: 10})
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState(Helper.getDefaultPage())
    const [list, setList] = useState<IRoleVO[]>([])
    const [userInfo, setUserInfo] = useState<ICurrSessionInfo>()
    const [curr, setCurr] = useState<IRoleVO>()
    const columns = useMemo<ColumnsType<IRoleVO>>(() => {
        function doEditRole(v: IRoleVO) {
            return () => {
                setShowEditModal(true)
                setCurr(v)
            }
        }

        function doPermissionEdit(v: IRoleVO) {
            return () => {
                setCurr(v)
                setShowAccessModal(true)
            }
        }

        function doDelete(v: IRoleVO) {
            return () => {
                Modal.confirm({
                    title: '确认删除',
                    content: "删除角色，分配给人的角色也会对应的删除",
                    onOk() {
                        RoleService.delete(v.id).then((r) => {
                            if (r.Error === null) {
                                message.success('删除成功').then(() => void 0)
                                setQuery((q) => ({...q}))
                            } else {
                                message.error(r.Error).then(() => void 0)
                            }
                        })
                    }
                })
            }
        }

        return [
            {
                dataIndex: 'role_name',
                title: '角色名称'
            },
            {
                dataIndex: 'desc',
                title: '描述信息'
            },
            {
                dataIndex: 'create_time',
                title: '创建时间',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                dataIndex: 'latest_update_time',
                title: '更新时间',
                width: 170,
                render(t) {
                    return Helper.formatDate(t)
                }
            },
            {
                dataIndex: 'create_by_name',
                title: '创建人',
                width: 120
            },
            {
                dataIndex: 'latest_update_by_name',
                title: '更新人',
                width: 120
            },
            {
                title: '操作',
                width: 180,
                fixed: 'right',
                render(t, r) {
                    return <>
                        <Button size={'small'} type={'link'} onClick={doEditRole(r)}>编辑</Button>
                        <Button size={'small'} type={'link'} onClick={doPermissionEdit(r)}>配置权限</Button>
                        <HasPermission code={API_DeleteData} inApi>
                            <Button size={'small'} type={'link'} onClick={doDelete(r)} danger>删除</Button>
                        </HasPermission>

                    </>
                }
            }
        ]
    }, [])

    function doNewRole() {
        setCurr(undefined)
        setShowEditModal(true)
    }

    useEffect(()=> {
        setLoading(true)
        RoleService.list(query).then((data) => {
            setList(data.list)
            setPage(data.page)
        }).finally(() => {
            setLoading(false)
        }).catch((err) => {
            console.log(err);
        })
    }, [query])

    useEffect(()=> {
        AuthService.info().then((info) => {
            setUserInfo(info)
        })
    }, [])

    function doTableChange(pagination: TablePaginationConfig) {
        setQuery((q) => ({
            ...q,
            page_no: pagination.current,
            page_size: pagination.pageSize,
        }))
    }


    return (
        <>
            <PageCard>
                <Button onClick={doNewRole}>新增角色</Button>
            </PageCard>

            <PageCard>
                <Table
                    tableLayout={'fixed'}
                    loading={loading}
                    dataSource={list}
                    columns={columns}
                    pagination={page}
                    bordered
                    size={'middle'}
                    rowKey={'id'}
                    scroll={{x: 1200}}
                    onChange={doTableChange}
                />
            </PageCard>

            <AccessPermissionAssign
                userInfo={userInfo!}
                visible={showAccessModal}
                curr={curr!}
                onCancel={() => setShowAccessModal(false)}
                onSuccess={() => {
                    setShowAccessModal(false)
                    setQuery((q) => ({...q}))
                }}
            />

            <RoleEditModal
                userInfo={userInfo!}
                visible={showEditModal}
                curr={curr}
                onCancel={() => {setShowEditModal(false)}}
                onSuccess={() => {
                    setShowEditModal(false)
                    setQuery(q => ({...q}))
                }}
            />
        </>
    )
}

