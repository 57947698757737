import {Button, Form, Input, Modal, Select} from "antd";
import React, {useEffect} from "react";
import {formButtonStyle, labelCol, noLabelWrapperCol, wrapperCol} from "../common";
import {
    EntrustService,
    EntrustStatusEnum,
    EntrustStatusList,
    EntrustStatusListMap,
    IEntrustVO
} from "../../service/EntrustService";

interface IProps {
    visible: boolean
    onClose?: () => void
    onSuccess?: () => void
    curr?: IEntrustVO
}

const HandleEntrustForm: React.FC<IProps> = ({visible, curr, onSuccess, onClose}) => {
    const [form] = Form.useForm()
    useEffect(() => {
        if (visible && curr) {
            form.resetFields()
        }
    }, [visible, curr, form])

    function doFinished(values: any) {
        // console.log(values)
        if (curr) {
            EntrustService.handleEntrust(curr.id, values.statusNew, values.remarks).then((res) => {
                if (res.Error === null) {
                    onSuccess?.();
                }
            })
        }
    }

    return (
        <Form form={form} initialValues={curr} labelCol={labelCol} wrapperCol={wrapperCol} onFinish={doFinished}>
            {
                curr ? <Form.Item label={"旧状态"}>
                    <span className={'ant-form-text'}>{EntrustStatusListMap.get(curr?.status)}</span>
                </Form.Item> : null
            }

            <Form.Item label={'设置状态'} name={'statusNew'} extra={'设置状态为处理中或者处理完毕'} rules={[{ required: true, message: '该项必填' }]}>
                <Select placeholder={'请选择状态'}>
                    {
                        EntrustStatusList.map((item) => {
                            return <Select.Option value={item.type} key={item.type} disabled={item.type === EntrustStatusEnum.NeedTodo}>{item.name}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item label={'处理备注信息'} name={'remarks'} extra={'这里填写处理的备注记录信息'} rules={[{max: 1000, message: '最长不超过1000个字'}]}>
                <Input.TextArea autoSize={{minRows: 3, maxRows: 10}} />
            </Form.Item>

            <Form.Item wrapperCol={noLabelWrapperCol}>
                <Button htmlType={'submit'} type={'primary'}>保存</Button>
                <Button htmlType={'reset'} style={formButtonStyle}>重置</Button>
            </Form.Item>
        </Form>
    )
}

export default function HandleEntrustModal({visible, onClose, onSuccess, curr}: IProps) {
    return <Modal visible={visible} title={'处理委托信息'} footer={false} onCancel={onClose}>
        <HandleEntrustForm visible={visible} onClose={onClose} onSuccess={onSuccess} curr={curr}/>
    </Modal>
}