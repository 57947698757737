import {useContext} from "react";
import {AuthService} from "../service/AuthService";
import style from "./layout.module.css"
import {Button, Divider, Modal} from "antd";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../global/GlobalContext";

export function HeaderUserInfo() {
    const {currUserInfo: info} = useContext(GlobalContext)
    const history = useHistory()

    function doShutDown() {
        Modal.confirm({
            title: '确认退出',
            content: '退出后将会强制跳转到登录页面.',
            onOk() {
                AuthService.logout().then(() => {
                    history.replace("/login")
                })
            }
        })
    }
    function doClickUserCenter() {
        history.push("/user_center")
    }
    return <div className={style.HeaderRightInfo}>
        <span>欢迎,&nbsp;</span>
        <span>{info?.Name}</span>
        {
            info ? <>
                <Button type={'link'} size={'small'} onClick={doClickUserCenter}>个人中心</Button>
                <Divider type={'vertical'} />
                <Button type={'link'} size={'small'} onClick={doShutDown}>退出</Button>
            </> : null
        }
    </div>
}